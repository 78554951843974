import SaviaIcon from '@images/saviaIcon';
import React from 'react';
import { provider, auth } from '../../firebase/firebaseConfig';
import { Button, Divider, Message, toaster, Loader, Schema, Form } from 'rsuite';
import '../../styles/Login.css';
import { useIntl } from 'react-intl';
import { FcGoogle } from "react-icons/fc";
// import { BsGoogle } from "react-icons/bs";
import ModalOtp from '../../components/Modals/Auth/ModalLoginOtp'
import * as constants from '../../utils/Constants';
import api from '@api/SecurityApi';


const Field = React.forwardRef((props, ref) => {
  const { name, message, label, accepter, error, ...rest } = props;
  return (
    <div className='mb-4'>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} errorMessage={error} {...rest} />
      {message && (
        <Form.HelpText>{message}</Form.HelpText>
      )}
    </div>
  );
});

export const Login = ({ success, signUpClicked }) => {
  const { $t } = useIntl();
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({});

  const [openModal, setOpenModal] = React.useState(false);
  const [spiner, setSpiner] = React.useState(false);
  const signInWithGoogle = async () => {
    try {
      const response = await auth.signInWithPopup(provider)
      success(response, 'google')
    } catch (err) {
      console.log('err', err)
    }
  }
  
  const getCode = async () => {
    if (!formRef.current.check()) {
      return;
    }
    if (formValue?.name) {
      setSpiner(true)
      try {
        const body = {
          email: formValue?.name,
          appType: 'B2BPORTAL'
        };
        await api.otp(body);
        setOpenModal(true)
        setSpiner(false)
      } catch (err) {
        console.log(err)
        toaster.push(
          <Message
            type="error"
            showIcon
            closable
            duration={constants.NOTIFICATION_DURATION}
          >
            Usuario no autorizado para ingresar a Savia
          </Message>,
        );
        setSpiner(false)
      }
    }
  }

  const onCloseModal = () => {
    setOpenModal(false)
  }

  const model = Schema.Model({
    name: Schema.Types.StringType()
      .isEmail($t({ id: 'route.email-invalid' }))
  });

  return (
    <div
      className="w-screen h-screen flex justify-center items-center
    bg-gradient-to-br from-green-500 to-gray-100"
    >
      <div className="p-16 bg-white rounded-xl drop-shadow-lg space-y-7">
        <div className="flex mx-16">
          <h1 className="text-center text-3xl">Savia </h1>
          <div>
            <SaviaIcon className="w-8 mx-2" />
          </div>
        </div>
        <Form
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formError={formError}
          formDefaultValue={formValue}
          model={model}
        >
          <Field name="name" label="Email"/>
          <Button appearance="primary" className=" w-full text-center mb-4" onClick={getCode}>
            {$t({ id: 'route.get-code' })}
          </Button>
        </Form>
        <div
          class="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
          <p
            class="mx-4 mb-0 text-center font-semibold dark:text-neutral-200">
            {$t({ id: 'route.or' })}
          </p>
        </div>
        <button type="button" onClick={signInWithGoogle} className="focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm text-gray px-5 py-2.5 w-full bg-gray-100 border-b-2 flex items-center dark:focus:ring-[#4285F4]/55 mb-2">
          <FcGoogle className='mr-4 text-xl' />
          {$t({ id: 'action.continue.with.google' })}
        </button>
        <div className="flex items-center justify-center ml-2 mb-1">
          <div className="flex mb-1">
            {/* <div>
              <SaviaIcon className="w-8 mx-2" />
            </div> */}
            <div>
              <Button
                as="a"
                appearance="link"
                size="sm"
                onClick={signUpClicked}
                className="p-0 m-0"
              >
                Crear una cuenta |
              </Button>
              <Divider vertical />
              <Button
                as="a"
                appearance="link"
                size="sm"
                href="http://savia.co/"
                target="_blank"
                className="p-0 m-0"
              >
                savia.co
              </Button>
            </div>

            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
        {openModal && (
          <ModalOtp visible={openModal} onClose={onCloseModal} email={formValue?.name} success={success} />
        )}
        {spiner && (
          <div className='text-center'>
            <Loader className='items-center justify-center text-center' content="Loading..." size="md" />
          </div>
        )}
      </div>
    </div>
  );
};
