import React, { ReactNode } from 'react';

type PageHeaderProps = {
  title?: ReactNode;
  subtitle?: ReactNode;
  right?: ReactNode;
};

const PageHeader: React.FCC<PageHeaderProps> = ({
  title,
  subtitle,
  children,
  right,
}) => (
  <div className="page-header w-full pl-12 pr-4 pb-2 pt-2.5 flex flex-row">
    {/* <div className="pl-12 pb-2 pt-2"> */}
    {title && <h3 className="text-xl font-medium">{title}</h3>}
    {subtitle && <h4 className="text-sm font-normal">{subtitle}</h4>}
    {children}
    {right && (
      <div className="flex flex-row flex-nowrap pl-2 ml-auto">{right}</div>
    )}
  </div>
);

export default PageHeader;
