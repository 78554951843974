import PageSpinner from '@components/PageSpinner';
import SideNavMenu from '@components/SideNavMenu';
import { setup } from 'goober';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import * as Rollbar from '@rollbar/react';
import { CustomProvider as RSuiteCustomProvider } from 'rsuite';
import 'src/tailwind.css';
import App from './App';
import { reactIntl, rsuiteIntl } from './locale';
import getLocale from './locale/getLocale';
import AppRouter from './router/AppRouter';
import store from './store';
import { TOKEN_ROLLBAR, ENVIRONMENT_ROLLBAR, ENABLED_ROLLBAR } from "../src/utils/Constants";


import '@core-js';

setup(React.createElement);

export const root = createRoot(document.getElementById('root'));
const rollbarConfig = {
  accessToken: TOKEN_ROLLBAR,
  environment: ENVIRONMENT_ROLLBAR,
  exception_level_filters: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: ENABLED_ROLLBAR,
  payload: {
    context: 'b2b',
    client: {
      javascript: {
        code_version: '1.0.0',
      }
    }
  }
}; 



 
root.render(
  <IntlProvider
    locale={getLocale()}
    messages={reactIntl}
    defaultLocale="en"
    wrapRichTextChunksInFragment>
    <RSuiteCustomProvider locale={rsuiteIntl}>
      <Rollbar.Provider config={rollbarConfig}>
        <Rollbar.ErrorBoundary >
          <Provider store={store}>
            <AppRouter>
              <SideNavMenu />
              <App />
              <PageSpinner />
            </AppRouter>
          </Provider>
        </Rollbar.ErrorBoundary>
      </Rollbar.Provider>
    </RSuiteCustomProvider>
  </IntlProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.register();
// serviceWorker.updateNewVersion();
