import axios from '../libs/axios';
import queryString from 'query-string';

const api = {
  async getAllStates() {
    return await axios
      .get('MasterData/GetAllStates')

      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  async getAllStateCities(filters = {}) {
    const params = queryString.stringify(filters)
    return await axios.get(`cities${params && `?${params}`}`)
  },

  getAllChannels(filters) {
    return axios.get('channels')
  },

  getAllInvoicingSettings(filters = {}) {
    const params = queryString.stringify(filters)
    return axios.get(`invoicing-settings${params && `?${params}`}`)
  },

  async getAllTaxes(filter) {
    return await axios
      .get('taxes', filter)

      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  searchStates(filters = {}) {
    const params = queryString.stringify(filters)
    return axios.get(`states${params && `?${params}`}`)
  },

  getAllTags(filters) {
    return axios.get('tags')
  },
  async getAllFulFillmentTypes(filter) {
    return await axios
      .get('fulfillment-types', filter)

      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },
};
export default api;
