import axios from 'axios';

const api = {

    getCustomers(){
        return axios.get('customers')
    },

    saveCustomer(customer){
        return axios.post('customers', customer)
    },

    editCustomer(id, customer){
        return axios.patch(`customers/${id}`, customer)
    },

    async getCustomerById(id){
        return await axios.get(`customers/${id}`)
    },

    searchCustomers(filters){
        return axios.get('customers')
    },

}

export default api;
