import { Icon } from "@rsuite/icons";
import ArrowUpLineIcon from "@rsuite/icons/ArrowUpLine";
import CloseIcon from "@rsuite/icons/Close";
import SearchIcon from "@rsuite/icons/Search";
import useViewport, { UseViewportInstance } from "@utils/useViewport";
import { styled } from "goober";
import { noop } from "lodash";
import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BiFilter } from "react-icons/bi";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import {
  Cell,
  Column,
  Hooks,
  IdType,
  Row,
  TableState,
  TableToggleCommonProps,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  ItemContent,
  TableComponents,
  TableVirtuoso,
  TableVirtuosoProps,
} from "react-virtuoso";
import { useKeyBindings, useToggle } from "rooks";
import { Checkbox, Drawer, IconButton, Input, InputGroup } from "rsuite";

// plugin augmentation
declare module "react-table" {
  export interface TableOptions<D extends object = {}>
    extends UseGlobalFiltersOptions<D> {}

  export interface TableInstance<D extends object = {}>
    extends UseFiltersInstanceProps<D>,
      UseGlobalFiltersInstanceProps<D>,
      UseSortByInstanceProps<D>,
      UseRowSelectInstanceProps<D> {
    [key: string]: any;
  }

  export interface ColumnInstance<D extends object = {}>
    extends UseFiltersColumnProps<D>,
      UseSortByColumnProps<D>,
      UseRowSelectInstanceProps<D> {}

  export interface ColumnInterface<D extends object = {}>
    extends UseGlobalFiltersColumnOptions<D>,
      UseSortByColumnOptions<D>,
      UseFiltersColumnOptions<D>,
      UseRowSelectOptions<D> {
    sticky?: boolean;
  }

  export interface Row<D extends object = {}> extends UseRowSelectRowProps<D> {}

  export interface TableState<D extends object>
    extends UseGlobalFiltersState<D>,
      UseSortByState<D>,
      UseRowSelectState<D> {}
}

export type AnyObject = Record<string | number | symbol, unknown>;

export type HighlightTextProps = {
  text?: string | number;
  subtext?: string | number;
};

export const HighlightText: React.FC<HighlightTextProps> = ({
  text,
  subtext,
}) => {
  if (text === null || text === undefined) return null;
  if (subtext === null || subtext === undefined) return <>{text}</>;

  text = `${text}`;
  subtext = `${subtext}`;

  const startIndex = text
    .toLocaleLowerCase()
    .indexOf(subtext.toLocaleLowerCase());

  if (startIndex === -1) return <>{text}</>;

  const endIndex = startIndex + subtext.length;

  return (
    <>
      {text.slice(0, startIndex)}
      <span className="rounded-sm bg-blue-100 text-blue-500">
        {text.slice(startIndex, endIndex)}
      </span>
      {text.slice(endIndex)}
    </>
  );
};

const DrawerStyled = styled(Drawer)`
  .rs-drawer-content {
    position: relative;
  }
`;

export type GlobalFilterProps = {
  setGlobalFilter(value: string): void;
  globalFilter: string;
};

export type GlobalFilterCallback<D extends AnyObject> = (
  rows: Array<Row<D>>,
  columnIds: Array<IdType<D>>,
  filterValue: any
) => Array<Row<D>>;

const GlobalFilter: React.FC<GlobalFilterProps> = ({
  globalFilter,
  setGlobalFilter,
}) => {
  const [value, setValue] = useState(globalFilter);
  const deferredValue = useDeferredValue(value);

  const { $t } = useIntl();

  useEffect(() => {
    setGlobalFilter(deferredValue);
  }, [deferredValue]);

  return (
    <InputGroup className="max-w-lg" inside size="sm">
      <InputGroup.Addon>
        <SearchIcon />
      </InputGroup.Addon>
      <Input
        type="search"
        size="sm"
        placeholder={$t({ id: "search" })}
        value={value || ""}
        onChange={setValue}
        autoFocus
        autoComplete="off"
        inputMode="search"
        autoCorrect="off"
      />
      {value && (
        <InputGroup.Button onClick={() => setValue("")}>
          <CloseIcon />
        </InputGroup.Button>
      )}
    </InputGroup>
  );
};

const SelectRowCheckbox: React.FCC<
  TableToggleCommonProps & { name?: string }
> = ({ name, children, onChange, ...props }) => {
  return (
    <Checkbox name={name} {...props} onCheckboxClick={onChange}>
      {children}
    </Checkbox>
  );
};

const makeSelectionColumn = <D extends AnyObject>(hooks: Hooks<D>) => {
  hooks.visibleColumns.push((columns) => [
    {
      id: "$rowSelect",
      maxWidth: 35,
      width: 35,
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <SelectRowCheckbox
          {...mergeStyleProps(getToggleAllRowsSelectedProps(), {
            className: "-m-2 mt-2 -ml-1",
          })}
        />
      ),
      Cell: ({ row }) => (
        <SelectRowCheckbox
          {...mergeStyleProps(row.getToggleRowSelectedProps(), {
            className: "-m-2",
          })}
        />
      ),
    },
    ...columns,
  ]);
};

export function mergeStyleProps(
  ...sources: {
    style?: React.CSSProperties;
    className?: string;
    [prop: string]: any;
  }[]
): AnyObject {
  const className = sources.map(({ className }) => className).join(" ");

  const style = sources.reduce<React.CSSProperties>(
    (styles, { style }) => Object.assign(styles, style),
    {}
  );

  return Object.assign({}, ...sources, { className, style });
}

function stopPropagation<E extends React.SyntheticEvent<any>>(e: E) {
  e.stopPropagation();
}

const defaultColumn: Partial<Column<any>> = {
  minWidth: undefined,
  maxWidth: undefined,
  width: undefined,
  disableFilters: false,
  disableGlobalFilter: false,
  Cell: ({ state, value, column }) => {
    const { filterValue } = column;
    const { globalFilter } = state;

    return <HighlightText text={value} subtext={filterValue || globalFilter} />;
  },
  Filter({ column }) {
    const { id, filterValue, setFilter } = column;

    return (
      <InputGroup size="xs" inside>
        <Input
          id={id}
          name={id}
          value={filterValue || ""}
          onChange={setFilter}
        />
      </InputGroup>
    );
  },
};

export function cellsById<D extends AnyObject>(
  cells: Cell<D>[]
): Record<string, Cell<D>> {
  return cells.reduce(
    (record, cell) => Object.assign(record, { [cell.column.id]: cell }),
    {}
  );
}

function toggleFn(state: boolean, action: any) {
  if (typeof action === "boolean") return action;
  return !state;
}

export type DataGridContext<D extends AnyObject> = {
  viewport: UseViewportInstance;
  rows: Row<D>[];
  selectedFlatRows: Row<D>[];
  filteredFlatRows: Row<D>[];
  filtersVisible: boolean;
};

export type DataGridRowContent<D extends AnyObject> = (
  index: number,
  row: Row<D>,
  context: DataGridContext<D>
) => React.ReactNode;

export type DataGridToolbar<D extends AnyObject> = (
  context: DataGridContext<D>
) => React.ReactNode;

export type DataGridRowClickHandler<D extends AnyObject> = (
  row: Row<D>,
  cell: Cell<D>,
  ev: React.MouseEvent<HTMLElement, MouseEvent>
) => void;

export type DataGridRowContextMenu<D extends AnyObject> = (
  row: Row<D>,
  cell: Cell<D>
) => void;

export type DataGridProps<D extends AnyObject> = TableVirtuosoProps<
  unknown,
  DataGridContext<D>
> & {
  data: ReadonlyArray<D>;
  columns: ReadonlyArray<Column<D>>;
  disableGlobalFilter?: boolean;
  globalFilter?: GlobalFilterCallback<D>;
  topToolbar?: DataGridToolbar<D>;
  selectable?: boolean;
  rowContent?: DataGridRowContent<D>;
  rowContextMenu?: DataGridRowContextMenu<D>;
  initialState?: Partial<TableState<D>>;
};

export const DataGrid = <D extends AnyObject>({
  className,
  data,
  columns,
  disableGlobalFilter,
  globalFilter,
  topToolbar,
  selectable,
  rowContent,
  initialState,
  ...props
}: DataGridProps<D>) => {
  const viewport = useViewport();

  const [globalFilterVisible, toggleGlobalFilterVisible] = useToggle(
    false,
    toggleFn
  );

  const [filtersVisible, toggleFiltersVisible] = useToggle(false, toggleFn);

  useEffect(() => {
    if (viewport.isWide) toggleGlobalFilterVisible(false);
  }, [viewport]);

  useKeyBindings({
    Enter: () => globalFilterVisible && toggleGlobalFilterVisible(false),
  });

  const table = useTable<D>(
    {
      data,
      columns,
      defaultColumn,
      disableGlobalFilter,
      globalFilter,
      initialState,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    selectable ? makeSelectionColumn : noop
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    flatHeaders,
    rows,
    prepareRow,
    state,
    globalFilteredRows,
    setGlobalFilter,
    getToggleAllRowsSelectedProps,
    selectedFlatRows,
    globalFilteredFlatRows,
  } = table;

  useEffect(() => setGlobalFilter(state.globalFilter), [data]);

  const context = useMemo<DataGridContext<D>>(
    () => ({
      rows,
      filteredFlatRows: globalFilteredFlatRows,
      selectedFlatRows,
      viewport,
      filtersVisible,
    }),
    [rows, globalFilteredFlatRows, selectedFlatRows, viewport, filtersVisible]
  );

  const tableComponents = useMemo<TableComponents<DataGridContext<D>>>(
    () => ({
      Table: ({ context, ...props }) => (
        <table
          {...mergeStyleProps(getTableProps(), props, {
            className: "w-full",
          })}
        />
      ),
      TableHead: React.forwardRef(({ context, ...props }, ref) => (
        <thead
          className="text-left bg-white shadow-sm shadow-gray-200"
          {...props}
          ref={ref}
        />
      )),
      TableRow: ({ context, ...props }) => {
        const row = context.rows[props["data-index"]];

        return (
          <tr
            {...mergeStyleProps(props, row.getRowProps(), {
              className: row.isSelected ? "bg-blue-50" : "bg-gray-50",
            })}
          />
        );
      },
      TableBody: React.forwardRef(({ context, ...props }, ref) => (
        <tbody
          {...mergeStyleProps(getTableBodyProps(), props, {
            className: "text-gray-600",
          })}
          ref={ref}
        />
      )),
    }),
    []
  );

  const headerContent = useCallback(() => {
    if (viewport.isNarrow) return <tr className="h-px" />;

    return (
      viewport.isWide &&
      headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th
              {...mergeStyleProps(
                column.getHeaderProps(column.getSortByToggleProps()),
                {
                  className: `text-ellipsis
                              align-top pr-1 pb-1 first:pl-1 last:pr-1 font-normal`,
                  style: {
                    position: column.sticky ? "sticky" : "static",
                    left: 0,
                    width: column.width,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                  },
                }
              )}
            >
              <label
                className="flex flex-nowrap pb-1 text-slate-900 font-normal cursor-pointer"
                htmlFor={column.id}
              >
                <span className="flex-shrink overflow-hidden">
                  {column.render("Header")}
                </span>
                {column.isSorted && (
                  <Icon
                    className="flex-shrink-0 text-blue-500 text-lg transition-transform ml-1"
                    rotate={column.isSortedDesc ? 180 : 0}
                    as={ArrowUpLineIcon}
                  />
                )}
              </label>
              {filtersVisible && (
                <div onClick={stopPropagation}>
                  {column.canFilter && column.render("Filter")}
                </div>
              )}
            </th>
          ))}
        </tr>
      ))
    );
  }, [viewport, headerGroups, filtersVisible]);

  const itemContent = useMemo<ItemContent<void, DataGridContext<D>>>(() => {
    return (index, _, context) => {
      const { rows, viewport } = context;
      const row: Row<D> = rows[index];

      prepareRow(row);

      const content = rowContent?.(index, row, context);

      if (content) return content;

      return (
        <>
          {viewport.isNarrow && (
            <div className="flex px-3 py-2 border-b">
              {row.cells.map((cell) => (
                <div className="flex-1 px-2">
                  <div className="text-slate-900 font-normal pb-1">
                    {cell.column.render("Header")}
                  </div>
                  {cell.render("Cell")}
                </div>
              ))}
            </div>
          )}
          {viewport.isWide &&
            row.cells.map((cell) => (
              <td
                {...mergeStyleProps(cell.getCellProps(), {
                  className:
                    "text-normal px-1 py-3 first:pl-2 last:pr-2 border-b border-gray-200",
                })}
              >
                <div>{cell.render("Cell")}</div>
              </td>
            ))}
        </>
      );
    };
  }, [rowContent, state]);

  return (
    <div
      className={`${className} flex flex-col flex-nowrap rounded-lg overflow-hidden transition-all ${
        globalFilterVisible ? "mt-4" : "mt-1"
      } ${viewport.isWide && "mx-7 border border-gray-200"}`}
    >
      {viewport.isNarrow && (
        <>
          {!disableGlobalFilter && (
            <div className="absolute top-1 right-2 z-10">
              <IconButton
                className="shadow-md shadow-slate-300"
                circle
                appearance="subtle"
                icon={<SearchIcon />}
                onClick={toggleGlobalFilterVisible}
              />
              {
                <DrawerStyled
                  placement="top"
                  open={globalFilterVisible}
                  onClose={toggleGlobalFilterVisible}
                  backdrop={false}
                  style={{ height: "unset", zIndex: 9001 }}
                >
                  <div className="flex flex-row items-center justify-between pr-2 pl-4 py-3">
                    <GlobalFilter
                      globalFilter={state.globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                    <IconButton
                      className="ml-2"
                      circle
                      icon={<CloseIcon />}
                      onClick={toggleGlobalFilterVisible}
                    />
                  </div>
                </DrawerStyled>
              }
            </div>
          )}
          <div className="flex flex-row my-0.5 mx-1 items-start">
            <div>
              <SelectRowCheckbox
                {...mergeStyleProps(getToggleAllRowsSelectedProps(), {
                  className:
                    "bg-gray-50 rounded-lg border border-gray-300 box-border",
                })}
              >
                <span className="pr-2 ">
                  {selectedFlatRows.length || <FormattedMessage id="all" />}
                </span>
              </SelectRowCheckbox>
            </div>
            <div className="flex flex-row flex-1 justify-end m-1">
              {topToolbar?.(context)}
            </div>
          </div>
        </>
      )}
      {viewport.isWide && (
        <div className="global-filters flex flex-row items-center m-1 mb-2 relative">
          <IconButton
            className={`mr-1 hover:shadow-md ${
              filtersVisible
                ? "focus:text-white focus:bg-blue-500 text-white bg-blue-500"
                : "bg-gray-100"
            }`}
            tabIndex={0}
            onClick={toggleFiltersVisible}
            size="sm"
            icon={<BiFilter size={18} className="rs-icon -m-px" />}
          />
          {!disableGlobalFilter && (
            <GlobalFilter
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
          <div className="flex flex-row flex-1 justify-end ml-1">
            {topToolbar?.(context)}
          </div>
        </div>
      )}

      <div className="flex-1 border-b border-stale-50">
        <TableVirtuoso
          {...mergeStyleProps(props, { className: "h-full" })}
          totalCount={rows.length}
          context={context}
          components={tableComponents}
          fixedHeaderContent={headerContent}
          itemContent={itemContent}
        />
      </div>

      <div className="text-slate-800 my-2 ml-2">
        <FormattedMessage
          id="pagination.total"
          values={{
            total: (
              <b>
                <FormattedNumber
                  value={globalFilteredRows.length}
                  style="decimal"
                />
              </b>
            ),
          }}
        />
      </div>
    </div>
  );
};

/**
 * TODO:
 * Keybindings: Esc, Select with 'Space', 'Enter', navigate with 'Up', 'Down'
 * Mobile: Swipe Down to close modal
 */
