import { Contact, Customer } from "@api/query/customerApi";
import AddressLink from "@components/ui/AddressLink";
import DrawerModal from "@components/ui/DrawerModal";
import FormattedEmail from "@components/ui/FormattedEmail";
import FormattedIndentification from "@components/ui/FormattedIndentification";
import FormattedPhoneNumber from "@components/ui/FormattedPhoneNumber";
import preventDefault from "@utils/preventDefault";
import useViewport from "@utils/useViewport";
import React, { useState } from "react";
import { BiCurrentLocation } from "react-icons/bi";
import { FaPen } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { Virtuoso } from "react-virtuoso";
import { IconButton } from "rsuite";
import { ContactItem } from "./ContactItem";

export type CustomerSummaryProps = {
	value: Customer;
	onContactChange(contact: Contact): void
};

export const CustomerSummary: React.FC<CustomerSummaryProps> = ({
	value: {
		fullName,
		primaryEmail,
		phone,
		mobile,
		address,
		state,
		cityDescription,
		identification,
		idType,
		actionOnShipment,
		city,
		contacts
	},
	onContactChange
}) => {
	const [showContactModal, setShowContactModal] = useState(false)
	const viewport = useViewport();

	const showHideContactModal = () => {
		setShowContactModal(!showContactModal)
	}

	return (
		<>
			<div className="mb-2">
				<div className="flex flex-col">
					<h4 className="text-base">{fullName}</h4>
					<span>
						<span className="font-semibold mr-1">{idType}</span>
						<FormattedIndentification value={identification} />
					</span>
				</div>

				<div className="my-4 border-t border-gray-300 -mx-8" />
				<div className="flex flex-col">
					<div className='flex justify-between'>
						<span className="text-base font-semibold mb-1">
							Información de contacto
						</span>
						<span>
							<IconButton
								icon={<FaPen />}
								size="sm"
								onClick={showHideContactModal}
								title="">
							</IconButton>
						</span>
					</div>
					<FormattedEmail
						value={primaryEmail}
						placeholder="No se encontró email"
						onClick={preventDefault()}
					/>
					<FormattedPhoneNumber value={mobile} placeholder="Movil no proveido" />
					<FormattedPhoneNumber value={phone} placeholder="" />
				</div>

				<div className="my-4 border-t border-gray-300 -mx-8" />
				<div className="mt-4 flex flex-col">
					<h4 className="text-base mb-1">Información de envio</h4>
					{address ? (
						<span className="flex flex-col select-text">
							<span>
								<span className="mr-1">{(state && state.name) || ''},</span>
								<span className="mr-1">{(city && city.name) || ''}</span>
							</span>
							<span className="flex flex-row items-center">
								<span>{address}</span>
								<AddressLink query={address} place={cityDescription}>
									<BiCurrentLocation className="text-lg mx-2" />
								</AddressLink>
							</span>
						</span>
					) : (
						'Dirección no proveida'
					)}
				</div>
			</div>
			<DrawerModal
				// className="md:w-96"
				DrawerProps={{ size: 'full' }}
				open={showContactModal}
				onClose={showHideContactModal}
				placement={viewport.isNarrow ? 'bottom' : 'floating'}
				title={<FormattedMessage id="customer.select-contact" />}
				body={
					<div className="flex flex-col flex-grow">
						<div className="flex-grow mt-2 relative overflow-hidden md:h-64 shadow-inner">
							<Virtuoso
								style={{ height: '100%' }}
								increaseViewportBy={200}
								data={contacts}
								itemContent={(_index, value) => (
									<ContactItem
										value={value}
										onClick={() => {
											showHideContactModal()
											onContactChange(value);
										}}
									/>
								)}
							/>
						</div>
					</div>

				}
			>
			</DrawerModal>
		</>
	);
};