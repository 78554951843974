import { cleanupString } from "./Tools";

export function mapCreateCustomerDto(
  fullPayload,
  companyId,
  fullDataValidation,
  erp
) {
  if (fullDataValidation) {
    fullPayload.companyId = companyId;
    fullPayload.erp = erp;
    fullPayload.idShopify = "230034444";
    fullPayload.source = "savia";
    return fullPayload;
  }
  fullPayload.lastName != ""
    ? (fullPayload.lastName = fullPayload.lastName)
    : (fullPayload.lastName = " - ");
  fullPayload.identification != ""
    ? (fullPayload.identification = fullPayload.identification)
    : (fullPayload.identification = "1");
  fullPayload.email
    ? (fullPayload.email = fullPayload.email)
    : (fullPayload.email = "defaultSavia@savia.com");
  fullPayload.address
    ? (fullPayload.address = fullPayload.address)
    : (fullPayload.address = "Calle ABC");
  fullPayload.idShopify = "2300344";
  fullPayload.source = "savia";
  fullPayload.companyId = companyId;
  fullPayload.erp = erp;
  return fullPayload;
}

export const mapCustomersList = (parentArray) => {
  let childArray = [];

  childArray = parentArray.map((childNode) => {
    const container = {};
    container["value"] = childNode.id;
    container["key"] = childNode.id;
    container["id"] = childNode.id;
    container["label"] = childNode.fullName;
    container["name"] = childNode.fullName;
    container["city"] = childNode.city;
    container["stateId"] = childNode.stateId;
    container["mobile"] = childNode.mobile;
    container["listPriceId"] = childNode.listPriceId;
    container["address"] = childNode.primaryAddress;
    container["email"] = childNode.primaryEmail;
    container["paymentTerms"] = childNode.paymentTerms;
    container["idERP"] = childNode.idERP;
    container["paymentMethodId"] = childNode.paymentMethodId;
    container["channelId"] = childNode.channelId;
    container["invoicingSettingId"] = childNode.invoicingSettingId;
    container["actionOnShipment"] = childNode.actionOnShipment;
    return container;
  });
  return childArray;
};

export const mapSearchFilters = (payload) => {
  let fields, values;
  let searchFilter = {};

  let columns = Object.keys(payload);
  let filters = Object.values(payload);

  fields = cleanupString(columns.join(","));
  values = cleanupString(filters.join(","));

  searchFilter.columns = fields;
  searchFilter.filters = values;

  return searchFilter;
};
