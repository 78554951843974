import { Customer } from '@api/query/customerApi';
import {
  DeliverySlot,
  useFindDeliverySlotsQuery,
} from '@api/query/deliverySlot';
import { useFindPaymentMethodQuery } from '@api/query/paymentMethod';
import { dayjsRanges, formats } from '@utils/dayjs';
import dayjs from 'dayjs';
import { styled } from 'goober';
import { uniqBy } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DatePicker, Form, Panel, SelectPicker } from 'rsuite'
import { getMainLoggingParams } from '@selectors/SystemSelector'

export type AdditionalInfoProps = {
  value: AdditionalInfoFormValues;
  onChange: Dispatch<SetStateAction<AdditionalInfoFormValues>>;
  className?: string;
  customer?: Customer;
  deliveryHoursAhead?: number;
  fulfillmentTypes?: [fulfillmentType]
};

type fulfillmentType = {
  id: string;
  description: string;
  idDelivery: string;
  deliveryType: string;
  companyId: string;
}

export type AdditionalInfoFormValues = {
  deliveryDate: Date;
  deliverySlot: string[];
  deliverySlotId: string;
  invoicingSettingId: string;
  fulfillmentTypeId: string;
  fulfillmentType: fulfillmentType;
  channelId: string;
  paymentMethodId: string;
  paymentMethod: {
    id: string;
    description: string;
    idERP: string
    accountIdERP: string;
    receiptPaymentMethodIdERP: string;
  };
  channel: {
    id: string;
    description: string;
  };
  actionOnShipment: string;
};

const FormStyled = styled(Form)`
  & .rs-form:not(.rs-form-inline),
  & .rs-form-group:not(:last-child) {
    margin-bottom: 8px !important;
  }
`;

function calcDeliverySlotOptions(deliveryDate: Date, slots?: DeliverySlot[], deliveryHoursAhead?: number) {
  const deliverySlots = []
  slots?.forEach(slot => {
    if (slot?.usedCapacity < slot?.capacity) {
      const slotDate = new Date(slot?.startDateTime)
      var numberOfMlSeconds = slotDate.getTime();
      const addMlSeconds = (deliveryHoursAhead * 60) * 60000;
      const newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      if (new Date() < newDateObj) {
        deliverySlots.push(slot)
      }
    }
  })
  return uniqBy(
    (deliverySlots || [])
      .map(({ startDateTime, endDateTime, id }) => ({
        label: `${dayjs(startDateTime).format(formats.time)} - ${dayjs(
          endDateTime,
        ).format(formats.time)}`,

        value: [startDateTime, endDateTime, id],
      })),
    'label',
  );
}

const AdditionalInfo: React.FC<AdditionalInfoProps> = ({
  className,
  customer,
  value,
  deliveryHoursAhead,
  onChange,
  fulfillmentTypes,
}) => {
  const paymentMethods = useFindPaymentMethodQuery({});
  const deliverySlot = useFindDeliverySlotsQuery({
    startDateTime: new Date(value.deliveryDate),
    orderBy: 'startDateTime:ASC'
  });
  const deliverySlotOptions = useMemo(
    () => calcDeliverySlotOptions(value.deliveryDate, deliverySlot.data?.items, deliveryHoursAhead),
    [deliverySlot.data, value.deliveryDate, deliveryHoursAhead],
  );
  const { automaticDeliveryDateTimeSet, choosePaymentMethod } = useSelector(getMainLoggingParams);

  useEffect(() => {
    if (!deliverySlot.isSuccess) return;
    if (value.deliverySlotId) {
      const foundDeliverySlot = deliverySlot.data?.items.find(
        slot => slot.id === value.deliverySlotId,
      );
      if (!foundDeliverySlot) {
        return;
      }
      onChange(values => ({
        ...values,
        deliverySlot: [
          foundDeliverySlot.startDateTime,
          foundDeliverySlot.endDateTime,
          foundDeliverySlot.id,
        ],
      }));
    }
  }, [value.deliverySlotId, deliverySlot]);

  useEffect(() => {
    if (value.paymentMethodId) {
      const paymentMethod = paymentMethods.data?.items.find(
        paymentMethod => paymentMethod.id === value.paymentMethodId,
      );

      onChange(
        values =>
        ({
          ...values,
          paymentMethod,
        } as any),
      );
    }
  }, [value.paymentMethodId, paymentMethods]);

  useEffect(() => {
    if (value.fulfillmentTypeId) {
      const fulfillmentType = fulfillmentTypes?.find(
        item => item.id === value.fulfillmentTypeId,
      );
      onChange(
        values =>
        ({
          ...values,
          fulfillmentType,
        } as any),
      );
    }
  }, [value.fulfillmentTypeId, fulfillmentTypes]);

  const selectDefaultDeliverySlot = date => {
    setTimeout(
      () =>
        onChange(values => ({
          ...values,
          deliverySlot:
            calcDeliverySlotOptions(date, deliverySlot.data?.items, deliveryHoursAhead)[0]?.value || [],
        })),
      200,
    );
  };

  const disabledPaymentMethod = choosePaymentMethod === false ? true : false

  return (
    <Panel
      className={`${className}`}
      header={
        <div className="flex justify-between">
          <span className="font-medium">Información adicional</span>
        </div>
      }
    >
      <FormStyled
        className="grid grid-cols-2 justify-items-stretch gap-x-2 "
        formValue={value}
        fluid
        onChange={onChange}
      >
        {!automaticDeliveryDateTimeSet && 
          <div>
            <Form.Group className="col-span-full">
          <Form.ControlLabel>Fecha de entrega</Form.ControlLabel>
          <Form.Control
            name="deliveryDate"
            style={{ width: '100%' }}
            disabledDate={date =>
              dayjs(date).isBefore(new Date(), 'day')
            }
            editable={false}
            appearance="subtle"
            format="E dd 'de' MMMM yyyy"
            oneTap
            cleanable={false}
            accepter={DatePicker}
            ranges={dayjsRanges(['today', 'tomorrow']).map(
              ({ label, value }) => ({
                label,
                value: value[1],
              }),
            )}
            onChangeCalendarDate={selectDefaultDeliverySlot}
          />
          </Form.Group>
          {deliverySlot.isSuccess && (
          <Form.Group className="col-span-full">
            <Form.ControlLabel>Franja horaria</Form.ControlLabel>
            <Form.Control
              name="deliverySlot"
              accepter={SelectPicker}
              appearance="subtle"
              style={{ width: '100%' }}
              cleanable={false}
              searchable={false}
              data={deliverySlotOptions}
            />
          </Form.Group>
          )}
          </div>
        }

        {fulfillmentTypes && (
          <Form.Group className="col-span-full">
            <Form.ControlLabel>Tipo Logistica</Form.ControlLabel>
            <Form.Control
              name="fulfillmentTypeId"
              style={{ width: '100%' }}
              appearance="subtle"
              cleanable={false}
              accepter={SelectPicker}
              searchable={false}
              data={fulfillmentTypes?.map(
                ({
                  id,
                  description,
                  idDelivery,
                  deliveryType,
                  companyId,
                }) => ({
                  label: description,
                  value: id,
                  idDelivery,
                  deliveryType,
                  companyId,
                }),
              )}
            />
          </Form.Group>
        )}

        {paymentMethods.isSuccess && (
          <Form.Group className="col-span-full">
            <Form.ControlLabel>Método de pago</Form.ControlLabel>
            <Form.Control
              name="paymentMethodId"
              style={{ width: '100%' }}
              appearance="subtle"
              cleanable={false}
              accepter={SelectPicker}
              searchable={false}
              disabled={disabledPaymentMethod}
              data={paymentMethods?.data?.items?.map(({ description, id, idERP }) => ({
                label: description,
                value: id,
                idERP
              }))}
            />
          </Form.Group>
        )}
      </FormStyled>
    </Panel>
  );
};

export default AdditionalInfo;
