import { Contact } from "@api/query/customerApi";
import React from "react";

type ContactItemProps = {
	value: Contact;
	active?: boolean;
	onClick?(ev: React.MouseEvent<HTMLElement, MouseEvent>, value: Contact): void;
};

export const ContactItem: React.FC<ContactItemProps> = ({
	value,
	active,
	onClick,
}) => {
	return (
		<div
			className={`flex flex-row flex-wrap px-4 py-2 border-y -mb-px cursor-pointer transition-colors ${active
				? 'bg-blue-500 text-white'
				: 'text-slate-700 hover:bg-slate-200 hover:text-slate-700'
				} `}
			onClick={ev => onClick(ev, value)}>
			<span className="basis-1/2 font-medium">
				{value.name} {value?.lastName}
			</span>
			<span className="basis-1/2 text-end text-current visited:text-current hover:text-current">
				<span className="basis-1/2 font-medium">
					{value?.state?.name}
					{', '}
					{value?.city?.name}
				</span>
			</span>
		</div>
	);
};