// @ts-ignore
import SaviaIcon from '@images/saviaIcon';
import { findRouteByPath, isMenuEntry, isRouteEntry } from '@router/types';
import { Close, Menu } from '@rsuite/icons';
import { selectIsAuthorized } from '@selectors/AuthorizationSelector';
import { getMainLoggingParams } from '@selectors/SystemSelector';
import routes from '@src/router/RouteEntries';
import first from '@utils/first';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useLocalstorageState } from 'rooks';
import { Button, Drawer, IconButton, Nav, NavProps, Sidenav } from 'rsuite';

const defaultOpenKeys = ['2'];

const SideNavMenu = () => {
  const { $t } = useIntl();
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [openKeys, setOpenKeys] = useLocalstorageState(
    'app.nav.openkeys',
    defaultOpenKeys,
  );

  const { version }: Record<'version', string> =
    useSelector(getMainLoggingParams);

  const isAuthorized = useSelector(selectIsAuthorized);

  const [activeKey, setActiveKey] = useState('1');

  useEffect(() => {
    setActiveKey(
      findRouteByPath(location.pathname, routes.flatTree)?.key || '1',
    );
  }, [location.pathname]);

  const selectHandler: NavProps['onSelect'] = (eventKey, event) => {
    event.preventDefault();

    setActiveKey(eventKey);

    const route =
      eventKey && routes.flatTree.find(entry => entry.key === eventKey);

    if (route && isRouteEntry(route)) {
      history.push({ pathname: first(route.path) });
      setOpen(false);
    }
  };

  if (!isAuthorized) return;

  return (
    <>
      <IconButton
        className={`absolute transition-all ${
          open ? 'left-2' : 'left-1'
        } top-2`}
        appearance="subtle"
        circle
        icon={open ? <Close /> : <Menu />}
        style={{ position: 'absolute', zIndex: 1100 }}
        onClick={() => setOpen(v => !v)}
      />
      <Drawer
        backdrop
        open={open}
        placement="left"
        autoFocus
        onClose={() => setOpen(false)}
        size="full"
        className="max-w-xs">
        <Sidenav
          openKeys={openKeys}
          onOpenChange={setOpenKeys}
          className="min-h-full flex flex-col">
          <div className="relative flex-1 mt-10">
            <Sidenav.Body className="overflow-auto absolute inset-0">
              <Nav activeKey={activeKey} onSelect={selectHandler}>
                {Object.entries(routes.tree).map(([key, entry]) =>
                  isMenuEntry(entry) ? (
                    <Nav.Menu
                      key={entry.key}
                      eventKey={entry.key}
                      title={entry.label ? $t(entry.label) : key}
                      icon={entry.Icon && <entry.Icon />}>
                      {Object.entries(entry.routes).map(
                        ([key, subEntry]) =>
                          subEntry.placement === 'sidenav' && (
                            <Nav.Item
                              key={subEntry.key}
                              eventKey={subEntry.key}
                              icon={subEntry.Icon && <subEntry.Icon />}>
                              {subEntry.label ? $t(subEntry.label) : key}
                            </Nav.Item>
                          ),
                      )}
                    </Nav.Menu>
                  ) : (
                    isRouteEntry(entry) &&
                    entry.placement === 'sidenav' && (
                      <Nav.Item
                        key={entry.key}
                        eventKey={entry.key}
                        icon={entry.Icon && <entry.Icon />}>
                        {entry.label ? $t(entry.label) : key}
                      </Nav.Item>
                    )
                  ),
                )}
              </Nav>
            </Sidenav.Body>
          </div>
          <div className="flex items-center ml-2 mb-1">
            <SaviaIcon className="w-8 mx-2" />
            <div className="flex flex-col mb-1">
              <Button
                as="a"
                appearance="link"
                size="sm"
                href="http://savia.co/"
                target="_blank"
                className="p-0 m-0">
                Savia App
              </Button>
              <span className="text-xs">
                {$t({ id: 'app.version' }, { version })}
              </span>
            </div>
          </div>
        </Sidenav>
      </Drawer>
    </>
  );
};

export default SideNavMenu;
