import React from "react";
import { useIntl } from 'react-intl';
import { Button, Loader, Message, Modal, toaster } from "rsuite";
import api from '@api/SecurityApi';
import * as constants from '../../../utils/Constants';

const ModalLoginOtp = ({
  visible,
  onClose,
  email,
  success,
}) => {
  const [code1, setValueCode1] = React.useState('');
  const [code2, setValueCode2] = React.useState('');
  const [code3, setValueCode3] = React.useState('');
  const [code4, setValueCode4] = React.useState('');
  const [code5, setValueCode5] = React.useState('');
  const [code6, setValueCode6] = React.useState('');
  const [error, setError] = React.useState('');
  const [spiner, setSpiner] = React.useState(false);

  const onCloseModal = () => {
    onClose()
  }

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {
      console.log("next");
     
        const next = elmnt.target.tabIndex;
        if (next < 6) {
          elmnt.target.form.elements[next].focus()
        }
    }

  }

  const onSubmitForm = () => {
    let code = `${code1}${code2}${code3}${code4}${code5}${code6}`
    code = code?.toUpperCase()
    onClose()
    success({email, code}, 'login otp')
  }

  const sendCode = async () => {
    try {
      setSpiner(true)
      const body = {
        email: email,
        appType: 'B2BPORTAL'
      };
      await api.otp(body);
      setSpiner(false)
    } catch (err) {
      console.log(err)
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          Usuario no autorizado para ingresar a Savia
        </Message>,
      );
      setSpiner(false)
    }
  }
  const handleChangeCode1 = event => {
    if (event.target.value?.length === 0) {
      setError('Debe llenar todas las casillas con 1 solo digito')
    } else {
      setError('')
    }
    setValueCode1(event.target.value?.toUpperCase())
  }
  const handleChangeCode2 = event => {
    if (event.target.value?.length === 0) {
      setError('Debe llenar todas las casillas con 1 solo digito')
    } else {
      setError('')
    }
    setValueCode2(event.target.value?.toUpperCase())
  }
  const handleChangeCode3 = event => {
    if (event.target.value?.length === 0) {
      setError('Debe llenar todas las casillas con 1 solo digito')
    } else {
      setError('')
    }
    setValueCode3(event.target.value?.toUpperCase())
  }
  const handleChangeCode4 = event => {
    if (event.target.value?.length === 0) {
      setError('Debe llenar todas las casillas con 1 solo digito')
    } else {
      setError('')
    }
    setValueCode4(event.target.value?.toUpperCase())
  }
  const handleChangeCode5 = event => {
    if (event.target.value?.length === 0) {
      setError('Debe llenar todas las casillas con 1 solo digito')
    } else {
      setError('')
    }
    setValueCode5(event.target.value?.toUpperCase())
  }
  const handleChangeCode6 = event => {
    if (event.target.value?.length === 0) {
      setError('Debe llenar todas las casillas con 1 solo digito')
    } else {
      setError('')
    }
    setValueCode6(event.target.value?.toUpperCase())
  }

  const { $t } = useIntl();

  let chars = 4
  let emailLabel = email.replace(/[a-z0-9\-_.]+@/ig, (c) => c.substr(0, chars) + c.split('').slice(chars, -1).map(v => '*').join('') + '@')

  return (
    <div>
      <Modal
        className="modal-dialog-container mt-28"
        open={visible}
        size="xs"
        keyboard
        onClose={onCloseModal}
      >
        <Modal.Header>
        </Modal.Header>
        <Modal.Body>
          <div className="relative flex flex-col justify-center overflow-hidden py-8">
            <div className="relative bg-white px-6 pb-9  mx-auto w-full max-w-lg rounded-2xl">
              <div className="mx-auto flex w-full max-w-md flex-col space-y-6">
                <div className="flex flex-col items-center justify-center text-center space-y-2">
                  <div className="font-semibold text-3xl">
                    <p>{$t({ id: 'route.email-verification' })}</p>
                  </div>
                  <div className="flex flex-row text-sm font-medium text-gray-400">
                    <p>{`${$t({ id: 'route.send-code' })} ${emailLabel}`}</p>
                  </div>
                </div>
                <div>
                  <form role="form" action="" method="post" name="form1">
                    <div className="flex flex-col space-y-4">
                      <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                        <div className="w-12 h-12 ">
                          <input onChange={handleChangeCode1} maxlength="1" minlength="1" onKeyUp={e => inputfocus(e)} value={code1} tabIndex="1" className="w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700" type="text" name="code1" id="code1"/>
                        </div>
                        <div className="w-12 h-12 ">
                          <input onChange={handleChangeCode2} maxlength="1" minlength="1" onKeyUp={e => inputfocus(e)} value={code2} tabIndex="2" className="w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700" type="text" name="code2" id="code2"/>
                        </div>
                        <div className="w-12 h-12 ">
                          <input onChange={handleChangeCode3} maxlength="1" minlength="1" onKeyUp={e => inputfocus(e)} value={code3} tabIndex="3" className="w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700" type="text" name="code3" id="code3"/>
                        </div>
                        <div className="w-12 h-12 ">
                          <input onChange={handleChangeCode4} maxlength="1" minlength="1" onKeyUp={e => inputfocus(e)} value={code4} tabIndex="4" className="w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700" type="text" name="code4" id="code4"/>
                        </div>
                        <div className="w-12 h-12 ">
                          <input onChange={handleChangeCode5} maxlength="1" minlength="1" onKeyUp={e => inputfocus(e)} value={code5} tabIndex="5" className="w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700" type="text" name="code5" id="code5"/>
                        </div>
                        <div className="w-12 h-12 ">
                          <input onChange={handleChangeCode6} maxlength="1" minlength="1" onKeyUp={e => inputfocus(e)} value={code6} tabIndex="6" className="w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700" type="text" name="code6" id="code6"/>
                        </div>
                      </div>
                      <h2 style={{ color: 'red', fontSize: '12px' }}>{error}</h2>
                      <div className="flex flex-col space-y-4">
                        <div className="flex items-center justify-center">
                          <Button appearance="primary" className=" w-full text-center" onClick={onSubmitForm}>
                            {$t({ id: 'route.verify-account' })}
                          </Button>
                        </div>
                        <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                          <p>{$t({ id: 'route.recieve-code' })}</p> <a onClick={sendCode} className="flex flex-row items-center text-blue-600 cursor-pointer" >{$t({ id: 'route.Resend' })}</a>
                        </div>
                      </div>
                      {spiner && (
                        <div className='text-center'>
                          <Loader className='items-center justify-center text-center' content="Loading..." size="md" />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalLoginOtp;

