import axios from "axios";
import { API_BACKEND_URL } from "../utils/Constants";

const api = {
  async signupCompany(data) {
    const endpoint = API_BACKEND_URL + "signup/create/";
    return await axios
      .post(endpoint, data)
      .then((response) => {
        return {
          status: response.status,
          data: response.data.result,
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },
};

export default api;
