import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import types from '../actions/ActionTypes';
import api from '../api/DashboardApi';
import * as Mappers from '../utils/Mappers/DashboardMappers';

function* getDashboardData(action) {
  try {
    const companyId = action.payload.loggingParams.companyId;
    let daysRange = 0;
    let dashboardController = {};
    let buttonGroupStatus = [
      { button: 'lastMonth', active: false },
      { button: 'currentMonth', active: false },
    ];
    const dashboardData = {};
    const selection = action.payload.selection;
    let response = null;

    yield put(actions.setShowSpinner(true));

    if (selection === 'PREVIOUS_MONTH') {
      daysRange = -30;
      buttonGroupStatus[0].active = true;
      response = yield call(api.getDashboardData, {
        companyId,
        customerId: action.payload.loggingParams.customerId,
        queryType: selection,
        deliveryStatusId: action.payload.deliveryStatusId,
        cancelStatusId: action.payload.cancelStatusId,
      });
    } else if (selection === 'CURRENT_MONTH' || selection === undefined) {
      daysRange = 30;
      buttonGroupStatus[1].active = true;
      response = yield call(api.getDashboardData, {
        companyId,
        customerId: action.payload.loggingParams.customerId,
        queryType: selection,
        deliveryStatusId: action.payload.deliveryStatusId,
        cancelStatusId: action.payload.cancelStatusId,
      });
    } else {
      buttonGroupStatus[2].active = true;
      response = yield call(api.getDailyLiveStatus);
    }

    if (response && response.status === 200) {
      // set dashboard controllers
      dashboardController.currentSelection = selection;
      dashboardController.buttonGroupStatus = buttonGroupStatus;
      dashboardController.lastUpdate = Date.now();

      if (daysRange === 0) {
        const liveStatusFirsRow = Mappers.getTodaysTotalOrders(
          response.data.orders,
        );
        dashboardData.firstRow = liveStatusFirsRow;
        yield put(actions.setLiveStatusData(dashboardData));
      } else {
        const localTimeData = Mappers.getLocalTimeData(
          response.data,
          'createdAt',
        );
        const currentMonthOnTime = Mappers.getOnTimeData(
          localTimeData,
          action.payload.deliveryStatusId,
          action.payload.cancelStatusId,
        );
        const customerData = Mappers.getDataByCustomer(
          localTimeData,
          action.payload.topTenDimension,
          action.payload.deliveryStatusId,
          action.payload.cancelStatusId,
        );
        dashboardData.currentMonthOnTime = currentMonthOnTime;
        dashboardData.customerData = customerData;

        yield put(actions.setDashboardData(dashboardData));
      }

      yield put(actions.setDashboardControllers(dashboardController));
    } else {
      console.log('oups! something went wrong');
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export default function* () {
  yield all([takeLatest(types.GET_DASHBOARD_DATA, getDashboardData)]);
}
