import { all, call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions";
import types from "../actions/ActionTypes";
import api from "../api/ListPriceApi";
import { mapListPrice } from "../utils/Mappers/MasterDataMappers";
import { rollbarErrors } from "@utils/rollbarErrors";

function* getListPrice(action) {
  try {
    const listPrice = yield call(api.getListPrice, action.payload);

    yield put(actions.updateFullListPrice(mapListPrice(listPrice.data.items)));
  } catch (error) {
    rollbarErrors(error, action.payload, 'GET/products/prices', null, null)
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export default function* () {
  yield all([takeLatest(types.GET_LIST_PRICE, getListPrice)]);
}
