import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Message, toaster } from 'rsuite';
import * as actions from '../actions';
import types from '../actions/ActionTypes';
import api from '../api/ProductionListApi';
import * as constants from '../utils/Constants';
import * as Mappers from '../utils/Mappers/ProductionListMapper';
import * as Notifications from '../utils/Notifications';

function* getProductionList(action) {
  try {
    const companyId = action.payload.loggingParams.companyId;
    yield put(actions.setShowSpinner(true));
    const response = yield call(
      api.getProductionList,
      companyId,
      action.payload.value,
    );

    if (response && response.status === 200) {
      const productionList = Mappers.mapOrdersToProductionList(response.data);
      yield put(actions.setProductionListData(productionList));
    } else {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {response.data}
        </Message>,
      );
    }
  } catch (error) {
    toaster.push(
      <Message
        type="error"
        showIcon
        closable
        duration={constants.NOTIFICATION_DURATION}
      >
        {Notifications.GENERAL_ERROR_MESSAGE}
      </Message>,
    );
    console.log(error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export default function* () {
  yield all([takeLatest(types.GET_PRODUCTION_LIST, getProductionList)]);
}
