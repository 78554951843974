// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BACKEND_URL } from "@utils/Constants";

export namespace Inventory {
  export type Status = "AVAILABLE" | "LOW_STOCK" | "OUT_OF_STOCK";
}

export type Inventory = {
  id: number;
  productId: number;
  productName: string;
  locationId: number;
  locationName: string;
  overallQty: number;
  reservedQty: number;
  availableQty: number;
  inventoryStatus: Inventory.Status;
  updatedAt: string;
};

export type PaginationOptions = {
  limit: number;
  skip: number;
};

export type InventoryRecords = {
  total: number;
  limit: number;
  offset: number;
  data: Inventory[];
};

function getSearchParams(options: Record<string, any>) {
  return new URLSearchParams(
    Object.entries(options).reduce<Record<string, string>>(
      (options, [key, value]) => ({ ...options, [key]: `${value}` }),
      {}
    )
  ).toString();
}

export const inventoryApi = createApi({
  reducerPath: "inventory-api",
  baseQuery: fetchBaseQuery({ baseUrl: API_BACKEND_URL }),
  endpoints: (builder) => ({
    searchInventoryByProductOrLocation: builder.query<
      InventoryRecords,
      { text: string; pagination: PaginationOptions }
    >({
      query: ({ text, pagination }) => ({
        url: `inventory/search-by-product-or-location/${text}?${getSearchParams(
          pagination
        )}`,
      }),
    }),
  }),
});

export const { useSearchInventoryByProductOrLocationQuery } = inventoryApi;

export default inventoryApi;
