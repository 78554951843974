import React from 'react';
import { FormatNumberOptions, FormattedNumberParts } from 'react-intl';

export type FormattedCurrencyProps = {
  value: number;
  className?: string;
  signClassName?: string;
  integerClassName?: string;
  fractionClassName?: string;
} & FormatNumberOptions;

const FormattedCurrency: React.FC<FormattedCurrencyProps> = ({
  className,
  signClassName,
  fractionClassName,
  integerClassName,
  value,
  ...numberOptions
}) => {
  return (
    <FormattedNumberParts
      value={value}
      maximumFractionDigits={2}
      minimumFractionDigits={2}
      {...numberOptions}>
      {parts => (
        <span className={className || 'text-base font-medium text-current'}>
          <span className={`mr-0.5 select-none ${signClassName}`}>$</span>
          <span className={integerClassName}>
            {parts
              .slice(0, -1)
              .reduce((str, part) => str + `${part.value}`, '')}
          </span>
          <span className={fractionClassName || 'text-sm'}>
            {parts.at(-1).value}
          </span>
        </span>
      )}
    </FormattedNumberParts>
  );
};

export default FormattedCurrency;
