export default {
  RESET_STATE: 'reset',
  SET_ODER_ITEM: 'set order item',
  SAVE_ORDER: 'save order',
  CANCEL_ORDER: 'cancel order',
  GET_ORDER_TRACKING: 'get order tracking',
  GET_ORDER_DATA: 'get order data',

  // master data, mainly used in NavBarContainer
  GET_PRODUCTS: 'get products',
  GET_CUSTOMERS: 'get customers',
  GET_STATUS: 'get status',
  GET_PAYMENT_METHODS: 'get payment methods',
  GET_LIST_PRICE: 'get list price',
  GET_USER_DEVICE: 'get user device',
  GET_TAGS: 'get tags',
  UPDATE_USER_DEVICE: 'update user device',
  UPDATE_FORM_ACTION: 'update form action',
  CLEAR_ORDER_ITEMS: 'clear order items',

  // grid Actions
  OPEN_UPDATE_ORDER_STATUS: 'open update order status',
  HIDE_UPDATE_ORDER_STATUS: 'hide update order status',
  ACTIVATE_BUTTON: 'activate button',

  // security-related actions
  GET_USER_AUTHORIZATION: 'get user authorization',
  UPDATE_USER_AUTHORIZATION: 'update user authorization',
  RESET_FULL_STATE: 'reset full state',
  LOG_OUT_USER: 'log out user',
  SHOW_SIGN_UP: 'show sign up',
  SET_SIGNUP_FORM: 'set signup form',
  SIGNUP_COMPANY: 'signup company',

  // update data master loaders
  UPDATE_STATE_CUSTOMERS: 'update state customers',
  UPDATE_STATE_PRODUCTS: 'update state products',
  UPDATE_STATE_STATUS: 'update state status',
  UPDATE_STATE_PAYMENT_METHODS: 'update state payment methods',
  UPDATE_STATE_DELIVERY_SLOTS: 'update state delivery slots',
  UPDATE_STATE_TAGS: 'update state tags',
  LOAD_SELECTED_DATE_DELIVERY_SLOTS: 'load selected date delivery slots',
  UPDATE_SELECTED_CUSTOMER: 'update selected customer',
  UPDATE_SELECTED_PRICE_LIST: 'update selected price list',
  UPDATE_SELECTED_PAYMENT_METHOD: 'update selected payment method',
  UPDATE_SELECTED_DELIVERY_SLOT: 'update selected delivery slot',
  UPDATE_SELECTED_CHANNEL: 'update selected channel',
  UPDATE_SELECTED_INVOICING_SETTINGS: 'update selected invoicing settings',
  UPDATE_SELECTED_ACTION_ON_SHIPMENT: 'update selected action on shipment',
  UPDATE_FULL_LIST_PRICE: 'update full list price',

  UPDATE_ACTIVE_USER: 'update active user',
  ADD_ROWS_GRID_ITEMS: 'add row grid items',
  DELETE_ROWS_GRID_ITEMS: 'delete row grid items',

  // update order status window
  SHOW_UPDATE_ORDER_STATUS: 'show update order status',
  UPDATE_ORDER_STATUS_STATUS_CHANGE: 'update order status status change',
  UPDATE_TRACKING_COMMENTS: 'update tracking comments',
  UPDATE_ORDER_STATUS: 'update order status',

  // show modal windows
  SHOW_ORDER_TRACKING: 'show order tracking',
  HIDE_ORDER_TRACKING: 'hide order tracking',

  UPDATE_LOADING: 'update loading',
  GET_ALL_ORDERS: 'get all orders',
  SEARCH_ORDERS: 'search orders',
  UPDATE_ORDERS_PAGING: 'update orders paging',
  UPDATE_ORDERS_CHECKED_KEYS: 'update orders checked keys',
  UPDATE_ORDERS_GRID_ROWS: 'update orders grid rows',
  UPDATE_ORDERS_FILTERS: 'update orders filters',
  UPDATE_ORDERS_HISTORY_FILTERS: 'update orders history filters',
  UPDATE_ORDERS_FIRST_LOAD: 'upadte orders first load',

  GET_CURRENT_ORDERS: 'get current orders',
  UPDATE_STATE_ORDERS: 'update state orders',
  REDIRECT: 'redirect',
  LOAD_EDIT_ORDER: 'load edit order',
  COPY_ORDER: 'copy order',
  LOAD_ITEMS_FOR_EDIT_ORDER: 'load items for edit order',
  EDIT_ORDER: 'edit order',
  SET_CONFIRM_ORDER_ACTION_VISIBLE: 'set confirm order action visible',
  SET_SLOT_STATUS: 'set slot status',
  INVOICE_FROM_PURCHASE_ORDER: 'invoice from purchase order',
  IS_INVOICEABLE: 'is invoiceable',
  // INVOICE_ORDER: "invoice order",
  CREATE_PURCHASE_ORDER: 'create purchase order',
  OPEN_CONFIRM_INVOICE_ACTION: 'open confirm invoice action',
  SET_ORDER_TRACKING: 'set order tracking',
  SET_INVOICE_CONFIRM_ACTION_VISIBLE: 'set invoice confirm action visible',

  // capturing change events on form's fields
  CHANGE_ORDER_PAYMENT_METHOD: 'change order payment method',
  CHANGE_ORDER_CUSTOMER: 'change order customer',
  CHANGE_ORDER_STATUS: 'change order status',
  CHANGE_ORDER_COMMENTS: 'change order comments',
  CHANGE_NOTES: 'change notes',
  CHANGE_ORDER_PROMISED_DELIVERY_DATE: 'change promised delivery date',
  SET_ORDER_FILTERS: 'set order filter',
  SET_SHOW_SPINNER: 'set show spinner',
  RESET_NEW_ORDER: 'reset new order',

  // data master CRUD
  CREATE_CUSTOMER: 'create customer',
  SET_CUSTOMER_ID_TYPE: 'set customer id type',
  SET_FOUND_STATES: 'set found states',
  SET_FOUND_CITIES: 'set found cities',
  SET_CITIES_DISABLED: 'set cities disabled',
  GET_STATES: 'get states',
  UPDATE_STATES: 'update states',
  GET_SELECTED_STATE_CITIES: 'get selected state cities',
  UPDATE_SELECTED_STATE_CITIES: 'update selected state cities',
  SEARCH_STATES: 'search states',
  SEARCH_CITIES: 'search cities',
  GET_CHANNELS: 'get channels',
  UPDATE_CHANNELS: 'update channels',
  GET_INVOICING_SETTINGS: 'get invoicing settings',
  UPDATE_INVOICING_SETTINGS: 'update invoicing settings',
  UPDATE_EDITED_CUSTOMER: 'update edited customer',
  GET_TAXES: 'get taxes',
  UPDATE_TAXES: 'update taxes',
  GET_FULFILLMENT_TYPES: 'get fulfillment types',
  UPDATE_FULFILLMENT_TYPES: 'update fulfillment types',

  // customer actions
  LOAD_EDIT_CUSTOMER: 'load edit customer',
  EDIT_CUSTOMER: 'edit customer',
  SEARCH_CUSTOMERS: 'search customers',
  UPDATE_GRID_CUSTOMERS: 'update grid customers',
  UPDATE_CUSTOMER_FILTERS: 'update customer filters',
  GET_CUSTOMERS_MASTER: 'get customers master',
  UPDATE_CUSTOMERS_PAGING: 'update customers paging',
  UPDATE_CUSTOMER_FORM_VALUES: 'update customer form values',
  UPDATE_CUSTOMER_FORM_ERROR: 'update customer form error',
  UPDATE_CUSTOMER_FORM_MODEL: 'update customer form model',

  // grids handling
  LAST_UPDATED_ORDERS_GRID: 'last updated orders grid',
  UPDATE_EXPANDED_ROW_KEYS: 'update expanded row keys',

  // dashboard
  GET_DASHBOARD_DATA: 'get dashboard data',
  GET_DAILY_LIVE_STATUS: 'det daily live status',
  SET_DASHBOARD_DATA: 'set dashboard data',
  SET_LIVE_STATUS_DATA: 'set live status data',
  SET_DASHBOARD_CONTROLLERS: 'set dashboard controllers',
  UPDATE_TOPTEN_DIMENSION: 'update top ten dimension',

  // production list set
  GET_PRODUCTION_LIST: 'get production list',
  SET_PRODUCTION_LIST_DATA: 'set production list data',
  SET_PRODUCTION_GRID_FILTERS: 'set production grid filters',

  // set of redirects
  REDIRECT_CREATE_CUSTOMER: 'redirect create customer',
  REDIRECT_CREATE_PRODUCT: 'redirect create product',

  // multi-order invoicing
  GET_PENDING_INVOICING_ORDERS: 'get pending invoicing orders',
  SET_PENDING_INVOICING_ORDERS: 'set pending invoicing orders',
  SET_SELECTED_CUSTOMER_MULTIORDERINVOICING:
    'set selected customer multi order invoicing',
  SET_MULTI_ORDER_INVOICING_CHECKED_KEYS:
    'set multi order invoicing checked keys',
  UPDATE_ORDER_INVOICE_DATA: 'update order invoice data',
  SET_ORDER_INVOICE_DATA: 'set order invoice data',
  INVOICE_MULTI_ORDER: 'invoice multi order',
  SET_MULTI_ORDER_MODAL_VISIBLE: 'set multi order modal visible',
  SET_MULTI_ORDERS_INVOICE_NOTES: 'set multi orders invoice notes',

  // version updates
  SET_NEW_VERSION_AVAILABLE: 'set new verison available',
  SET_WAITING_WORKER: 'set waiting worker',
  // file uploader
  SET_FILE_UPLOADER_DATA: 'set file uploader data',

  // delivery slots
  GET_DELIVERY_SLOTS: 'get delivery slots',
  CREATE_DELIVERY_SLOTS: 'create delivery slots',
  UPDATE_DELIVERY_SLOTS_PAGING: 'update delivery slots paging',
  SEARCH_DELIVERY_SLOTS: 'search delivery slots',
  UPDATE_DELIVERY_SLOTS_GRID: 'update delivery slots grid',
  UPDATE_PROCESS_ENABLED: 'update proccess enabled',
  GET_DELIVERY_SLOT: 'get delivery slot',
  UPDATE_DELIVERY_SLOT: 'update delivery slot',
  SET_EDITED_SLOT: 'set edited slot',
  SET_EDITED_SLOT_VISIBLE: 'set edited slot visible',

  // products
  SEARCH_PRODUCTS: 'search products',
  SEARCH_ACTIVE_ORDERS: 'search active orders',
  UPDATE_PRODUCTS_PAGING: 'update products paging',
  UPDATE_PRODUCTS_GRID: 'update products grid',
  UPDATE_PRODUCT_FILTERS: 'update product filters',
  UPDATE_PRODUCTS_FORM_VALUE: 'update products form value',
  UPDATE_PRODUCTS_FORM_ERROR: 'update products form error',
  CREATE_PRODUCT: 'create product',

  // tags
  SET_UPDATE_TAGS_VISIBLE: 'set update tags visible',
  UPDATE_SELECTED_TAGS: 'update selected tags',
  UPDATE_ORDER_TAGS: 'update order tags',
  GET_ORDER_INVOICE_URL: 'get order invoice url',
  GET_PURCHASE_ORDER_URL: 'get purchase order url',
  UPDATE_STATE_ORDER_INVOICE_URL: 'update state order invoice url',
  UPDATE_STATE_PURCHASE_ORDER_URL: 'update state purchase order url',

  // order events
  TRIGGER_ORDER_EVENT: 'trigger order event',
  TRIGGER_INVOICING: 'trigger invoicing order',

  // export pdf
  SHOW_EXPORTED_PDF: 'show exported pdf',

  // portal users
  UPDATE_CUSTOMER_EMAIL: 'update customer email',

  // token
  SET_TOKEN_DATA: 'set token data',
  CLEAR_TOKEN_DATA: 'clear token data',

  // modal
  OPEN_AUTH_MODAL: 'open auth modal',
  CLOSE_AUTH_MODAL: 'close auth modal',
};
