import LoginContainer from '@containers/LoginContainer';
import LogoutContainer from '@containers/LogoutContainer';
import CreateOrder from '@containers/Orders/CreateOrder';
import ListOrders from '@containers/Orders/ListOrders';
import ListOrdersHistory from '@containers/Orders/ListOrdersHistory';
import ExitIcon from '@rsuite/icons/Exit';
import PageIcon from '@rsuite/icons/Page';
import RootRoute from './RootRoute';
import { createRouteTree } from './types';

export default createRouteTree(({ $t }) => ({
  '/': {
    Component: RootRoute,
    exact: true,
  },
  // '/home': {
  //   key: '1',
  //   Icon: DashboardIcon,
  //   label: $t('route.home'),
  //   Component: HomeContainer,
  //   placement: 'sidenav',
  //   private: true,
  // },
  orders: {
    key: '2',
    label: $t('route.orders'),
    Icon: PageIcon,
    private: true,
    routes: {
      '/create-order': {
        key: '2-1',
        label: $t('route.create-order'),
        Component: CreateOrder,
        placement: 'sidenav',
        private: true,
      },
      '/list-order': {
        key: '2-2',
        label: $t('route.list-order'),
        Component: ListOrders,
        placement: 'sidenav',
        private: true,
      },
      '/list-order-history': {
        key: '2-3',
        label: $t('route.list-order-history'),
        placement: 'sidenav',
        Component: ListOrdersHistory,
        private: true,
      },
      // "/multi-invoicing": {
      //   key: "2-4",
      //   label: $t("route.multi-invoicing"),
      //   component: MultiOrderInvoicingContainer,
      //   placement: "sidenav",
      //   private: true,
      // },
    },
  },
  // planning: {
  //   key: '3',
  //   label: $t('route.planning'),
  //   Icon: TaskIcon,
  //   private: true,
  //   routes: {
  //     // "/production-list": {
  //     //   key: "3-1",
  //     //   label: $t("route.production-list"),
  //     //   component: ProductionListContainer,
  //     //   placement: "sidenav",
  //     //   private: true,
  //     // },
  //     // "/order-schedule": {
  //     //   key: "3-2",
  //     //   label: $t("route.order-schedule"),
  //     //   component: OrderSchedulingContainer,
  //     //   placement: "sidenav",
  //     //   private: true,
  //     // },
  //     '/list-delivery-slots': {
  //       key: '3-3',
  //       label: $t('route.list-delivery-slots'),
  //       Component: ListDeliverySlotsContainer,
  //       placement: 'sidenav',
  //       private: true,
  //     },
  //   },
  // },
  // masters: {
  //   key: '4',
  //   label: $t('route.masters'),
  //   Icon: ProjectIcon,
  //   private: true,
  //   routes: {
  //     '/list-customers': {
  //       key: '4-1',
  //       label: $t('route.list-customers'),
  //       Component: ListCustomersContainer,
  //       placement: 'sidenav',
  //       private: true,
  //     },
  //     '/list-products': {
  //       key: '4-2',
  //       label: $t('route.list-products'),
  //       Component: ListProductsContainer,
  //       placement: 'sidenav',
  //       private: true,
  //     },
  //     // "/product-inventory": {
  //     //   key: "4-3",
  //     //   label: $t("route.product-inventory"),
  //     //   component: Inventory,
  //     //   placement: "sidenav",
  //     //   private: true,
  //     // },
  //   },
  // },
  // '/edit-order': {
  //   Component: CreateOrderContainer,
  //   private: true,
  // },
  // '/addEdit-product': {
  //   Component: AddEditProductContainer,
  //   private: true,
  // },
  // '/create-delivery-slots': {
  //   Component: CreateDeliverySlotsContainer,
  //   private: true,
  // },
  // '/file-center': {
  //   Component: FileCenterContainer,
  //   private: true,
  // },
  // '/addEdit-customer': {
  //   Component: AddEditCustomerContainer,
  //   private: true,
  // },
  '/login': {
    Component: LoginContainer,
  },
  '/logout': {
    key: '5',
    label: $t('route.logout'),
    Icon: ExitIcon,
    Component: LogoutContainer,
    placement: 'sidenav',
    private: true,
  },
}));
