import axios from "axios";
import { API_BACKEND_URL } from "../utils/Constants";

const api = {
  async getDashboardData(filter) {
    return await axios
      .get(API_BACKEND_URL + "order/findfulldatadashboard/", { params: filter })
      .then((response) => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
        console.log("problem on getting products!!");
        console.log(error);
      });
  },
};

export default api;
