import dayjs from '@utils/dayjs';
import {
  formatNumber,
  formatPercentage,
  getDayFromFullDate,
} from '../../utils/DataFormat';
import * as Constants from '../Constants';

export const getOnTimeData = (
  monthOrders,
  deliveryStatusId,
  cancelStatusId,
) => {
  let groupedByDate = {};
  let onTimeInfo = {};
  let dailyOnTime = [];
  let dailyOrdersSeries = [];
  let dailyOrdersData = [];
  let fullDailyOrders = [];
  let todaysOnTime = {};
  let dayOnTimeOrders,
    dayOffTimeOrders,
    monthOnTimeOrders = 0,
    monthOffTimeOrders = 0;
  let nonBilledUnits = 0,
    nonBilledAmount = 0,
    billedUnits = 0,
    billedAmount = 0,
    billedOrders = 0,
    nonBilledOrders = 0;
  let canceledOrders = 0,
    canceledAmount = 0,
    canceledUnits = 0;

  // group data into dates
  monthOrders.forEach(order => {
    const date = order.createdAt.split('T')[0];
    if (groupedByDate[date]) {
      groupedByDate[date].push(order);
    } else {
      groupedByDate[date] = [order];
    }
  });

  // fetch each date in order to obtain on/off time orders
  for (let value of Object.values(groupedByDate)) {
    todaysOnTime = {};
    dayOnTimeOrders = 0;
    dayOffTimeOrders = 0;

    value.map(order => {
      // set data for ontime calculation
      if (order.statusId == deliveryStatusId) {
        if (order.onTime == Constants.ON_TIME) {
          dayOnTimeOrders = dayOnTimeOrders + 1;
          monthOnTimeOrders = monthOnTimeOrders + 1;
        } else if (order.onTime == Constants.DELAYED) {
          dayOffTimeOrders = dayOffTimeOrders + 1;
          monthOffTimeOrders = monthOffTimeOrders + 1;
        }
      }

      // set data for billing
      if (order.statusId == cancelStatusId) {
        canceledOrders = canceledOrders + 1;
        canceledUnits = canceledUnits + order.totalUnits;
        canceledAmount = canceledAmount + order.totalBeforeTax;
      } else if (!order.invoiceId && order.totalBeforeTax > 0) {
        nonBilledUnits = nonBilledUnits + order.totalUnits;
        nonBilledAmount = nonBilledAmount + order.totalBeforeTax;
        nonBilledOrders = nonBilledOrders + 1;
      } else if (order.invoiceId || order.totalBeforeTax == 0) {
        billedUnits = billedUnits + order.totalUnits;
        billedAmount = billedAmount + order.totalBeforeTax;
        billedOrders = billedOrders + 1;
      }
    });

    // daily orders building
    fullDailyOrders.push({
      date: getDayFromFullDate(value[0].createdAt),
      orders: value.length,
    });
  }

  fullDailyOrders = fullDailyOrders.sort(compareDates);
  dailyOrdersSeries = fullDailyOrders.map(value => value.date);
  dailyOrdersData = fullDailyOrders.map(value => value.orders);

  if (monthOnTimeOrders + monthOffTimeOrders > 0) {
    onTimeInfo.currentMonthOnTime = formatPercentage(
      (
        (monthOnTimeOrders / (monthOnTimeOrders + monthOffTimeOrders)) *
        100
      ).toFixed(0),
    );
  } else {
    onTimeInfo.currentMonthOnTime = 0;
  }

  onTimeInfo.currentMonthOnTimeOrders = monthOnTimeOrders;
  onTimeInfo.currentMonthOffTimeOrders = monthOffTimeOrders;

  // total orders col
  onTimeInfo.totalOrders = [];
  onTimeInfo.totalOrders.push(
    formatNumber(billedOrders + nonBilledOrders + canceledOrders),
  );
  onTimeInfo.totalOrders.push(formatNumber(billedOrders));
  onTimeInfo.totalOrders.push(formatNumber(nonBilledOrders));
  onTimeInfo.totalOrders.push(canceledOrders);

  // total units col
  onTimeInfo.totalUnits = [];
  onTimeInfo.totalUnits.push(billedUnits + nonBilledUnits + canceledUnits);
  onTimeInfo.totalUnits.push(billedUnits);
  onTimeInfo.totalUnits.push(nonBilledUnits);
  onTimeInfo.totalUnits.push(canceledUnits);

  // total amount col
  onTimeInfo.totalAmount = [];
  onTimeInfo.totalAmount.push(billedAmount + nonBilledAmount + canceledAmount);
  onTimeInfo.totalAmount.push(billedAmount);
  onTimeInfo.totalAmount.push(nonBilledAmount);
  onTimeInfo.totalAmount.push(canceledAmount);

  onTimeInfo.dailyOrdersSeries = dailyOrdersSeries;
  onTimeInfo.dailyOrdersData = dailyOrdersData;

  return onTimeInfo;
};

export const getLocalTimeData = (data, field) => {
  return data.map(item => {
    item[field] = dayjs(item[field]).format();
    return item;
  });
};

export const getDataByCustomer = (
  ordersList,
  topTenDimension,
  deliveryStatusId,
  cancelStatusId,
) => {
  let groupedByCustomer = {};
  let customerInfo = [];
  let sortedCustomerInfo = [];
  let topSortedCustomers = [];
  let total;
  let customerPoint = {};
  let fullCustomerName = undefined;

  // group data by customers
  ordersList.forEach(order => {
    const customerName = order.customerName;
    if (groupedByCustomer[customerName]) {
      groupedByCustomer[customerName].push(order);
    } else {
      groupedByCustomer[customerName] = [order];
    }
  });

  // fetch each date in order to obtain total units
  for (let value of Object.values(groupedByCustomer)) {
    total = 0;
    value.map(customer => {
      if (customer.statusId != cancelStatusId) {
        total =
          topTenDimension === 'cop'
            ? total + customer.totalBeforeTax
            : total + customer.totalUnits;
      }
    });

    fullCustomerName = value[0].customerName;

    customerPoint = {
      customerName: fullCustomerName.slice(0, 30),
      totalUnits: total,
    };
    customerInfo.push(customerPoint);
  }

  sortedCustomerInfo = customerInfo.sort(compare);
  topSortedCustomers = getTopCustomers(sortedCustomerInfo);
  return topSortedCustomers;
};

export const getTodaysTotalOrders = orders => {
  let firstRow = {};
  let ordersSet = [];
  let unitsSet = [];
  let amountsSet = [];
  let totalAmount, totalUnits;
  let pendingAmount, pendingUnits;
  let deliveredAmount, deliveredUnits;
  let canceledAmount, canceledUnits;

  const pendingOrders = orders.filter(
    order =>
      order.order.currentStatusId > 0 &&
      order.order.currentStatusId < Constants.ENTREGADA,
  );
  const deliveredOrders = orders.filter(
    order => order.order.currentStatusId == Constants.ENTREGADA,
  );
  const canceledOrders = orders.filter(
    order => order.order.currentStatusId == Constants.CANCELADA,
  );

  // summary by orders
  ordersSet.push(orders.length);
  ordersSet.push(deliveredOrders.length);
  ordersSet.push(pendingOrders.length);
  ordersSet.push(canceledOrders.length);

  // values for total orders
  totalAmount = orders.length > 0 ? orders.reduce(reducerAmount, 0) : 0;
  totalUnits = orders.length > 0 ? orders.reduce(reducerUnits, 0) : 0;

  // values for deliveryed orders
  deliveredAmount =
    deliveredOrders.length > 0 ? deliveredOrders.reduce(reducerAmount, 0) : 0;
  deliveredUnits =
    deliveredOrders.length > 0 ? deliveredOrders.reduce(reducerUnits, 0) : 0;

  // values for pending orders
  pendingAmount =
    pendingOrders.length > 0 ? pendingOrders.reduce(reducerAmount, 0) : 0;
  pendingUnits =
    pendingOrders.length > 0 ? pendingOrders.reduce(reducerUnits, 0) : 0;

  // values for canceled orders
  canceledAmount =
    canceledOrders.length > 0 ? canceledOrders.reduce(reducerAmount, 0) : 0;
  canceledUnits =
    canceledOrders.length > 0 ? canceledOrders.reduce(reducerUnits, 0) : 0;

  // summary by amount$
  amountsSet.push(totalAmount);
  amountsSet.push(deliveredAmount);
  amountsSet.push(pendingAmount);
  amountsSet.push(canceledAmount);

  // summary by units
  unitsSet.push(totalUnits);
  unitsSet.push(deliveredUnits);
  unitsSet.push(pendingUnits);
  unitsSet.push(canceledUnits);

  firstRow.ordersSet = ordersSet;
  firstRow.amountsSet = amountsSet;
  firstRow.unitsSet = unitsSet;
  return firstRow;
};

const reducerUnits = (accumulator, currentValue) =>
  accumulator + currentValue.order.totalUnits;
const reducerAmount = (accumulator, currentValue) =>
  accumulator + currentValue.order.totalBeforeTax;

const getTopCustomers = customers => {
  let topCustomers = [];
  let othersValue = {};
  let totalUnitsOthers = 0;
  let othersCount = 0;
  const top = Constants.TOP_CUSTOMERS_SHOWN;
  let index = 0;

  for (index = 0; index < customers.length; index++) {
    if (index < top) {
      topCustomers.push(customers[index]);
    } else {
      totalUnitsOthers = totalUnitsOthers + customers[index].totalUnits;
      othersCount = othersCount + 1;
    }
  }
  othersValue.customerName = 'Otros (' + othersCount + ')';
  othersValue.totalUnits = totalUnitsOthers;
  topCustomers.push(othersValue);
  return topCustomers;
};

function compare(a, b) {
  const diffA = a.totalUnits;
  const diffB = b.totalUnits;

  let comparison = 0;
  if (diffA > diffB) comparison = -1;
  else if (diffA < diffB) comparison = 1;

  return comparison;
}

function compareDates(a, b) {
  const diffA = a.date;
  const diffB = b.date;

  let comparison = 0;
  if (diffA < diffB) comparison = -1;
  else if (diffA > diffB) comparison = 1;

  return comparison;
}

// npm install lodash --save
// // OR https://medium.com/better-programming/how-to-setup-chart-js-for-react-and-how-to-dynamically-allocate-colors-ae5faf7a45d3
// yarn add lodash
