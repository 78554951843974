import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_BACKEND_URL } from '@utils/Constants';

export type Channel = {
  id: string;
  description: string;
  priority: number;
  companyId: string;
  createdAt: string;
  updatedAt: string;
  origin: string[];
};

type FindChannelRequest = {
  companyId: string;
};
type FindChannelResponse = {
  result: Channel[];
};

type Store = {
  ordox?: {
    auth: {
      token: string;
    };
  };
};

export const channelApi = createApi({
  reducerPath: 'channel-api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BACKEND_URL}channel`,
    prepareHeaders: (headers, { getState }) => {
      const { ordox }: Store = getState();
      const token = ordox.auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    findChannel: builder.query<Channel[], FindChannelRequest>({
      query: ({ companyId }) => ({
        url: `find/`,
        method: 'POST',
        body: { companyId },
      }),
      transformResponse: (response: FindChannelResponse) => response.result,
    }),
  }),
});

export const { useFindChannelQuery } = channelApi;

export default channelApi;
