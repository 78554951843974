import {
  Product,
  useFindProductQuery,
  useLazyFindListPriceProductQuery,
} from '@api/query/productApi';
// import { useFindTaxesQuery } from '@api/query/taxApi';
import DrawerModal from '@components/ui/DrawerModal';
import FormattedCurrency from '@components/ui/FormattedCurrency';
import { Close as CloseIcon, Edit as EditIcon } from '@rsuite/icons';
import SearchIcon from '@rsuite/icons/Search';
import { selectCompany } from '@selectors/SystemSelector';
import combineWith, { stop } from '@utils/combine';
import filterOne from '@utils/filterOne';
import useFuzzySearch, {
  getMatchIndices,
  RangeTuples,
} from '@utils/useFuzzySearch';
import useViewport from '@utils/useViewport';
import { styled } from 'goober';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import InfoIcon from '@rsuite/icons/InfoRound';
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso';
import {
  Button,
  Form,
  IconButton,
  Input,
  InputGroup,
  Loader,
  Panel,
} from 'rsuite';
import HighlightMatchText from '../ui/HighlightMatchText';
import InputNumber from '../ui/InputNumber';

Object.defineProperty(GroupedVirtuoso, 'displayName', { value: 'Virtuoso' });

export type ProductBrowserProps = {
  className?: string;
  listPriceId?: string;
  selected: Product[];
  setListPriceProducts;
  setProductIsShippingItem;
  onSelectedChange(
    items: Product[],
    change: Product | undefined,
    checked: boolean,
  ): void;
};

const keys = ['name'];

const ProductBrowser: React.FC<ProductBrowserProps> = ({
  className,
  onSelectedChange,
  selected,
  setListPriceProducts,
  setProductIsShippingItem,
  listPriceId,
}) => {
  const viewport = useViewport();

  const [triggerListPrice, listPriceQuery] = useLazyFindListPriceProductQuery();

  useEffect(() => {
    if (listPriceQuery.data?.items.length) {
      setListPriceProducts(listPriceQuery.data?.items)
    }
  }, [listPriceQuery]);

  useEffect(() => {
    if (listPriceId) triggerListPrice({ listPriceId });
  }, [listPriceId, triggerListPrice]);

  const productQuery = useFindProductQuery({ active: true });

  const products = useMemo<Product[]>(() => {
    const products = productQuery.data?.items?.slice() || [];
    const listPrices = listPriceQuery.data?.items?.slice() || [];
    const newProducts = []
    combineWith(products, listPrices, (l, r) => {
      if (l.companyId === r.companyId && l.id === r.product?.id) {
        l.priceBeforeTax = r.price;
        l.priceAfterTax = r.price + (r.price * l.tax) / 100;
        l.minQuantity = r?.minQuantity
        l.isShippingItem = r?.isShippingItem
        newProducts.push(l)
        stop();
      }
    });

    for (let i = 0; i < newProducts?.length; i++) {
      if (newProducts[i]?.isShippingItem) {
        setProductIsShippingItem(newProducts[i])
      }
    }
    return newProducts
  }, [productQuery.data, listPriceQuery.data, listPriceQuery]);

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const {
    search,
    pattern,
    patternIsEmpty,
    searchResult,
    isSearching,
  } = useFuzzySearch({
    data: products,
    keys,
    threshold: 0.4,
  });

  const context = useMemo(
    () => ({
      patternIsEmpty,
      searchResult,
      products,
      selected,
      onSelectedChange,
    }),
    [patternIsEmpty, searchResult, products, onSelectedChange, selected],
  );

  const selectedChangeHandler = useCallback(
    (changes: Partial<Product>) => {
      const index = selected.findIndex(({ id }) => id === changes.id);

      if (index !== -1) {
        selected.splice(index, 1, { ...selected[index], ...changes });
        onSelectedChange([...selected], selected[index], true);
      }
    },
    [selected, onSelectedChange],
  );

  const removeHandler = useCallback(
    (product: Product) => {
      onSelectedChange(
        selected.filter(({ id }) => id !== product.id),
        product,
        false,
      );
    },
    [selected, onSelectedChange],
  );

  return (
    <Panel
      className={className}
      header={
        <div className="flex justify-between">
          <span className="font-medium">
            <FormattedMessage id="products" />
          </span>
          <IconButton
            size="sm"
            appearance="link"
            icon={<SearchIcon />}
            onClick={() => setOpen(true)}
          >
            {selected.length === 0 ? 'Buscar productos' : undefined}
          </IconButton>
        </div>
      }
    >
      <ProductList
        products={selected as Product[]}
        onChange={selectedChangeHandler}
        onRemove={removeHandler}
      />
      <DrawerModal
        open={open}
        DrawerProps={{ size: 'full' }}
        onClose={onClose}
        placement={viewport.isNarrow ? 'bottom' : 'floating'}
        title={<FormattedMessage id="products" />}
        body={
          <div className="flex flex-col flex-grow">
            {productQuery.isLoading ? (
              <Loader size="md" className="m-auto" />
            ) : (
              <>
                <div className="mx-3 mt-1">
                  <InputGroup inside size="sm">
                    <InputGroup.Addon>
                      <SearchIcon />
                    </InputGroup.Addon>
                    <Input
                      type="search"
                      autoFocus
                      autoComplete="off"
                      inputMode="search"
                      autoCorrect="off"
                      onChange={value => search(value)}
                      value={pattern as string}
                    />
                    {isSearching && (
                      <InputGroup.Addon>
                        <Loader />
                      </InputGroup.Addon>
                    )}
                  </InputGroup>
                </div>
                <div className="flex-grow pt-2 relative overflow-hidden md:h-96">
                  <div className="header flex flex-nowrap font-medium py-2 shadow-sm">
                    <span className="ml-4 flex-1">Product</span>
                    <span className="mr-5">Cantidad</span>
                  </div>
                  <Virtuoso
                    className="h-full"
                    totalCount={
                      patternIsEmpty ? products?.length : searchResult?.length
                    }
                    context={context}
                    itemContent={(
                      index,
                      _,
                      {
                        products,
                        patternIsEmpty,
                        searchResult,
                        selected,
                        onSelectedChange,
                      },
                    ) => {
                      const matches = !patternIsEmpty
                        ? getMatchIndices('name', searchResult[index]?.matches)
                        : [];

                      const product = patternIsEmpty
                        ? products[index]
                        : searchResult[index]?.item;

                      if (!product) return <div className="h-px"></div>;

                      const selectedItem =
                        selected.find(({ id }) => id === product.id) ||
                        product;

                      return (
                        <ProductItemCompact
                          product={selectedItem}
                          nameMatches={matches}
                          checked={selected.some(
                            item => item.id === product.id,
                          )}
                          onChange={selectedChangeHandler}
                          onChecked={(product, checked) => {
                            onSelectedChange(
                              checked
                                ? selected.concat(product)
                                : filterOne(
                                  selected,
                                  item => item.id === product.id,
                                ),
                              product,
                              checked,
                            )
                          }
                          }
                        />
                      );
                    }}
                    increaseViewportBy={100}
                  />
                </div>
              </>
            )}
          </div>
        }
        actions={
          <div className="text-slate-800 my-2 mx-4 flex">
            <FormattedMessage
              id="selected"
              values={{
                count: <b className="mr-1">{selected.length}</b>,
              }}
            />
          </div>
        }
        backdrop
      />
    </Panel>
  );
};

export type ProductItemProps = {
  product: Product;
  checked?: boolean;
  onChecked?(product: Product, checked: boolean): void;
  onChange?(changes: Product): void;
  nameMatches?: RangeTuples;
  onRemove?(product: Product): void;
  readonly?: boolean;
};

export const ProductItemCompact: React.FC<ProductItemProps> = ({
  product,
  checked,
  nameMatches,
  onChecked,
  onChange,
  onRemove,
  readonly,
}) => {
  return (
    <Form
      className={`flex items-center flex-wrap flex-row px-4 py-2 -mb-px border-y border-gray-200 cursor-pointer
      ${
        checked
          ? 'bg-blue-400 text-white'
          : 'text-slate-700 hover:bg-slate-200 hover:text-slate-700'
      }
    `}
      onClick={() => onChecked(product, !checked)}
      fluid
      formDefaultValue={{}}
    >
      <span className="flex-1 basis-full">
        <HighlightMatchText text={product.name} matches={nameMatches} />
      </span>

      <div className="flex-shrink basis-36 text-left">
        <FormattedCurrency
          className="text-base font-bold whitespace-nowrap"
          value={product.priceBeforeTax}
        />
      </div>

      <div
        className={`my-1 ml-auto basis-20 flex-shrink ${
          !checked && 'invisible'
        }`}
        onClick={ev => ev.stopPropagation()}
      >
        <InputNumber
          key={Number(checked)}
          disabled={!checked}
          name="quantity"
          unit={product.unit}
          value={product.quantity}
          min={product.unit === 'uds' ? 1 : 0.001}
          scale={product.unit === 'uds' ? 0 : 2}
          onChange={quantity => onChange({ ...product, quantity })}
        />
      </div>
    </Form>
  );
};

const ProductListStyled = styled('div')`
  & .rs-input {
    width: 100%;
  }
  & form > * {
    margin-right: 8px;
  }
`;

type ProductListProps = {
  products: Product[];
  onChange?(changes: Partial<Product>): void;
  onRemove?(product: Product): void;
};

export const ProductList: React.FC<ProductListProps> = ({
  products,
  onChange,
  onRemove,
}) => {
  return (
    <ProductListStyled className="">
      {products.map(product => (
        <ProductItem
          key={product.id}
          product={product}
          onChange={onChange}
          onRemove={onRemove}
        />
      ))}
    </ProductListStyled>
  );
};

export const ProductItem: React.FC<ProductItemProps> = ({
  product,
  onChange,
  onRemove,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="basis-full border-t border-gray-200 -mx-5" />
      <div
        className={`flex flex-wrap justify-end items-end py-3 -mb-px cursor-pointer
      text-slate-700 hover:bg-slate-50 hover:text-slate-700`}
      >
        <div className="flex-1 basis-full flex flex-nowrap items-start">
          <span className="flex-1 font-medium">{product.name}</span>
          {product?.quantity < product?.minQuantity && (
            <IconButton
              className="mx-1 -mt-1"
              appearance="subtle"
              title={`El ítem no tiene la cantidad mínima (${product?.minQuantity})`}
              size="sm"
              icon={<InfoIcon color='red' />}
            />
          )}
          <IconButton
            className="mx-1 -mt-1"
            size="sm"
            icon={<EditIcon />}
            onClick={() => setOpen(true)}
          />
          <IconButton
            className="ml-2"
            size="xs"
            circle
            appearance="subtle"
            icon={<CloseIcon />}
            onClick={() => onRemove(product)}
          />
        </div>

        <FormattedCurrency
          className="flex-1 text-left text-gray-500"
          value={product.priceBeforeTax}
        />

        <span className="basis-10">
          <InputNumber
            value={product.quantity}
            unit={product.unit?.slice(0, 3)}
            scale={product.unit === 'uds' ? 0 : 2}
            min={product.unit === 'uds' ? 1 : 0.001}
            onChange={value =>
              onChange({ ...product, quantity: Number(value) })
            }
          />
        </span>

        <span className="flex-shrink basis-32 font-bold text-right mx-2">
          <FormattedCurrency
            value={product.priceBeforeTax * product.quantity}
          />
        </span>
        <ProductInlineEditor
          product={product}
          onChange={onChange}
          open={open}
          onClose={() => setOpen(false)}
        />
      </div>
    </>
  );
};

const ProductInlineEditor: React.FC<
  ProductItemProps & { open: boolean; onClose(): void }
> = ({ product, onChange, open, onClose }) => {
  const { $t } = useIntl();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const productDefault = useMemo(() => open && cloneDeep(product), [open]);

  // const taxesOptions = useFindTaxesQuery({});

  const reset = () => {
    onChange(productDefault);
  };

  const save = () => {
    onClose();
  };

  const discard = () => {
    reset();
    onClose();
  };

  return (
    <DrawerModal
      open={open}
      onClose={discard}
      placement="floating"
      header={
        <span className="flex-1 flex flex-col text-gray-700">
          <span className="font-medium">{product.name}</span>
        </span>
      }
      body={
        <form className={`p-4 grid grid-cols-2 gap-3 text-slate-700`}>
          {/* <span className="">
            <label htmlFor="price" className="font-medium">
              Precio
            </label>
            <InputNumber
              id="price"
              name="price"
              unit="$"
              min={0}
              max={100_000_000}
              scale={2}
              value={product.priceBeforeTax}
              spinButtons={false}
              onChange={price => {
                onChange({
                  ...product,
                  priceBeforeTax: price,
                  priceAfterTax: price + (price * product.tax) / 100,
                });
              }}
            />
          </span> */}

          <span className="">
            <label htmlFor="quantity" className="font-medium">
              Cantidad
            </label>
            <InputNumber
              id="quantity"
              name="quantity"
              value={product.quantity}
              unit={product.unit?.slice(0, 3)}
              scale={product.unit === 'uds' ? 0 : 2}
              min={product.unit === 'uds' ? 1 : 0.001}
              onChange={value =>
                onChange({ ...product, quantity: Number(value) })
              }
            />
          </span>
          {/* 
          <span className="">
            <label htmlFor="discount" className="font-medium">
              Descuento
            </label>
            <InputNumber
              id="discount"
              name="discount"
              value={product.discount}
              unit="%"
              scale={0}
              min={0}
              max={100}
              spinButtons={false}
              onChange={discount => onChange({ ...product, discount })}
            />
          </span> */}

          {/* <span className="">
            <label htmlFor="tax" className="font-medium">
              Impuesto
            </label>
            <SelectPicker
              style={{ width: '100%' }}
              size="sm"
              cleanable={false}
              searchable={false}
              value={product.tax}
              data={
                taxesOptions.data?.map(({ description, percentage }) => ({
                  label: `${description} - ${percentage}%`,
                  value: percentage,
                })) || []
              }
              onChange={tax =>
                onChange({
                  ...product,
                  tax: Number(tax),
                  taxIdERP: taxesOptions.data?.find(
                    ({ percentage }) => percentage === tax,
                  )?.idERP,
                  priceAfterTax:
                    product.priceBeforeTax +
                    (product.priceBeforeTax * tax) / 100,
                })
              }
            />
          </span> */}

          <span>
            <label htmlFor="notes" className="font-medium">
              {$t({ id: 'product-notes' })}
            </label>
            <Input
              as="textarea"
              rows={2}
              size="sm"
              className="basis-full mr-2"
              maxLength={55}
              value={product.notes || ''}
              onChange={notes => onChange({ ...product, notes })}
            />
          </span>
          <span></span>
          <span className="font-bold text-right">
            <label className="font-medium">Subtotal</label>

            <FormattedCurrency
              className="block mt-2"
              value={
                product.priceBeforeTax * product.quantity -
                product.priceBeforeTax * ((product.discount || 0) / 100)
              }
            />
          </span>
        </form>
      }
      actions={
        <div className="flex justify-end p-2">
          <Button onClick={reset}>Deshacer cambios</Button>
          <Button className="mr-2" appearance="primary" onClick={save}>
            Guardar
          </Button>
        </div>
      }
    />
  );
};

export default ProductBrowser;
