import { AppState } from '@src/store';

export const getActiveUser = state => state.ordox.activeUser;
export const getLoadingVisible = state => state.ordox.loadingVisible;
export const setButtonDisabled = state => state.ordox.buttonDisabled;
export const getFormActions = state => state.ordox.formActions;
export const redirectToHome = state => state.ordox.redirectTo.home;
export const redirectToListOrder = state => state.ordox.redirectTo.listOrder;
export const getShowSpinner = state => state.ordox.showSpinner;
export const getShowNotification = state => state.ordox.showNotification;
export const getLastUpdatedOrdersGrid = state =>
  state.ordox.lastUpdateOrdersGrid;
export const getUserDevice = state => state.ordox.isMobile;

export const getCustomerEmail = state => state.ordox.customerEmail;
export const getMainLoggingParams = (state: AppState) => {
  const channel = getB2BPortalChannel(state)
  return {
    company: state.ordox.overallParams.company,
    version: state.ordox.overallParams.version,
    user: state.ordox.activeUser.email,
    userId: state.ordox.activeUser.id,
    companyId: state.ordox.overallParams.companyId,
    erp: state.ordox.company.erp,
    taxAccountable: state.ordox.company.taxAccountable,
    customerId: state.ordox.overallParams.customerId,
    listPriceId: state.ordox.overallParams.customer?.listPriceId,
    deliveryHoursAhead: state.ordox.company.deliveryHoursAhead,
    automaticDeliveryDateTimeSet: state.ordox.company.automaticDeliveryDateTimeSet,
    choosePaymentMethod : state.ordox.company.choosePaymentMethod,
    showDeliveryDate: state.ordox.company.showDeliveryDate,
    customer: {
      ...state.ordox.overallParams.customer,
      channelId: channel?.value,
      channel: { id: channel?.value, description: channel?.label }
    },
  };
};

const getB2BPortalChannel = state => {
 console.log('state', state.ordox.masterData?.channels)
  const channel = state.ordox.masterData.channels?.find(
    channel => channel.origin === 'B2BPORTAL'
  );

  return channel;
};
export const selectCompany = (state: AppState) => state.ordox.company;
export const selectCompanyId = (state: AppState) => state.ordox.company.id;
export const getShowSignUp = state => state.ordox.signUpVisible;
export const getSignupForm = state => state.ordox.signupForm;
export const getAuthModalData = state => state.ordox.authModal;
export const getShowModalTokenExpired = state =>
  state.ordox.showModalTokenExpired;
