import queryString from 'query-string';
import axios from "axios";
import { API_BACKEND_URL } from "../utils/Constants";

const api = {
  getProducts(filters) {
    return axios.get(`products?active=${filters.active}`)
  },

  getProductById({ id }) {
    return axios.get(`products/${id}`)
  },

  async countProducts(filter) {
    return await axios
      .post(API_BACKEND_URL + "product/count", filter)
      .then((response) => {
        return {
          status: response.status,
          data: response.data.result,
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  searchProducts(filters) {
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return axios.get(`products${params ? `?${params}` : ''}`)
  },

  createProduct(product) {
    return axios.post("products", product)
  },
};

export default api;
