import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../actions';
import { Login } from '../components/Security/Login';
import {
  getActiveUser,
  getCustomerEmail,
  getMainLoggingParams,
} from '../selectors/SystemSelector';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.getUserAuthorization = this.getUserAuthorization.bind(this);
    // this.showSignup = this.showSignup.bind(this);
  }

  getUserAuthorization(response, type) {
    this.props.getUserAuthorization(response, type);
  }

  // showSignup() {
  //   this.props.showSignUp(true);
  // }

  render() {
    this.props.setShowSpinner(false);

    return (
      <div>
        {this.props.activeUser.authorized && (
          <Redirect to="/list-order" push={false} />
        )}
        <Login
          success={this.getUserAuthorization}
          signUpClicked={''}
        />
        {/* {this.props.showSignUp && (
          <div>
            <Signup
              visible={this.props.signUpVisible}
              formValues={this.props.signupForm}
              onClose={this.closeSignup}
              onChangedForm={this.handlOnChangeSignupForm}
              onSubmit={this.handleOnSubmit}
            ></Signup>
          </div>
        )} */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeUser: getActiveUser(state),
    companyId: getMainLoggingParams(state).companyId,
    customerEmail: getCustomerEmail(state),
  };
};
export default connect(mapStateToProps, actions)(LoginContainer);
