import axios from 'axios';
import * as constants from '../utils/Constants';

const api = {
  async isInvoiceable(currentStatus, newStatus) {
    const apiURL =
      constants.API_BACKEND_URL +
      'Invoice/IsInvoiceableEvent?currentStatus=' +
      currentStatus +
      '&newStatus=' +
      newStatus;

    return await axios
      .get(apiURL)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        console.log('error here!');
        console.log(error);
        return {
          status: error.response.status,
          data: error.response.data,
        };
      });
  },
  async getRecentOrders(customerIdERP) {
    const apiURL =
      constants.API_BACKEND_URL +
      'Invoice/GetRecentInvoices?customerIdERP=' +
      customerIdERP;

    return await axios
      .get(apiURL)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        console.log('error here!');
        console.log(error);
        return {
          status: error.response.status,
          data: error.response.data,
        };
      });
  },
  async updateOrderInvoiceData(order) {
    return await axios
      .post(constants.API_BACKEND_URL + 'Invoice/UpdateInvoiceData', order)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },
};

export default api;
