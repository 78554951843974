import axios from 'axios';
import * as constants from '../utils/Constants';

const api = {
    async getProductionList(companyId,daysRange)
    {
        return await axios.get(constants.API_BACKEND_URL + 'Order/GetProductionList?companyId=' + companyId + '&daysRange=' + daysRange)
        .then(response => {
            return{
                status: response.status,
                data: response.data
            }
        })
        .catch(error => {
            return{
                status: error.response.status,
                data: error.response.data
            }
        })
    },
}

export default api;