import React from 'react';
import { MessageDescriptor } from 'react-intl';
import { matchPath, RouteProps } from 'react-router-dom';
import { ReadonlyDeep } from 'ts-toolbelt/out/Object/Readonly';

export type BaseEntry = {
  key?: `${number}` | `${number}-${number}`;
  label?: MessageDescriptor;
  Icon?: React.FC;
  placement?: 'sidenav';
};

export type BaseRouteProps = Omit<
  RouteProps,
  'children' | 'render' | 'component' | 'sensitive'
> & {
  Component: React.ComponentType;
  private?: boolean;
};

export type MenuEntry = BaseEntry & {
  routes: {
    [path: string]: RouteEntry;
  };
};

export type RouteEntry = BaseRouteProps & BaseEntry;

export type RouteTree = {
  [path: string]: MenuEntry | RouteEntry;
};

const $t = (id: `route.${string}`): MessageDescriptor => ({ id });

export function traverseRouteTree(
  tree: RouteTree,
  cb: (key: string, entry: MenuEntry | RouteEntry) => void | true,
) {
  Object.entries(tree).forEach(([path, entry]) => {
    const stop = cb(path, entry);

    if (stop === true) return;
    if (isMenuEntry(entry)) traverseRouteTree(entry.routes, cb);
  });
}

type RouteTreeFactory = (props: { $t: typeof $t }) => RouteTree;

export function createRouteTree(factory: RouteTreeFactory) {
  const tree = factory({ $t });
  const flatTree: (MenuEntry | RouteEntry)[] = [];
  const pathConfig: ReadonlyDeep<{
    path: string | string[];
    exact?: boolean;
    strict?: boolean;
  }>[] = [];

  traverseRouteTree(tree, (path, entry) => {
    if (isRouteEntry(entry)) {
      entry.path = entry.path || path;
      pathConfig.push({
        path: entry.path,
        exact: entry.exact,
        strict: entry.strict,
      });
    }

    flatTree.push(entry);
  });

  return { tree, flatTree, pathConfig };
}

export function isMenuEntry(entry: any): entry is MenuEntry {
  return 'routes' in entry;
}

export function isRouteEntry(entry: any): entry is RouteEntry {
  return !('routes' in entry);
}

export function findRouteByPath(
  pathname: string,
  routes: (MenuEntry | RouteEntry)[],
) {
  return routes.find(
    entry => isRouteEntry(entry) && !!matchPath(pathname, entry) && entry,
  ) as RouteEntry | undefined;
}
