// @ts-ignore
import {
  closeAuthModal,
  getOrderInvoiceURL,
  getOrderTracking,
  getTags,
  loadItemsEditOrder,
  openUpdateOrderStatus,
  redirect
} from '@actions/index';
import { Order, useFindQuery } from '@api/query/orderApi';
import {
  cellsById,
  DataGrid,
  DataGridRowContent,
  DataGridToolbar,
  GlobalFilterCallback,
  HighlightText
} from '@components/DataGrid';
import AuthModalComponent from '@components/Modals/Auth';
import TagFigure from '@components/Order/TagFigure';
import PageHeader from '@components/PageHeader';
import { More as MoreIcon } from '@rsuite/icons';
import InfoIcon from '@rsuite/icons/InfoRound';
import PlusIcon from '@rsuite/icons/Plus';
import {
  getCancelStatusId,
  getDeliveryStatusId,
  getStatusList
} from '@selectors/StatusSelector';
import dayjs, { dayjsCalendar, dayjsRanges, formats } from '@utils/dayjs';
import { ExportToCsv } from 'export-to-csv';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import {
  FormattedMessage,
  FormattedNumberParts,
  FormattedPlural
} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CellProps, Column } from 'react-table';
import {
  CheckPicker,
  DateRangePicker,
  Dropdown,
  IconButton,
  Input,
  Popover,
  Tag,
  Tooltip,
  Whisper
} from 'rsuite';
import { getLoggedUser } from '../../selectors/AuthorizationSelector';
import {
  getOrderTrackingVisible
} from '../../selectors/OrderTrackingSelector';
import {
  getAuthModalData,
  getMainLoggingParams
} from '../../selectors/SystemSelector';
import {
  EDIT_ORDER_ALLOWANCE,
  getCSVOrderOptions,
  COMPANY_NOT_COPY
} from '../../utils/Constants';
import { getOutputExcelOrders } from '../../utils/Mappers/OrderMappers';
import OrderTrackingContainer from '../OrderTracking/OrderTrackingContainer';
import { rollbarErrors } from '../../utils/rollbarErrors';

type InvoiceParams = {
  action: string;
  orderId: string;
  statusId: string;
  statusAction?: string;
}

const ListOrders: React.FC = () => {
  const dispatch = useDispatch();
  const { companyId, customerId, user, showDeliveryDate, ...loggingParams } = useSelector(
    getMainLoggingParams,
  );
  const orderTrackingVisible = useSelector(getOrderTrackingVisible);
  const loggedUser = useSelector(getLoggedUser);
  const statusList = useSelector(getStatusList);
  const authModalVisible = useSelector(getAuthModalData);
  const deliveryStatusId = useSelector(getDeliveryStatusId);
  const cancelStatusId = useSelector(getCancelStatusId);

  const handleOnClickExportCSV = data => {
    const orderList = data.map(order => order.original);
    const options = getCSVOrderOptions(loggedUser);
    const csvExporter = new ExportToCsv(options);
    const formattedData = getOutputExcelOrders(orderList);
    csvExporter.generateCsv(formattedData);
  };

  useEffect(() => {
    dispatch(getTags(companyId));
  }, []);

  const getDocumentCreationStatus = type => {
    const status = statusList.find(status => status.role == type);
    return status.id;
  };

  const redirectCreateOrder = () => dispatch(redirect('create-order'));

  const { data, isLoading, isError, error } = useFindQuery({
    toCreatedAt: dayjs()
      .set('hours', 23)
      .set('minutes', 59)
      .set('seconds', 59)
      .set('milliseconds', 999)
      .toDate(),
    fromCreatedAt: dayjs()
      .subtract(45, 'days')
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .toDate(),
    orderBy: 'promisedDeliveryDateTime:DESC',
    limit: 100,
    offset: 0,
  });

  const objectBody = {
    toCreatedAt: dayjs()
      .set('hours', 23)
      .set('minutes', 59)
      .set('seconds', 59)
      .set('milliseconds', 999)
      .toDate(),
    fromCreatedAt: dayjs()
      .subtract(45, 'days')
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .toDate(),
    orderBy: 'promisedDeliveryDateTime:DESC',
    limit: 4000,
    offset: 0,
  }

  useEffect(() => {
    if (isError) {
      rollbarErrors(error, objectBody, 'GET/orders', customerId, user)
    }
  }, [isError]);

  const handleCloseModal = () => {
    dispatch(closeAuthModal());
    dispatch(redirect('/logout'));
  };

  const hiddenColumns = useMemo(() => {
    if (showDeliveryDate === false) return ['promisedDelivery']
    if (data?.items?.some(order => order.tags?.length > 0)) return [];
    return ['tags'];
  }, [data]);

  const statusOptions = useMemo(
    () =>
      data
        ? Array.from(
            new Set(data?.items?.map(v => v.status.description)),
          ).map(value => ({ value, label: value }))
        : [],
    [data],
  );

  const paymentOptions = useMemo(
    () =>
      data
        ? Array.from(
            new Set(data?.items?.map(v => v.paymentMethod.description)),
          ).map(value => ({ value, label: value }))
        : [],
    [data],
  );

  const globalFilter = useCallback<GlobalFilterCallback<Order>>(
    (rows, columnIds, filterValue: string) => {
      return rows.filter(row => {
        return (
          row.original.orderNumber.toString().includes(filterValue) ||
          row.original.customer.fullName
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          dayjsCalendar(row.original.promisedDeliveryDateTime)
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          row.original.status.description
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          row.original.invoiceId?.includes(filterValue) ||
          row.original.purchaseOrderId?.includes(filterValue) ||
          row.original.paymentMethod.description
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          row.original.orderNumberShopify?.toString().includes(filterValue) ||
          row.original.incomingPurchaseOrder?.includes(filterValue)
        );
      });
    },
    [],
  );

  const columns = useMemo<Column<Order>[]>(
    (): Column<Order>[] => [
      {
        Header: <FormattedMessage id="ordox" />,
        accessor: 'orderNumber',
        sticky: true,
        width: 80,
        maxWidth: 85,
        Cell: ({ value, state }) => (
          <div className="text-center">
            <HighlightText text={value} subtext={state.globalFilter} />
          </div>
        ),
      },
      {
        id: 'client',
        Header: <FormattedMessage id="client" />,
        accessor: row => row.customer.fullName,
      },
      {
        id: 'status',
        Header: <FormattedMessage id="state" />,
        accessor: row => row.status.description,
        width: 100,
        maxWidth: 100,
        Cell: ({ value, state, row }) => (
          <Tag color={row.original.statusColor} size="sm">
            <HighlightText text={value} subtext={state.globalFilter} />
          </Tag>
        ),
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <CheckPicker
              id={id}
              name={id}
              placeholder="-"
              size="xs"
              data={statusOptions}
              block
              searchable={false}
              cleanable={false}
              value={filterValue || []}
              onChange={setFilter}
            />
          );
        },
        filter: (rows, _, filterValue) =>
          rows.filter(row =>
            filterValue?.length > 0
              ? filterValue.includes(row.values['status'])
              : true,
          ),
      },
      {
        id: 'promisedDelivery',
        Header: <FormattedMessage id="delivery-date" />,
        accessor: data => new Date(data.promisedDeliveryDateTime),
        width: 300,
        maxWidth: 300,
        sortType: 'datetime',
        Cell: ({ value, inline, state }: CellProps<Order, Date>) => {
          const date = dayjs(value);

          if (inline) {
            return (
              <HighlightText
                text={dayjsCalendar(date)}
                subtext={state.globalFilter}
              />
            );
          }

          return (
            <>
              <Whisper
                trigger={['click', 'hover', 'active']}
                placement="bottomStart"
                speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}
              >
                <div className="text-sm font-light first-letter:capitalize">
                  <HighlightText
                    text={dayjsCalendar(date)}
                    subtext={state.globalFilter}
                  ></HighlightText>
                </div>
              </Whisper>
            </>
          );
        },
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <DateRangePicker
              id={id}
              name={id}
              size="xs"
              block
              showOneCalendar
              value={filterValue || []}
              onChange={setFilter}
              format="dd/MM/yyyy"
              ranges={dayjsRanges([
                'today',
                'tomorrow',
                'yesterday',
                'last7Days',
                'next7Days',
              ])}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          return rows.filter(row =>
            filterValue?.length >= 2
              ? dayjs(row.values['promisedDelivery']).isBetween(
                  filterValue[0],
                  filterValue[1],
                  'day',
                  '[]',
                )
              : true,
          );
        },
      },
      {
        Header: <FormattedMessage id="total" />,
        accessor: 'totalAfterTax',
        width: 120,
        maxWidth: 120,
        minWidth: 120,
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <Input
              size="xs"
              inputMode="numeric"
              value={filterValue || ''}
              onChange={v => setFilter(v.replace(/[^\d]/g, ''))}
            />
          );
        },
        Cell: ({ value }) => (
          <FormattedNumberParts
            value={value}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
          >
            {parts => (
              <span className="font-light text-base">
                <span className="mr-1 select-none">$</span>
                {parts.slice(0, -1).reduce((str, part) => str + part.value, '')}
                <span className="text-xs">{parts.at(-1).value}</span>
              </span>
            )}
          </FormattedNumberParts>
        ),
      },
      {
        Header: <FormattedMessage id="units" />,
        accessor: 'totalUnits',
        width: 50,
        Cell: ({ value, state, inline }) =>
          inline ? (
            <HighlightText text={value} subtext={state.globalFilter} />
          ) : (
            <div className="font-light text-center">
              <HighlightText text={value} subtext={state.globalFilter} />
            </div>
          ),
      },
      {
        id: 'paymentMethod',
        Header: <FormattedMessage id="payment" />,
        accessor: row => row.paymentMethod.description,
        width: 120,
        maxWidth: 120,
        Cell: ({ value, state }) => (
          <HighlightText text={value} subtext={state.globalFilter} />
        ),
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <CheckPicker
              id={id}
              name={id}
              placeholder="-"
              size="xs"
              data={paymentOptions}
              block
              searchable={false}
              cleanable={false}
              value={filterValue || []}
              onChange={setFilter}
            />
          );
        },
        filter: (rows, _, filterValue) =>
          rows.filter(row =>
            filterValue?.length > 0
              ? filterValue.includes(row.values['paymentMethod'])
              : true,
          ),
      },
      {
        id: '$actions',
        accessor: 'id',
        disableFilters: true,
        disableGlobalFilter: true,
        disableSortBy: true,
        maxWidth: 105,
        minWidth: 105,
        width: 30,
        Cell: ({ row }) => {
          const loadItems = () =>
            dispatch(loadItemsEditOrder({ action: 'EDIT', order: row.original, filters: { populateData: true } } ));

          const copyOrder = () =>
            dispatch(loadItemsEditOrder({ action: 'COPY', order: row.original, filters: { populateData: true } }));

          const getOrderDetails = () => {
            dispatch(
              getOrderTracking({
                populateData: true,
                action: 'ORDER_TRACKING',
                orderIds: [row.original.id],
              }),
            );
          }

          const updateOrderStatus = () =>
            dispatch(openUpdateOrderStatus(row.original));


          const openInvoiceURL = () => {
            dispatch(
              getOrderInvoiceURL({
                id: row.original.id,
                erp: loggingParams.erp,
              }),
            );
          };

          const isEditable = (promisedDeliveryDate, statusId) => {
            const promise = dayjs(promisedDeliveryDate);
            const currentTime = dayjs();
            const remainingTime = promise.diff(currentTime, 'minute');

            if (
              remainingTime < EDIT_ORDER_ALLOWANCE ||
              statusId === cancelStatusId ||
              statusId === deliveryStatusId
            ) {
              return false;
            }
            return true;
          };

          return (
            <div>
              <IconButton
                title="Ver detalles"
                size="sm"
                icon={<InfoIcon />}
                onClick={getOrderDetails}
              />
              <Whisper
                trigger="click"
                placement="left"
                delay={10}
                speaker={
                  <Popover className="p-0" arrow>
                    <Dropdown.Menu>
                      {companyId !== COMPANY_NOT_COPY &&
                        <Dropdown.Item onClick={copyOrder}>
                        <FormattedMessage id="action.copy" />
                      </Dropdown.Item>
                      }
                      {isEditable(
                        row.original.promisedDeliveryDateTime,
                        row.original.statusId,
                      ) && (
                        <Dropdown.Item onClick={loadItems}>
                          <FormattedMessage id="action.edit" />
                        </Dropdown.Item>
                      )}
                      {row.original.invoiceId && loggingParams.erp && (
                        <Dropdown.Item onClick={openInvoiceURL}>
                          <FormattedMessage id="action.view-invoice" />
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <IconButton
                  className="shadow-md bg-gray-50 ring-1 ring-gray-300 text-gray-600 focus:text-gray-600"
                  icon={<MoreIcon />}
                  circle
                  appearance="subtle"
                  size="sm"
                />
              </Whisper>
            </div>
          );
        },
      },
    ],
    [statusOptions, paymentOptions, dispatch],
  );

  const rowContent = useCallback<DataGridRowContent<Order>>(
    (index, row, { viewport }) => {
      if (viewport.isWide) return;

      const cells = cellsById(row.cells);

      return (
        <td
          className={`flex flex-row justify-between items-stretch py-2 px-4 border-y -mb-px border-gray-200`}
        >
          <div className="flex flex-col flex-nowrap flex-1">
            <div className="mb-1 flex items-center text-xs text-gray-500">
              <span className="pr-2 -ml-1">
                {cells.$rowSelect.render('Cell')}
              </span>
              <span className="flex flex-row">
                #{cells.orderNumber.render('Cell')}
              </span>
              <span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
              {cells.promisedDelivery && (
                <span>
                  {cells.promisedDelivery.render('Cell', { inline: true })}
                </span>
              )}
            </div>

            <span className="mb-0.5 text-lg font-normal text-gray-700">
              {cells.client.render('Cell')}
            </span>

            <div className="mb-0.5 text-gray-500">
              <span className="text-xs mr-2">
                {cells.status.render('Cell')}
              </span>
              <span className="text-sm">
                {cells.paymentMethod.render('Cell')}
              </span>
              <span>
                {cells.status.row.original.tags && (
                  <TagFigure tags={cells.status.row.original.tags} />
                )}
              </span>
            </div>
          </div>
          <div className="flex flex-col flex-nowrap items-end">
            <span className="flex-1">{cells.totalAfterTax.render('Cell')}</span>
            <div className="mb-1">
              <span className="text-sm">
                {cells.totalUnits.render('Cell', { inline: true })}
              </span>
              <span className="ml-0.5 lowercase text-sm text-gray-500">
                <FormattedPlural
                  value={cells.totalUnits.value}
                  one={<FormattedMessage id="unit" />}
                  other={<FormattedMessage id="units" />}
                />
              </span>
            </div>
            <span>{cells.$actions.render('Cell')}</span>
          </div>
        </td>
      );
    },
    [],
  );

  const topToolbar = useCallback<DataGridToolbar<Order>>(
    ({ filteredFlatRows, selectedFlatRows, rows }) => {
      return (
        <>
          <IconButton
            className="ml-auto mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<PlusIcon />}
            title="Crear orden"
            onClick={redirectCreateOrder}
          />
          <IconButton
            className="mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<FaFileExcel className="rs-icon text-green-500" />}
            title="Exportar a csv"
            onClick={() => handleOnClickExportCSV(rows)}
          ></IconButton>
        </>
      );
    },
    [],
  );

  return (
    <div className="h-screen flex flex-col pb-1">
      <PageHeader title={<FormattedMessage id="orders" />} />
      <DataGrid
        className="flex-1"
        data={isLoading ? [] : data?.items!}
        totalCount={isLoading ? 0 : data?.total!}
        columns={columns}
        rowContent={rowContent}
        selectable
        topToolbar={topToolbar}
        initialState={{ hiddenColumns }}
        globalFilter={globalFilter}
      />
      {orderTrackingVisible && (
        <OrderTrackingContainer
          visible={orderTrackingVisible}
          history={false}
        />
      )}
      <AuthModalComponent
        open={authModalVisible.open}
        handleClose={handleCloseModal}
      />
    </div>
  );
};

export default ListOrders;
