import history from '@router/history';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import types from '../actions/ActionTypes';
import api from '../api/MasterDataApi';
import { mapMasterDataList, mapTags } from '../utils/Mappers/MasterDataMappers';

function* getAllStates(action) {
  let statesList = yield call(api.getAllStates);
  yield put(actions.updateStates(mapMasterDataList(statesList.data, 'STATES')));
  yield put(actions.setShowSpinner(false));
}

function* getSelectedStateCities(action) {
  try {
    const companyId = action.payload.companyId;
    let citiesList = yield call(
      api.getAllStateCities,
      companyId,
      action.payload.stateId,
    );
    const mappedCities = mapMasterDataList(citiesList.data, 'CITIES');
    yield put(actions.updateSelectedStateCities(mappedCities));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.setShowSpinner(false));
    if (action.callback) {
      action.callback();
    }
  }
}

export function* getChannels(action) {
  try {
    const channels = yield call(api.getAllChannels);

    yield put(
      actions.updateChannels(mapMasterDataList(channels.data.items, 'CHANNELS')),
    );
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* getInvoicingSettings(action) {
  try {
    const invoicingSettings = yield call(api.getAllInvoicingSettings, action.payload);

    yield put(
      actions.updateInvoicingSettings(
        mapMasterDataList(invoicingSettings.data.items, 'INVOICING_SETTINGS'),
      ),
    );
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export function* getTaxes(action) {
  try {
    const taxes = yield call(api.getAllTaxes);

    yield put(actions.updateTaxes(mapMasterDataList(taxes.data.items, 'TAXES')));
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export function* getFulFillmentTypes(action) {
  try {
    const fulfillmentTypes = yield call(api.getAllFulFillmentTypes);
    yield put(actions.updateFulFillmentTypes(mapMasterDataList(fulfillmentTypes.data.items, 'FULFILLMENT_TYPES')));
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* redirect(action) {
  yield call(history.push, action.payload);
}

export function* searchStates(action) {
  try {
    const response = yield call(api.searchStates, action.payload);

    yield put(actions.setFoundStates(mapMasterDataList(response.data.items)));
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* searchCities(action) {
  try {
    const response = yield call(api.getAllStateCities, action.payload);

    yield put(actions.setFoundCities(mapMasterDataList(response.data.items)));
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export function* getTags(action) {
  try {
    const tags = yield call(api.getAllTags);

    yield put(actions.updateStateTags(mapTags(tags.data.items)));
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export default function* () {
  yield all([
    takeLatest(types.GET_SELECTED_STATE_CITIES, getSelectedStateCities),
    takeLatest(types.GET_CHANNELS, getChannels),
    takeLatest(types.GET_INVOICING_SETTINGS, getInvoicingSettings),
    takeLatest(types.REDIRECT, redirect),
    takeLatest(types.GET_TAXES, getTaxes),
    takeLatest(types.GET_FULFILLMENT_TYPES, getFulFillmentTypes),
    takeLatest(types.SEARCH_STATES, searchStates),
    takeLatest(types.SEARCH_CITIES, searchCities),
    takeLatest(types.GET_TAGS, getTags),
  ]);
}
