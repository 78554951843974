import apis from '@api/query/apis';
import { initialState } from '@reducers/initialState';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { setAutoFreeze } from 'immer';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducers';
import { mainSaga } from './rootSaga';
import { persistState, restoreState } from './Storage.js';

setAutoFreeze(false);

const sagaMiddleware = createSagaMiddleware();

export type OrdoxState = typeof initialState;

const rootReducer = combineReducers({
  ordox: reducer,
  ...apis.reducers,
});

const store = configureStore({
  reducer: rootReducer,
  preloadedState: restoreState({ ordox: initialState }) as {
    ordox: OrdoxState;
  },
  devTools: true,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(...apis.middlewares, sagaMiddleware),
});

export type AppState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);

sagaMiddleware.run(mainSaga);
store.subscribe(() => persistState(store));

export default store;
