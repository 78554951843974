import axios from "axios";
import { API_BACKEND_URL } from "../utils/Constants";

const api = {
  async trigerInvoicing(order) {
    return await axios
      .post(API_BACKEND_URL + "orderEvent/invoice", order)
      .then((response) => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },
  async triggerOrderEvent(orderEvent) {
    return await axios
      .post(API_BACKEND_URL + "orderEvent/trigger", orderEvent)
      .then((response) => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },
};

export default api;
