import {
  getFormattedStatusList,
  getStatusList,
} from "@selectors/StatusSelector";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import UpdateOrderStatus from "../../components/OrderTracking/UpdateOrderStatus";
import * as selectorOrderTracking from "../../selectors/OrderTrackingSelector";
import * as SystemSelector from "../../selectors/SystemSelector";

class UpdateOrderStatusContainer extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.updateOrderStatus = this.updateOrderStatus.bind(this);
    this.handleCommentsChange = this.handleCommentsChange.bind(this);
  }

  componentDidMount() {
    if (this.props.fullStatusList.length == 0) {
      this.props.getStatus(this.props.companyId);
    }

    this.props.updateOrderStatusStatusChange(this.props.nextStatus?.value);
  }

  onClose() {
    this.props.hideUpdateOrderStatus();
  }

  changeStatus(status) {
    this.props.updateOrderStatusStatusChange(status);
  }

  handleCommentsChange(comments) {
    this.props.updateTrackingComments(comments);
  }

  updateOrderStatus() {
    this.props.disableButton(true);
    this.props.updateOrderStatus({
      companyId: this.props.companyId,
      statusId: this.props.newOrderStatus,
      orderId: this.props.orderStatusWillUpdate.id,
      userId: this.props.loggingParams.userId,
      statusNotes: this.props.statusNotes,
    });
    this.props.hideUpdateOrderStatus();
    this.triggerOrderEvent(this.props.newOrderStatus);
    this.props.updateTrackingComments("");
  }

  triggerOrderEvent(statusId) {
    const statusIdInvoiced = this.getDocumentCreationStatus("INVOICE");
    const statusIdPOCreated = this.getDocumentCreationStatus("PO_CREATED");

    this.props.triggerOrderEvent({
      companyId: this.props.companyId,
      userId: this.props.loggingParams.userId,
      orderId: this.props.orderStatusWillUpdate.id,
      statusIdInvoiced,
      statusIdPOCreated,
    });
  }

  getDocumentCreationStatus(type) {
    const status = this.props.fullStatusList.find(
      (status) => status.role == type
    );
    return status.id;
  }

  render() {
    return (
      <div>
        <UpdateOrderStatus
          visible={this.props.visible}
          orderId={this.props.orderStatusWillUpdate.orderNumber}
          nextStatus={this.props.nextStatus}
          newOrderStatus={this.props.newOrderStatus}
          statusList={this.props.manualStatusList}
          onClose={this.onClose}
          changeStatus={this.changeStatus}
          updateOrderStatus={this.updateOrderStatus}
          buttonDisabled={this.props.buttonDisabled}
          comments={this.props.comments}
          changeComments={this.handleCommentsChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    manualStatusList: getFormattedStatusList(state),
    fullStatusList: getStatusList(state),
    companyId: SystemSelector.getMainLoggingParams(state).companyId,
    nextStatus: selectorOrderTracking.getNextStatus(state),
    loggingParams: SystemSelector.getMainLoggingParams(state),
    statusNotes: selectorOrderTracking.getTrackingComments(state),
    newOrderStatus: selectorOrderTracking.getNewOrderStatus(state),
    orderStatusWillUpdate: selectorOrderTracking.getOrderStatusWillUpdate(
      state
    ),
  };
};
export default connect(mapStateToProps, actions)(UpdateOrderStatusContainer);
