import { styled } from 'goober';
import React, { useCallback, useEffect, useMemo } from 'react';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { useIMask } from 'react-imask';
import { Input, InputGroup, InputProps } from 'rsuite';

type InputNumberProps = Omit<
  InputProps,
  'min' | 'max' | 'value' | 'onChange'
> & {
  unit?: string;
  scale?: number;
  min?: number;
  max?: number;
  value?: number;
  onChange?(value: number): void;
  spinButtons?: boolean;
};

const InputNumberStyled = styled(InputGroup)`
  & .rs-input-group-btn {
    border-radius: 0px !important;
    color: black;
    width: 22px;
    min-width: 3px !important;
    padding: 4px !important;
  }
  & .rs-input-group-btn:last-child {
    border-radius: 0 4px 4px 0 !important;
  }
  & .unit {
    border-radius: 0;
    background-color: white;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  & .rs-input {
    width: 100%;
    min-width: 4rem;
    padding-left: 1px;
    padding-right: 1px;
    text-align: right;
  }
`;

const InputNumber: React.FC<InputNumberProps> = ({
  disabled,
  value,
  onChange,
  unit,
  scale = 3,
  min = 0,
  max = 1000000,
  spinButtons = true,
  ...props
}) => {
  const options = useMemo(
    () => ({
      mask: Number,
      radix: ',',
      maxLength: 10,
      placeholderChar: '0',
      min,
      max,
      thousandsSeparator: ' ',
      mapToRadix: ['.'],
      scale,
      eager: true,
      autofix: true,
      overwrite: true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onAccept = useCallback(
    (_, mask: IMask.InputMask<typeof options>) => {
      if (Math.abs(value - mask.typedValue) > 0.001 || value === undefined) {
        onChange(mask.typedValue);
      }
    },
    [onChange],
  );

  const { ref, typedValue, setTypedValue, unmaskedValue } = useIMask(options, {
    onAccept,
  });

  useEffect(() => {
    const nextValue =
      value === undefined || value === null || isNaN(value) ? min : value;

    if (Math.abs(nextValue - typedValue) > 0.001 || unmaskedValue === '') {
      setTypedValue(nextValue);
    }
  }, [value]);

  return (
    <InputNumberStyled size="sm">
      <Input
        ref={disabled ? undefined : ref}
        {...props}
        onFocus={ev => ev.currentTarget.select()}
        inputMode="numeric"
      />
      {unit && <InputGroup.Addon className="unit">{unit}</InputGroup.Addon>}
      {spinButtons && (
        <>
          <InputGroup.Button
            tabIndex={0}
            onClick={() => setTypedValue(Math.max(min, typedValue - 1))}>
            <BiMinus />
          </InputGroup.Button>
          <InputGroup.Button
            tabIndex={0}
            onClick={() => setTypedValue(Math.min(max, typedValue + 1))}>
            <BiPlus />
          </InputGroup.Button>
        </>
      )}
    </InputNumberStyled>
  );
};

export default InputNumber;
