import CustomerInfo from "@components/CustomerInfo";
import OrderItems from "@components/OrderItems";
import OrderTracking from "@components/OrderTracking";
import GeneralInfo from "@components/OrderTracking/GeneralInfo";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Drawer, Modal, Panel } from "rsuite";
import * as actions from "../../actions";
import * as SelectorOrderTracking from "../../selectors/OrderTrackingSelector";
import {
  getMainLoggingParams,
  getUserDevice,
} from "../../selectors/SystemSelector";

class OrderTrackingContainer extends Component {
  constructor(props) {
    super(props);
    this.closeOrderTracking = this.closeOrderTracking.bind(this);
  }

  componentDidMount() {}

  closeOrderTracking() {
    this.props.hideOrderTracking("orderTracking");
  }

  render() {
    const orderTracking = this.props.orderTracking[0];
    if (orderTracking) {
      return (
        <div>
          <Modal
            size="md"
            placement="top"
            open={this.props.orderTrackingVisible}
            onClose={this.closeOrderTracking}
            autoFocus
            keyboard
          >
            <Modal.Header>
              <Drawer.Title className="font-medium">
                Savia #{orderTracking.order.orderNumber}
              </Drawer.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="container">
                <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
                  <div className="col-span-3">
                    <Panel bordered>
                      <CustomerInfo customer={orderTracking.order.customer} shippingAddress={orderTracking.order.shippingAddress} />
                    </Panel>
                  </div>
                  <div className="row-span-2 col-span-2">
                    <Panel bordered>
                      <OrderTracking
                        trackingInfo={orderTracking.orderTracking}
                      />
                    </Panel>
                  </div>
                  <div className="col-span-3">
                    <Panel bordered>
                      <GeneralInfo
                        order={orderTracking.order}
                        ecomSite={this.props.ecomSite}
                      />
                    </Panel>
                  </div>
                </div>
              </div>
              <div className="bg-white">
                <OrderItems
                  orderItems={orderTracking.orderItems}
                  isMobile={this.props.isMobile}
                  totalAmount={orderTracking.order.totalAfterTax}
                  totalUnits={orderTracking.order.totalUnits}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    orderTracking: SelectorOrderTracking.getOrderTrackingData(state),
    orderTrackingVisible: SelectorOrderTracking.getOrderTrackingVisible(state),
    // orderItems: SelectorOrderTracking.getOrderItems(state),
    isMobile: getUserDevice(state),
    ecomSite: getMainLoggingParams(state).ecomSite,
  };
};

export default connect(mapStateToProps, actions)(OrderTrackingContainer);
