import api from '@api/OrderEventApi';
import {
  INVOICING_ERROR_NOTIFICATION_DURATION,
  NOTIFICATION_DURATION,
} from '@utils/Constants';
import { getDocumentSucessNotification } from '@utils/Notifications';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Message, toaster } from 'rsuite';
import * as actions from '../actions';
import types from '../actions/ActionTypes';

function* triggerInvoicing(action) {
  try {
    yield put(actions.setShowSpinner(true));
    const invoice = yield call(api.trigerInvoicing, action.payload);
    if (invoice.data?.id) {
      toaster.push(
        <Message type="info" showIcon closable duration={NOTIFICATION_DURATION}>
          {getDocumentSucessNotification(invoice.data)}
        </Message>,
      );
    } else {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={INVOICING_ERROR_NOTIFICATION_DURATION}
        >
          {invoice.data.error}
        </Message>,
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* triggerOrderEvent(action) {
  try {
    const orderEvent = yield call(api.triggerOrderEvent, action.payload);

    if (orderEvent.data?.id) {
      toaster.push(
        <Message type="info" showIcon closable duration={NOTIFICATION_DURATION}>
          {getDocumentSucessNotification(orderEvent.data)}
        </Message>,
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* () {
  yield all([
    takeLatest(types.TRIGGER_INVOICING, triggerInvoicing),
    takeLatest(types.TRIGGER_ORDER_EVENT, triggerOrderEvent),
  ]);
}
