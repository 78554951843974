export const mapCreateProductDto = (
  { tax, product: { cost, ...product } },
) => {
  let newProduct = product;

  // build up complete object
  newProduct.tax = tax.percentage;
  newProduct.priceBeforeTax = parseFloat(newProduct.priceBeforeTax);
  newProduct.priceAfterTax = parseFloat(
    (newProduct.priceBeforeTax * (1 + tax.percentage / 100)).toFixed(2)
  );
  newProduct.unitsCount = true;
  newProduct.taxIdERP = tax.value;

  return newProduct;
};
