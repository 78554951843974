import React, { useDeferredValue, useState } from 'react';
import { useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import { FormattedMessage } from 'react-intl';
import { useLocalstorageState } from 'rooks';
import { IconButton, Input, InputGroup, Loader, Panel } from 'rsuite';


import { Contact, Customer } from '@api/query/customerApi';
import DrawerModal from '@components/ui/DrawerModal';
import PlusIcon from '@rsuite/icons/Plus';
import SearchIcon from '@rsuite/icons/Search';
import { selectCompany } from '@selectors/SystemSelector';
import useViewport from '@utils/useViewport';
import FormattedEmail from '../ui/FormattedEmail';
import FormattedIndentification from '../ui/FormattedIndentification';
import { CustomerSummary } from './components/CustomerSummary';

export type CustomerBrowserProps = {
  className?: string;
  customer?: Customer;
  onCustomerChange(value: Customer): void;
  onContactChange(contact: Contact): void
};

const CustomerBrowser: React.FC<CustomerBrowserProps> = ({
  className,
  customer,
  onCustomerChange,
  onContactChange
}) => {
  const viewport = useViewport();

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const { id: companyId } = useSelector(selectCompany);

  const [search, setSearch] = useLocalstorageState('customer:search', '');

  const searchDeferred = useDeferredValue(search);

  // const customerQuery = useFindCustomerQuery({
  //   companyId,
  //   fullName: searchDeferred,
  //   limit: 100,
  //   offset: 0,
  // });

  // const context = useMemo(() => ({ data: customerQuery?.data?.items }), [
  //   customerQuery.data,
  // ]);

  // type Context = typeof context;

  // const components = useMemo<Components<Context>>(() => {
  //   return {
  //     EmptyPlaceholder: ({ context: { data } }) => (
  //       <div className="text-center py-4 font-semibold">
  //         {data?.length === 0 && <span>No se encontró ningún cliente</span>}
  //       </div>
  //     ),
  //   };
  // }, []);
  return (
    <>
      <Panel
        className={className}
        header={
          <div className="flex justify-between">
            <span className="font-medium w-full">
              <FormattedMessage id="customer" />
            </span>
            {/* <span>
              <IconButton
                icon={<SearchIcon />}
                size="sm"
                appearance="link"
                onClick={() => setOpen(true)}>
                {!customer ? 'Buscar cliente' : undefined}
              </IconButton>
            </span> */}
          </div>
        }
      >
        {customer && <CustomerSummary value={customer} onContactChange={onContactChange} />}
      </Panel>
      <DrawerModal
        open={open}
        DrawerProps={{ size: 'full' }}
        onClose={onClose}
        placement={viewport.isNarrow ? 'bottom' : 'floating'}
        title={<FormattedMessage id="customer.select-customer" />}
        body={
          <div className="flex flex-col flex-grow">
            <div className="flex-row mx-2 mt-1">
              <InputGroup inside size="sm">
                <InputGroup.Addon>
                  <SearchIcon />
                </InputGroup.Addon>
                <Input
                  type="search"
                  autoFocus
                  autoComplete="off"
                  inputMode="search"
                  autoCorrect="off"
                  onChange={value => setSearch(value)}
                  value={search}
                />
                {/* {customerQuery.isFetching && (
                  <InputGroup.Addon>
                    <Loader />
                  </InputGroup.Addon>
                )} */}
              </InputGroup>
            </div>
            <IconButton
              className="mx-3 my-2 shadow-sm"
              size="sm"
              icon={<PlusIcon />}
            >
              Crear un nuevo cliente
            </IconButton>
            <div className="flex-grow mt-2 relative overflow-hidden md:h-96 shadow-inner">
              <Virtuoso
                increaseViewportBy={200}
                // data={customerQuery.data?.items}
                // components={components}
                // context={context}
                itemContent={(_, value) => (
                  <CustomerItem
                    value={value}
                    active={customer?.id === value.id}
                    onClick={() => {
                      onCustomerChange(value);
                      setOpen(false);
                    }}
                  />
                )}
              />
            </div>
          </div>
        }
      ></DrawerModal>
    </>
  );
};

type CustomerItemProps = {
  value: Customer;
  active?: boolean;
  onClick?(
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    value: Customer,
  ): void;
};

const CustomerItem: React.FC<CustomerItemProps> = ({
  value,
  active,
  onClick,
}) => {
  return (
    <div
      className={`flex flex-row flex-wrap px-4 py-2 border-y -mb-px cursor-pointer transition-colors ${active
        ? 'bg-blue-500 text-white'
        : 'text-slate-700 hover:bg-slate-200 hover:text-slate-700'
        } `}
      onClick={ev => onClick(ev, value)}
    >
      <span className="basis-1/2 font-medium">{value.fullName}</span>
      <FormattedEmail
        className="basis-1/2 text-end text-current visited:text-current hover:text-current"
        value={value.primaryEmail}
        onClick={ev => ev.preventDefault()}
      />
      <span className="basis-full text-end mt-1">
        <b className="mr-1 text-sm">CC:</b>
        <FormattedIndentification
          value={value.identification}
          onClick={ev => ev.preventDefault()}
        />
      </span>
    </div>
  );
};

export default CustomerBrowser;
