import React from 'react';
import '../styles/OrderItems.css';
import { formatCurrencySpanishCO } from '../utils/DataFormat';

const OrderItems = ({ orderItems, isMobile, totalAmount, totalUnits }) => {
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="py-2 inline-block min-w-full">
          <div className="overflow-auto rounded-lg shadow">
            <table className="min-w-full border">
              <thead className="bg-gray-50 border-b-2 border-gray-200">
                <tr>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-600 px-6 py-2 text-left"
                  >
                    Producto
                    <p className="text-sm text-white"> - </p>
                  </th>
                  <th
                    scope="col"
                    class="text-sm font-medium text-gray-600 px-6 py-2 text-left"
                  >
                    Cantidad
                    <p className="text-sm text-gray-900"> {totalUnits} </p>
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-600 px-6 py-2 text-left"
                  >
                    Valor
                    <p className="text-sm text-gray-900">
                      {formatCurrencySpanishCO(totalAmount)}
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderItems.map(item => {
                  return (
                    <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                      <td className="px-6 py-2 text-wrap text-sm font-light text-gray-900">
                        {item.productName}
                        {item.notes && (
                          <p className="text-xs text-blue-700">{item.notes}</p>
                        )}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                        {item.quantity}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                        {formatCurrencySpanishCO(
                          item.priceAfterTax *
                            (1 - item.discount / 100) *
                            item.quantity,
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    // <Table
    //   data={orderItems}
    //   virtualized
    //   rowHeight={30}
    //   cellBordered
    //   bordered
    //   wordWrap
    //   headerHeight={60}
    //   height={getTableHeight(orderItems.length)}
    // >
    //   <Column flexGrow={flexGrow.col1} minwidth={350} align="center">
    //     <HeaderCell className="grid-header">Producto</HeaderCell>
    //     <Cell dataKey="productName">
    //       {(rowData) => <OrderItemRow rowData={rowData} value="productName" />}
    //     </Cell>
    //   </Column>
    //   <Column flexGrow={flexGrow.col2} minwidth={10} align="center">
    //     <HeaderCell className="grid-header">
    //       Cantidad
    //       <HeaderSummary summary={units} />
    //     </HeaderCell>
    //     <Cell dataKey="product.quantity">
    //       {(rowData) => <OrderItemRow rowData={rowData} value="quantity" />}
    //     </Cell>
    //   </Column>
    //   <Column flexGrow={flexGrow.col3} align="center" minwidth={35}>
    //     <HeaderCell className="grid-header">
    //       Valor Total
    //       <HeaderSummary summary={formatCurrencySpanishCO(amount)} />
    //     </HeaderCell>
    //     <Cell dataKey="product.priceAfterTax">
    //       {(rowData) => (
    //         <OrderItemRow rowData={rowData} value="priceAfterTax" />
    //       )}
    //     </Cell>
    //   </Column>
    // </Table>
  );
};

export default OrderItems;
