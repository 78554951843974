import queryString from 'query-string';
import axios from '../libs/axios';
import * as constants from '../utils/Constants';

const api = {
  async updateOrderInvoiceData(order) {
    return await axios
      .post(constants.API_BACKEND_URL + 'Order/UpdateInvoiceData', order)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },

  findOrder(id, filters = {}) {
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return axios.get(`orders/${id}${params && `?${params}`}`)
  },

  findOrderDetails(id) {
    return axios.get(`orders/${id}/items`)
  },

  async getInvoiceURL(id) {
    return await axios
      .get(`invoices/${id}/INVOICE/download`)
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          return {};
        }
      });
  },

  async getPurchaseOrderURL(companyId, purchaseOrderIdERP) {
    return await axios
      .get(
        'Order/GetPurchaseOrderURL?companyId=' +
          companyId +
          '&purchaseOrderIdERP=' +
          purchaseOrderIdERP,
      )
      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },
};

export default api;

// useful resources about API's idempotency
// https://medium.com/gin-and-tonic/understanding-idempotency-in-rest-apis-7a5568f41406
// https://stackoverflow.com/questions/56074531/how-to-retry-5xx-requests-using-axios
