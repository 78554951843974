// @ts-ignore
import {
  getOrderInvoiceURL,
  getOrderTracking,
  loadItemsEditOrder,
  openUpdateOrderStatus,
  redirect,
  showExportedPDF,
  triggerInvoicing,
} from '@actions/index';
import { Order, useFindQuery } from '@api/query/orderApi';import {
  cellsById,
  DataGrid,
  DataGridRowContent,
  DataGridToolbar,
  HighlightText,
} from '@components/DataGrid';
import PageHeader from '@components/PageHeader';
import UpdateOrderStatusContainer from '@containers/OrderTracking/UpdateOrderStatusContainer';
import { More as MoreIcon } from '@rsuite/icons';
import PlusIcon from '@rsuite/icons/Plus';
import { getStatusList } from '@selectors/StatusSelector';
import dayjs, { dayjsCalendar, dayjsRanges, formats } from '@utils/dayjs';
import * as reports from '@utils/ReportsBuilder/ShippingList';
import { ExportToCsv } from 'export-to-csv';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import {
  FormattedMessage,
  FormattedNumberParts,
  FormattedPlural,
} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CellProps, Column } from 'react-table';
import {
  CheckPicker,
  DateRangePicker,
  Dropdown,
  IconButton,
  Input,
  Popover,
  Tag,
  Tooltip,
  Whisper,
} from 'rsuite';
import { getLoggedUser } from '../../selectors/AuthorizationSelector';
import {
  getOrderTrackingData,
  getOrderTrackingVisible,
  getShowExportedPDF,
  getUpdateOrderStatusVisible,
} from '../../selectors/OrderTrackingSelector';
import { getMainLoggingParams } from '../../selectors/SystemSelector';
import { getCSVOrderOptions } from '../../utils/Constants';
import { getOutputExcelOrders } from '../../utils/Mappers/OrderMappers';
import OrderTrackingContainer from '../OrderTracking/OrderTrackingContainer';
import { rollbarErrors } from '../../utils/rollbarErrors';

const ListOrders: React.FC = () => {
  const dispatch = useDispatch();
  const { companyId, user, customerId, ...loggingParams } = useSelector(
    getMainLoggingParams,
  );
  const orderTrackingVisible = useSelector(getOrderTrackingVisible);
  const updateStatusContainerVisible = useSelector(getUpdateOrderStatusVisible);
  const loggedUser = useSelector(getLoggedUser);
  const statusList = useSelector(getStatusList);
  const orderTrackingData = useSelector(getOrderTrackingData);
  const pdfExportVisible = useSelector(getShowExportedPDF);
  const invoiceURL = useSelector(getOrderInvoiceURL);

  const handleOnClickExportCSV = data => {
    const orderList = data.map(order => order.original);
    const options = getCSVOrderOptions(loggedUser);
    const csvExporter = new ExportToCsv(options);
    const formattedData = getOutputExcelOrders(orderList);
    csvExporter.generateCsv(formattedData);
  };

  useEffect(() => {
    orderTrackingData.length && pdfExportVisible && exportOrdersToPDF();
  }, [orderTrackingData]);

  const handleOnClickPDF = checkedOrders => {
    dispatch(showExportedPDF(true));
    const selectedOrders = checkedOrders.map(order => order.original.id);
    dispatch(
      getOrderTracking({
        populateData: true,
        action: 'orderExport',
        orderIds: selectedOrders,
      }),
    );
  };

  const exportOrdersToPDF = async () => {
    const doc = await reports.buildShippingList(
      orderTrackingData,
      loggingParams,
    );
    reports.downloadShippingList(doc, null);
    dispatch(showExportedPDF(false));
  };

  const getDocumentCreationStatus = type => {
    const status = statusList.find(status => status.role == type);
    return status.id;
  };

  const redirectCreateOrder = () => dispatch(redirect('create-order'));

  const { data, isLoading, error, isError } = useFindQuery({
    limit: 5000,
    offset: 0,
    orderBy: 'createdAt:DESC',
  });
  const objectBody = {
    limit: 5000,
    offset: 0,
    orderBy: 'createdAt:DESC',
  }

  useEffect(() => {
    if (isError) {
      rollbarErrors(error, objectBody, 'GET/orders', customerId, user)
    }
  }, [isError]);

  const statusOptions = useMemo(
    () =>
      data
        ? Array.from(
            new Set(data?.items?.map(v => v.status.description)),
          ).map(value => ({ value, label: value }))
        : [],
    [data],
  );

  const paymentOptions = useMemo(
    () =>
      data
        ? Array.from(
            new Set(data?.items?.map(v => v.paymentMethod.description)),
          ).map(value => ({ value, label: value }))
        : [],
    [data],
  );

  const columns = useMemo<Column<Order>[]>(
    (): Column<Order>[] => [
      {
        Header: <FormattedMessage id="ordox" />,
        accessor: 'orderNumber',
        sticky: true,
        width: 80,
        maxWidth: 85,
        Cell: ({ value, state }) => (
          <div className="text-center">
            <HighlightText text={value} subtext={state.globalFilter} />
          </div>
        ),
      },
      {
        id: 'client',
        Header: <FormattedMessage id="client" />,
        accessor: row => row.customer.fullName,
      },
      {
        id: 'status',
        Header: <FormattedMessage id="state" />,
        accessor: row => row.status.description,
        width: 100,
        maxWidth: 100,
        Cell: ({ value, state, row }) => (
          <Tag color={row.original.statusColor} size="sm">
            <HighlightText text={value} subtext={state.globalFilter} />
          </Tag>
        ),
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <CheckPicker
              id={id}
              name={id}
              placeholder="-"
              size="xs"
              data={statusOptions}
              block
              searchable={false}
              cleanable={false}
              value={filterValue || []}
              onChange={setFilter}
            />
          );
        },
        filter: (rows, _, filterValue) =>
          rows.filter(row =>
            filterValue?.length > 0
              ? filterValue.includes(row.values['status'])
              : true,
          ),
      },
      {
        id: 'promisedDelivery',
        Header: <FormattedMessage id="delivery-date" />,
        accessor: data => new Date(data.promisedDeliveryDateTime),
        width: 300,
        maxWidth: 300,
        sortType: 'datetime',
        Cell: ({ value, inline }: CellProps<Order, Date>) => {
          const date = dayjs(value);

          if (inline) {
            return <>{dayjsCalendar(date)}</>;
          }

          return (
            <>
              <Whisper
                trigger={['click', 'hover', 'active']}
                placement="bottomStart"
                speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}
              >
                <div className="text-sm font-light first-letter:capitalize">
                  {dayjsCalendar(date)}
                </div>
              </Whisper>
            </>
          );
        },
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <DateRangePicker
              id={id}
              name={id}
              size="xs"
              block
              showOneCalendar
              value={filterValue || []}
              onChange={setFilter}
              format="dd/MM/yyyy"
              ranges={dayjsRanges([
                'today',
                'tomorrow',
                'yesterday',
                'last7Days',
                'next7Days',
              ])}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          return rows.filter(row =>
            filterValue?.length >= 2
              ? dayjs(row.values['promisedDelivery']).isBetween(
                  filterValue[0],
                  filterValue[1],
                  'day',
                  '[]',
                )
              : true,
          );
        },
      },
      {
        Header: <FormattedMessage id="total" />,
        accessor: 'totalAfterTax',
        width: 120,
        maxWidth: 120,
        minWidth: 120,
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <Input
              size="xs"
              inputMode="numeric"
              value={filterValue || ''}
              onChange={v => setFilter(v.replace(/[^\d]/g, ''))}
            />
          );
        },
        Cell: ({ value }) => (
          <FormattedNumberParts
            value={value}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
          >
            {parts => (
              <span className="font-light text-base">
                <span className="mr-1 select-none">$</span>
                {parts.slice(0, -1).reduce((str, part) => str + part.value, '')}
                <span className="text-xs">{parts.at(-1).value}</span>
              </span>
            )}
          </FormattedNumberParts>
        ),
      },
      {
        Header: <FormattedMessage id="units" />,
        accessor: 'totalUnits',
        width: 50,
        Cell: ({ value, state, inline }) =>
          inline ? (
            <HighlightText text={value} subtext={state.globalFilter} />
          ) : (
            <div className="font-light text-center">
              <HighlightText text={value} subtext={state.globalFilter} />
            </div>
          ),
      },
      {
        id: 'paymentMethod',
        Header: <FormattedMessage id="payment" />,
        accessor: row => row.paymentMethod.description,
        width: 120,
        maxWidth: 120,
        Cell: ({ value, state }) => (
          <HighlightText text={value} subtext={state.globalFilter} />
        ),
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <CheckPicker
              id={id}
              name={id}
              placeholder="-"
              size="xs"
              data={paymentOptions}
              block
              searchable={false}
              cleanable={false}
              value={filterValue || []}
              onChange={setFilter}
            />
          );
        },
        filter: (rows, _, filterValue) =>
          rows.filter(row =>
            filterValue?.length > 0
              ? filterValue.includes(row.values['paymentMethod'])
              : true,
          ),
      },
      {
        id: '$actions',
        accessor: 'id',
        disableFilters: true,
        disableGlobalFilter: true,
        disableSortBy: true,
        maxWidth: 30,
        minWidth: 30,
        width: 30,
        Cell: ({ row }) => {
          const loadItems = () =>
            dispatch(loadItemsEditOrder({ action: 'EDIT', order: row.original, filters: { populateData: true } }));

          const copyOrder = () =>
            dispatch(loadItemsEditOrder({ action: 'COPY', order: row.original, filters: { populateData: true } }));

          const getOrderDetails = () =>
            dispatch(
              getOrderTracking({
                populateData: true,
                action: 'ORDER_TRACKING',
                orderIds: [row.original.id],
              }),
            );

          const updateOrderStatus = () =>
            dispatch(openUpdateOrderStatus(row.original));

          const triggerInvoice = () =>
            dispatch(
              triggerInvoicing({
                orderId: row.original.id,
                userId: loggedUser.id,
                statusId: getDocumentCreationStatus('INVOICE'),
              }),
            );

          const openInvoiceURL = () => {
            dispatch(
              getOrderInvoiceURL({
                id: row.original.id,
                invoiceIdERP: row.original.invoiceIdERP,
                erp: loggingParams.erp,
              }),
            );
          };

          return (
            <Whisper
              trigger="click"
              placement="left"
              speaker={
                <Popover className="p-0" arrow>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={getOrderDetails}>
                      <FormattedMessage id="action.seeDetails" />
                    </Dropdown.Item>
                    {/* <Dropdown.Item onClick={copyOrder}>
                      <FormattedMessage id="action.copy" />
                    </Dropdown.Item> */}
                    {row.original.invoiceId && loggingParams.erp && (
                      <Dropdown.Item onClick={openInvoiceURL}>
                        <FormattedMessage id="action.view-invoice" />
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Popover>
              }
            >
              <IconButton
                className="shadow-md bg-gray-50 ring-1 ring-gray-300 text-gray-600 focus:text-gray-600"
                icon={<MoreIcon />}
                circle
                appearance="subtle"
                size="xs"
              />
            </Whisper>
          );
        },
      },
    ],
    [statusOptions, paymentOptions, dispatch],
  );

  const rowContent = useCallback<DataGridRowContent<Order>>(
    (index, row, { viewport }) => {
      if (viewport.isWide) return;

      const cells = cellsById(row.cells);

      return (
        <td
          className={`flex flex-row justify-between items-stretch py-2 px-4 border-y -mb-px border-gray-200`}
        >
          <div className="flex flex-col flex-nowrap flex-1">
            <div className="mb-1 flex items-center text-xs text-gray-500">
              <span className="pr-2 -ml-1">
                {cells.$rowSelect.render('Cell')}
              </span>
              <span className="flex flex-row">
                #{cells.orderNumber.render('Cell')}
              </span>
              <span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
              {cells.promisedDelivery && (
                <span>
                  {cells.promisedDelivery.render('Cell', { inline: true })}
                </span>
              )}
            </div>

            <span className="mb-0.5 text-lg font-normal text-gray-700">
              {cells.client.render('Cell')}
            </span>

            <div className="mb-0.5 text-gray-500">
              <span className="text-xs mr-2">
                {cells.status.render('Cell')}
              </span>
              <span className="text-sm">
                {cells.paymentMethod.render('Cell')}
              </span>
            </div>
          </div>
          <div className="flex flex-col flex-nowrap items-end">
            <span className="flex-1">{cells.totalAfterTax.render('Cell')}</span>
            <div className="mb-1">
              <span className="text-sm">
                {cells.totalUnits.render('Cell', { inline: true })}
              </span>
              <span className="ml-0.5 lowercase text-sm text-gray-500">
                <FormattedPlural
                  value={cells.totalUnits.value}
                  one={<FormattedMessage id="unit" />}
                  other={<FormattedMessage id="units" />}
                />
              </span>
            </div>
            <span>{cells.$actions.render('Cell')}</span>
          </div>
        </td>
      );
    },
    [],
  );

  const topToolbar = useCallback<DataGridToolbar<Order>>(
    ({ filteredFlatRows, selectedFlatRows, rows }) => {
      return (
        <>
          <IconButton
            className="ml-auto mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<PlusIcon />}
            title="Crear orden"
            onClick={redirectCreateOrder}
          />
          <IconButton
            className="mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<FaFileExcel className="rs-icon text-green-500" />}
            title="Exportar a csv"
            onClick={() => handleOnClickExportCSV(rows)}
          ></IconButton>
        </>
      );
    },
    [],
  );

  return (
    <div className="h-screen flex flex-col pb-1">
      <PageHeader title={<FormattedMessage id="orders-history" />} />
      <DataGrid
        className="flex-1"
        data={isLoading ? [] : data?.items!}
        totalCount={isLoading ? 0 : data?.total!}
        columns={columns}
        rowContent={rowContent}
        selectable
        topToolbar={topToolbar}
      />
      {orderTrackingVisible && (
        <OrderTrackingContainer
          visible={orderTrackingVisible}
          history={false}
        />
      )}
      {updateStatusContainerVisible && (
        <UpdateOrderStatusContainer visible={updateStatusContainerVisible} />
      )}
    </div>
  );
};

export default ListOrders;
