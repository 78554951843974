import Rollbar from 'rollbar';
import { ENABLED_ROLLBAR, ENVIRONMENT_ROLLBAR, TOKEN_ROLLBAR } from './Constants';

export function rollbarErrors(error, body, endPoint, companyId, email) {
  const rollbar = new Rollbar({
    accessToken: TOKEN_ROLLBAR,
    environment: ENVIRONMENT_ROLLBAR,
    exception_level_filters: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: ENABLED_ROLLBAR,
    payload: {
      context: 'savia-b2b-portal',
      client: {
        javascript: {
          code_version: '1.0.0',
        }
      }
    }
  });
  
  const objectError = {}

  if (typeof body === "object" && body !== null) {
    if (body instanceof Object) {
      objectError.body = JSON.stringify(body)
    }
  } else if (typeof variable === "string") {
    objectError.body = body
  }
  
  if (endPoint) {
    objectError.endPoint = endPoint
  }

  if (companyId) {
    objectError.companyId = companyId
  }

  if (email) {
    objectError.email = email
  }

  rollbar.error(error, objectError)
}

