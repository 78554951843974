import React from 'react';

const GeneralInfo = ({ order, ecomSite }) => (
  <div>
    <div>
      <b>Canal de venta: </b> {order.channel.description}
    </div>

    {/* <div>
      <b>Facturación: </b> {order.invoicingSetting.description}
    </div>

    {(order.invoiceId != 0 || order.purchaseOrderId != 0) && (
      <div>
        {order.invoiceId && (
          <div>
            <b>Factura: </b>
            <a
              href={GET_ALEGRA_INVOICE(order.invoiceIdERP)}
              className="stretched-link"
              target="_blank"
            >
              {order.invoiceId}
            </a>
          </div>
        )}
        {order.purchaseOrderId && (
          <div>
            <b>Remisión: </b>
            <a
              href={GET_ALEGRA_PURCHASE_ORDER(order.purchaseOrderIdERP)}
              className="stretched-link"
              target="_blank"
            >
              {order.purchaseOrderId}
            </a>
          </div>
        )}
        {order.orderNumberShopify && (
          <div>
            <b>Shopify #: </b>
            <a
              href={GET_SHOPIFY_LINK(ecomSite, order.idShopify)}
              className="stretched-link"
              target="_blank"
            >
              {order.orderNumberShopify}
            </a>
          </div>
        )}
      </div>
    )} */}
    {order.comments && (
      <p className="whitespace-pre-wrap break-words font-medium">
        {order.comments}
      </p>
    )}
  </div>
);

export default GeneralInfo;
