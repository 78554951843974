import queryString from 'query-string';

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ordoxBaseQuery } from './baseQuery';

export type DeliverySlot = {
  id: string;
  companyId: string;
  startDateTime: string;
  endDateTime: string;
  capacity: number;
  usedCapacity: number;
  createdAt: string;
  updatedAt: string;
};

type FindDeliverySlotRequest = {
  orderBy: string;
  startDateTime: Date;
};

type FindDeliverySlotResponse = {
  total: number;
  items: DeliverySlot[];
};

export const deliverySlotApi = createApi({
  reducerPath: 'deliverySlot-api',
  baseQuery: ordoxBaseQuery(),
  endpoints: builder => ({
    findDeliverySlots: builder.query<FindDeliverySlotResponse, FindDeliverySlotRequest>({
      query: (filters) => {
        const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
        
        return {
          url: `delivery-slots${params ? `?${params}` : ''}`,
          method: 'GET',
        }
      },
    }),
  }),
});

export const { 
  useFindDeliverySlotsQuery,
} = deliverySlotApi;

export default deliverySlotApi;
