import store from '../store';
import { ERP, ERPConfig } from './Constants';

export const roundResult = result => {
  const { erp: currentERP } = store.getState().ordox.company;
  const { rounding } = ERPConfig[currentERP];
  return parseFloat(result.toFixed(rounding));
};

// export const InvoiceLink = ({ order }) => {
//   const { erp: currentERP } = store.getState().ordox.company;
//   if (currentERP === ERP.alegra) {
//     return (
//       <div>
//         <b>Factura: </b>
//         <a
//           href={GET_ALEGRA_INVOICE(order.invoiceIdERP)}
//           className="stretched-link"
//           target="_blank"
//           rel="noreferrer"
//         >
//           {order.invoiceId}
//         </a>
//       </div>
//     );
//   } else if (currentERP === ERP.siigo) {
//     return (
//       <div>
//         <b>Factura: </b>
//         {order.invoiceId}
//       </div>
//     );
//   }
//   return <div></div>;
// };

export const openPDF = (pdf, erp, options) => {
  if (erp === ERP.alegra) {
    window.open(pdf.url, options);
  } else if (erp === ERP.siigo) {
    decodePDF(pdf.base64);
  }
};

const decodePDF = (data, options) => {
  const base64EncodedPDF = data;
  const decodedPdfContent = atob(base64EncodedPDF);
  const byteArray = new Uint8Array(decodedPdfContent.length);
  for (let i = 0; i < decodedPdfContent.length; i++) {
    byteArray[i] = decodedPdfContent.charCodeAt(i);
  }
  var blob = new Blob([byteArray.buffer], { type: 'application/pdf' });
  var _pdfurl = URL.createObjectURL(blob);
  window.open(_pdfurl, options);
};
