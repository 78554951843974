import { all, call, put, takeLatest } from "redux-saga/effects";
import { Message, toaster } from "rsuite";
import * as actions from "../actions";
import types from "../actions/ActionTypes";
import api from "../api/DeliverySlotApi";
import * as Constants from "../utils/Constants";
import { mapDeliverySlots } from "../utils/Mappers";
import { mapDeliverySlot } from "../utils/Mappers/MasterDataMappers";
import {
  BATCH_PROCESSING_LIMIT_EXCEEDED,
  DELIVERY_SLOT_SUCCESS_UPDATED,
  GENERAL_ERROR_MESSAGE,
  PREVIEW_SUCESS_DELIVERY_SLOTS,
  SUCESS_DELIVERY_SLOTS,
} from "../utils/Notifications";
import {
  convertExcelValues,
  validateSlots,
} from "../utils/Validations/DeliverySlotValidations";

function* getDeliverySlots(action) {
  let deliverySlots,
    mappedSlots = [];

  const filter = { ...action.payload };
  deliverySlots = yield call(api.getDateDeliverySlots, filter);
  mappedSlots = mapDeliverySlots(deliverySlots.data);

  yield put(actions.updateStateDeliverySlots(mappedSlots));
  yield put(actions.setShowSpinner(false));
}

function* createDeliverySlots(action) {
  let totalSlots = action.payload.totalSlots;
  let addedSlot;
  let index;
  totalSlots.shift();

  const convertedSlots = convertExcelValues(totalSlots);

  const validatedSlots = validateSlots(convertedSlots);
  const validSlots = validatedSlots.valid;
  const backgroundProcessing =
    validSlots.length > Constants.MAX_LENGTH_BACKGROUND_PROCESSING;

  if (validSlots.length < Constants.MAX_BATCH_PROCESSING) {
    if (validatedSlots.invalid) {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={Constants.ERROR_NOTIFICATION_DURATION}
        >
          {validatedSlots.invalid}
        </Message>
      );
    }

    if (backgroundProcessing) {
      toaster.push(
        <Message
          type="success"
          showIcon
          closable
          duration={Constants.NOTIFICATION_SUCESS_QUICK_DURATION}
        >
          {validSlots.length + " " + PREVIEW_SUCESS_DELIVERY_SLOTS}
        </Message>
      );
      action.callback({ orderBy: 'startDateTime:DESC' });
    }

    try {
      for (index = 0; index < validSlots.length; index++) {
        // if slots load is not too large, then block user actions
        if (!backgroundProcessing) {
          yield put(actions.setShowSpinner(true));
        }
        addedSlot = {};
        addedSlot.startDateTime = new Date(validSlots[index]['start']);
        addedSlot.endDateTime = new Date(validSlots[index]['end']);
        yield call(api.createDeliverySlot, addedSlot);
      }

      if (validSlots.length > 0) {
        toaster.push(
          <Message
            type="success"
            showIcon
            closable
            duration={Constants.NOTIFICATION_SUCESS_QUICK_DURATION}
          >
            {validSlots.length + ' ' + SUCESS_DELIVERY_SLOTS}
          </Message>,
        );
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        console.log(error.response.data.message);
      } else {
        toaster.push(
          <Message
            type="error"
            showIcon
            closable
            duration={Constants.NOTIFICATION_DURATION}
          >
            {GENERAL_ERROR_MESSAGE} : {error?.response?.data.message}
          </Message>,
        );
      }
      console.error('oups, an error has occured!', error);
    } finally {
      yield put(actions.setShowSpinner(false));
      if (action.callback && !backgroundProcessing) {
        action.callback({ orderBy: 'startDateTime:DESC' });
      }
    }
  } else {
    toaster.push(
      <Message
        type="warning"
        showIcon
        closable
        duration={Constants.NOTIFICATION_SUCESS_QUICK_DURATION}
      >
        {BATCH_PROCESSING_LIMIT_EXCEEDED}
      </Message>
    );
  }
}

function* searchDeliverySlots(action) {
  try {
    yield put(actions.setShowSpinner(true));
    const response = yield call(api.searchDeliverySlots, action.payload);
    yield put(
      actions.updateDeliverySlotsPaging({
        name: 'totalRows',
        value: response.data.total,
      }),
    );

    yield put(
      actions.updateDeliverySlotsGrid(mapDeliverySlots(response.data.items)),
    );
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* updateDeliverySlot(action) {
  try {
    yield put(actions.setShowSpinner(true));
    const { id, ...updatedSlot } = mapDeliverySlot(action.payload);
    yield call(api.editDeliverySlot, id, updatedSlot);

    yield put(actions.setEditedSlotVisible(false));
    toaster.push(
      <Message
        type="success"
        showIcon
        closable
        duration={Constants.NOTIFICATION_DURATION}
      >
        {DELIVERY_SLOT_SUCCESS_UPDATED}
      </Message>,
    );
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={Constants.NOTIFICATION_DURATION}
        >
          {GENERAL_ERROR_MESSAGE} : {error?.response?.data.message}
        </Message>,
      );
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
    if (action.callback) {
      action.callback({ orderBy: 'startDateTime:DESC' });
    }
  }
}

export default function* () {
  yield all([
    takeLatest(types.GET_DELIVERY_SLOTS, getDeliverySlots),
    takeLatest(types.CREATE_DELIVERY_SLOTS, createDeliverySlots),
    takeLatest(types.SEARCH_DELIVERY_SLOTS, searchDeliverySlots),
    takeLatest(types.UPDATE_DELIVERY_SLOT, updateDeliverySlot),
  ]);
}
