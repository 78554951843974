import axios from '../libs/axios';
import queryString from 'query-string';

const api = {
  getStatus(filters) {
    const params = queryString.stringify(filters)
    return axios.get(`status${params && `?${params}`}`)
  },
};
export default api;
