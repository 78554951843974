import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Panel } from 'rsuite';

export type InvoiceNotesProps = {
  className?: string;
  agentNotes: string;
  invoiceNotes: string;
  incomingPurchaseOrder: string;
  onChange?(
    ev: React.ChangeEvent<HTMLInputElement>,
    name: 'agentNotes' | 'invoiceNotes' | 'incomingPurchaseOrder',
    value: string,
  ): void;
};

const InvoiceNotes: React.FC<InvoiceNotesProps> = ({
  className,
  invoiceNotes,
  agentNotes,
  incomingPurchaseOrder,
  onChange,
}) => {
  const { $t } = useIntl();

  return (
    <Panel
      className={className}
      header={
        <div className="flex justify-between">
          <span className="font-medium">
            <FormattedMessage id="notes" />
          </span>
        </div>
      }>
      <Input
        className="mb-2"
        as="textarea"
        rows={3}
        placeholder={$t({ id: 'notes' })}
        value={agentNotes}
        name="notes"
        onChange={(value, ev) => onChange?.(ev, 'agentNotes', value)}
      />
      <Input
        as="textarea"
        className="mb-2"
        rows={3}
        placeholder={$t({ id: 'billing-notes' })}
        value={invoiceNotes}
        name="invoiceNotes"
        onChange={(value, ev) => onChange?.(ev, 'invoiceNotes', value)}
      />
      <Input
        placeholder={$t({ id: 'incoming-purchase-order' })}
        value={incomingPurchaseOrder}
        name="incomingPurchaseOrder"
        maxLength={20}
        onChange={(value, ev) => onChange?.(ev, 'incomingPurchaseOrder', value)}
      />
    </Panel>
  );
};

export default InvoiceNotes;
