import queryString from 'query-string';
import axios from '../libs/axios';

const api = {
  async getTrackingFullData(orderId, filters = {}) {
    const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
    return await axios.get(`orders-tracking/orders/${orderId}${params && `?${params}`}`)
  },
};

export default api;
