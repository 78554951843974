import axios from 'axios';
import queryString from 'query-string';
import { API_BACKEND_URL } from '../utils/Constants';

const api = {
  async getDateDeliverySlots(filter) {
    return await axios
      .post(API_BACKEND_URL + 'deliveryslot/findDate', filter)

      .then(response => {
        return {
          status: response.status,
          data: response.data,
        };
      })

      .catch(error => {
        if (error.response) {
          return {
            status: error.response.status,
            data: error.response.data,
          };
        }
      });
  },

  searchDeliverySlots(filters = {}) {
    const params = queryString.stringify(filters)
    return axios.get(`delivery-slots${params && `?${params}`}`)
  },

  editDeliverySlot(id, deliverySlot) {
    return axios.patch(`delivery-slots/${id}`, deliverySlot)
  },
};
export default api;
