import { resetNewOrder } from '@actions/index';
import { Customer } from '@api/query/customerApi';
import { useCreateOrderMutation, useUpdateOrderMutation } from '@api/query/orderApi';
import { ListPriceProductItem, Product } from '@api/query/productApi';
import CustomerBrowser from '@components/Customer/CustomerBrowser';
import PageHeader from '@components/PageHeader';
import ProductBrowser from '@components/Product/ProductBrowser';
import FormattedCurrency from '@components/ui/FormattedCurrency';
import { NewOrderState } from '@reducers/initialState';
import { ArrowDown as ArrowDownIcon } from '@rsuite/icons';
import { getNewEditOrder } from '@selectors/CreateOrderSelector';
import { getStatusList } from '@selectors/StatusSelector';
import combineWith, { stop } from '@utils/combine';
import * as MasterDataSelector from '../../selectors/MasterDataSelector';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {
  getMainLoggingParams,
  selectCompanyId,
  getActiveUser,
} from '@selectors/SystemSelector';
import dayjs from '@utils/dayjs';
import { isFetchBaseQueryError } from '@utils/queryUtils';
import useViewport from '@utils/useViewport';
import { differenceBy } from 'lodash';
import pick from 'lodash.pick';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Dropdown,
  IconButton,
  Message,
  Panel,
  Popover,
  useToaster,
  Whisper
} from 'rsuite';
import AdditionalInfo, { AdditionalInfoFormValues } from './AdditionalInfo';
import InvoiceNotes from './InvoiceNotes';
import { rollbarErrors } from '@utils/rollbarErrors';

type CreateOrderProps = {
  fulfillmentTypesList
};

const CreateOrder: React.FC<CreateOrderProps> = ({
  fulfillmentTypesList,
}) => {

  const history = useHistory();
  const toaster = useToaster();
  const dispatch = useDispatch();

  const [triggerCreateOrder, createOrderState] = useCreateOrderMutation({});
  const [triggerUpdateOrder, updateOrderState] = useUpdateOrderMutation({});

  const [bodyUpdate, setBodyUpdate] = useState<{}>();
  const [bodyCreate, setBodyCreate] = useState<{}>();
  const companyId = useSelector(selectCompanyId);
  const { customer: currentCustomer, deliveryHoursAhead, automaticDeliveryDateTimeSet, user } = useSelector(getMainLoggingParams);

  useEffect(() => {
    if (updateOrderState?.isError) {
      rollbarErrors(updateOrderState?.error, bodyUpdate, 'PATCH/orders', companyId, user)
    }
  }, [updateOrderState?.isError]);

  useEffect(() => {
    if (createOrderState?.isError) {
      rollbarErrors(createOrderState?.error , bodyCreate, 'POST/orders', companyId, user)
    }
  }, [createOrderState?.isError]);

  const orderActive: NewOrderState = useSelector(getNewEditOrder);
  const [orderId, setOrderId] = useState<string>();
  const [orderNumber, setOrderNumber] = useState<number>();
  const [productError, setProductError] = useState<boolean>(false);
  const activeUser = useSelector(getActiveUser);

  const InitialFormValues: AdditionalInfoFormValues = {
    deliveryDate: new Date(),
    deliverySlot: [],
    deliverySlotId: '',
    invoicingSettingId: '',
    channelId: '',
    fulfillmentTypeId: '',
    fulfillmentType: {
      id: '',
      description: '',
      idDelivery: '',
      deliveryType: '',
      companyId: '',
    },
    paymentMethodId: currentCustomer.paymentMethodId,
    paymentMethod: pick(currentCustomer.paymentMethod, ['id', 'description', 'idERP']),
    channel: {
      id: '',
      description: ''
    },
    actionOnShipment: '',
  };


  const [mode, setMode] = useState<'create' | 'edit'>('create');

  const [initialProduct, setInitialProduct] = useState<Product[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [productsOrder, setProductsOrder] = useState<Product[]>([]);
  const [productIsShippingItem, setProductIsShippingItem] = useState<Product>();
  const [listPriceProducts, setListPriceProducts] = useState<ListPriceProductItem[]>([]);

  useEffect(() => {
    if (listPriceProducts?.length && productsOrder?.length) {
      const newProducts = []
      combineWith(productsOrder, listPriceProducts, (l, r) => {
        if (l.companyId === r.companyId && l.productId === r.product?.id) {
          l.minQuantity = r?.minQuantity
          if (r?.isShippingItem) {
            l.isShippingItem = r?.isShippingItem
          }
          newProducts.push(l)
          stop();
        }
      });
      setProducts(newProducts);
      setInitialProduct(newProducts);
    }
  }, [listPriceProducts, orderActive]);

  const [customer, setCustomer] = useState<Customer>();

  const handleCustomerChange = useCallback(
    (action: SetStateAction<Customer>) => {
      setCustomer(prevCustomer => {
        const customer =
          typeof action === 'function' ? action(prevCustomer) : action;
        if (!customer) return customer;
        setAdditionalInfo(values => ({
          ...values,
          invoicingSettingId: customer.invoicingSettingId,
          channelId: customer.channelId,
          actionOnShipment: customer.actionOnShipment,
          fulfillmentType: customer?.fulfillmentType,
          fulfillmentTypeId: customer?.fulfillmentType?.id,
        }));
        return customer;
      });
    },
    [setCustomer],
  );

  const [invoiceNotes, setInvoiceNotes] = useState('');
  const [comments, setComments] = useState('');
  const [incomingPurchaseOrder, setIncomingPurchaseOrder] = useState('');
  const [subTotal, setSubtotal] = useState(0);

  const [additionalInfo, setAdditionalInfo] = useState(InitialFormValues);
  useEffect(() => {
    if ((orderActive?.items?.length || 0) === 0) return;

    const {
      id,
      orderNumber,
      customer,
      actionOnShipment,
      comments,
      channelId,
      invoicingSettingsId,
      paymentMethodId,
      promisedDeliveryDateTime,
      deliverySlotId,
      paymentMethod,
      channel,
      fulfillmentTypeId,
      fulfillmentType,
      incomingPurchaseOrder
    } = orderActive;

    if (orderActive.action === 'EDIT') {
      setMode('edit');
      setOrderId(id);
      setOrderNumber(orderNumber);
      setAdditionalInfo({
        actionOnShipment,
        channelId,
        deliveryDate: new Date(promisedDeliveryDateTime),
        deliverySlot: [],
        deliverySlotId,
        invoicingSettingId: invoicingSettingsId,
        fulfillmentTypeId: fulfillmentTypeId,
        fulfillmentType: fulfillmentType,
        paymentMethodId,
        paymentMethod,
        channel
      });
    } else {
      setAdditionalInfo({
        actionOnShipment,
        channelId,
        deliveryDate: new Date(),
        deliverySlot: [],
        deliverySlotId: '',
        invoicingSettingId: invoicingSettingsId,
        fulfillmentTypeId: fulfillmentTypeId,
        fulfillmentType: fulfillmentType,
        paymentMethodId,
        paymentMethod,
        channel
      });
    }

    const products: Product[] = orderActive.items.map(item => ({
      id: item.id,
      orderItemId: item.id,
      companyId,
      cost: 0,
      createdAt: dayjs().toISOString(),
      idERP: item.idERP,
      inventory: 0,
      name: item.item,
      priceBeforeTax: item.itemPrice,
      priceAfterTax:
        item.itemPrice + (item.itemPrice * item.itemTaxNumeric) / 100,
      tax: item.itemTaxNumeric,
      taxIdERP: item.taxIdERP,
      unit: 'uds' as Product['unit'],
      unitsCount: item.unitsCount,
      updatedAt: dayjs().toISOString(),
      discount: item.discount,
      notes: item.notes,
      productId: item?.productId,
      quantity: item.itemQuantity,
    }));
    setProductsOrder(products)
    setIncomingPurchaseOrder(incomingPurchaseOrder)
    setCustomer(customer);
    setComments(comments);
    dispatch(resetNewOrder());
  }, [orderActive]);

  const statusList: { id: string; role: string }[] = useSelector(
    getStatusList,
  );

  useEffect(() => {
    createOrderState.reset();
    return () => createOrderState.reset();
  }, []);

  useEffect(() => {
    if (products) {
      for (let i = 0; i < products?.length; i++) {
        if (products[i]?.quantity < products[i]?.minQuantity) {
          setProductError(true)
          i = products.length
        } else {
          setProductError(false)
        }
      }
    }
  }, [products]);

  useEffect(() => {
    updateOrderState.reset();
    return () => updateOrderState.reset();
  }, []);

  useEffect(() => {
    if (mode === 'create') {
      handleCustomerChange(activeUser?.customer);
    }
  }, [activeUser]);

  useEffect(() => {
    if (createOrderState.isUninitialized) return;
    if (createOrderState.isLoading) return;

    if (
      isFetchBaseQueryError(createOrderState.error) &&
      createOrderState.error.status === 409
    ) {
      toaster.push(
        <Message type={'warning'} duration={10000} closable>
          <FormattedMessage id={'slot-no-room'} />
        </Message>,
      );
      return;
    }

    toaster.push(
      <Message
        type={createOrderState.isSuccess ? 'success' : 'error'}
        duration={4000}
        closable
      >
        <FormattedMessage
          id={`${'create-order'}.${createOrderState.isSuccess ? 'success' : 'failure'
            }`}
          values={{
            orderId: (
              <b>
                #
                {createOrderState.data?.orderNumber || orderActive?.orderNumber}
              </b>
            ),
          }}
        />
      </Message>,
    );
    createOrderState.reset();
  }, [createOrderState]);

  useEffect(() => {
    if (updateOrderState.isUninitialized) return;
    if (updateOrderState.isLoading) return;

    if (
      isFetchBaseQueryError(updateOrderState.error) &&
      updateOrderState.error.status === 409
    ) {
      toaster.push(
        <Message type={'warning'} duration={7000} closable>
          <FormattedMessage id={'slot-no-room'} />
        </Message>,
      );
      return;
    }

    toaster.push(
      <Message
        type={updateOrderState.isSuccess ? 'success' : 'error'}
        duration={4000}
        closable>
        <FormattedMessage
          id={`${'edit-order'}.${updateOrderState.isSuccess ? 'success' : 'failure'
            }`}
          values={{
            orderId: (
              <b>#{updateOrderState.data?.orderNumber || orderNumber}</b>
            ),
          }}
        />
      </Message>,
    );
    updateOrderState.reset();
  }, [updateOrderState]);

  const handleCreateOrderSave = (hint: 'reset' | 'close') => {
    const { deliveryDate, deliverySlot } = additionalInfo;

    if (!customer?.id) {
      return toaster.push(
        <Message type="warning" showIcon duration={1000}>
          <FormattedMessage id="create-order.customer.error" />
        </Message>,
      );
    }

    if (!products.length) {
      return toaster.push(
        <Message type="warning" showIcon duration={1000}>
          <FormattedMessage id="create-order.products.error" />
        </Message>,
      );
    }

    if (!deliverySlot.length && !automaticDeliveryDateTimeSet) {
      return toaster.push(
        <Message type="warning" showIcon duration={3000}>
          <FormattedMessage id="create-order.deliverySlot.error" />
        </Message>,
      );
    }

    const slotDate = new Date(additionalInfo.deliverySlot[0])
    var numberOfMlSeconds = slotDate.getTime();
    const addMlSeconds = ((deliveryHoursAhead) * 60) * 60000;
    const newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
    if (new Date() > newDateObj) {
      return toaster.push(
        <Message type="warning" showIcon duration={1000}>
          <FormattedMessage id="create-order.products.slot.error" />
        </Message>,
      );
    }

    const arrayProducts = products
    const arrayDiscountShipping = []

    if (productIsShippingItem) {
      console.log('subTotal', subTotal)
      console.log('customer?.freeShippingFrom', customer?.freeShippingFrom)
      if (subTotal < customer?.freeShippingFrom) {
        let flag = false
        for (let i = 0; i < arrayProducts?.length; i++) {
          if (arrayProducts[i]?.isShippingItem) {
            flag = true
          }
        }
        if (!flag) {
          productIsShippingItem.quantity = 1
          arrayProducts.push(productIsShippingItem)
        }
      } else {
        for (let i = 0; i < arrayProducts?.length; i++) {
          if (!arrayProducts[i]?.isShippingItem) {
            console.log('!arrayProducts[i]?.isShippingItem', !arrayProducts[i]?.isShippingItem)
            arrayDiscountShipping.push(arrayProducts[i])
          }
        }
      }
    }
    let finalProducts = arrayProducts
    if (arrayDiscountShipping?.length) {
      finalProducts = arrayDiscountShipping
    }

    const {
      totalAfterTax,
      totalBeforeTax,
      totalUnits,
      discount,
    } = paymentSummaryValuesFee(finalProducts);

    const deletedItems =
      mode === 'edit'
        ? differenceBy(initialProduct, finalProducts, 'id').map(
          ({ orderItemId }) => orderItemId as string,
        )
        : [];


    const order = {
      source: 'savia',
      data: {
        actionOnShipment: additionalInfo.actionOnShipment,
        channel: {
          id: currentCustomer.channel.id,
          description: currentCustomer.channel.description
        },
        comments,
        fulfillmentType: {
          id: additionalInfo?.fulfillmentType?.id,
          description: additionalInfo?.fulfillmentType?.description,
          idDelivery: additionalInfo?.fulfillmentType?.idDelivery,
          deliveryType: additionalInfo?.fulfillmentType?.deliveryType,
        },
        customer: pick(customer, [
          'id',
          'idType',
          'fullName',
          'listPriceId',
          'identification',
          'mobile',
        ]),
        shippingAddress:
        {
          address: customer.address,
          city: customer.city,
          state: customer.state,
          primaryEmail: customer.primaryEmail,
          mobile: customer.mobile,
          name: customer.name,
          lastName: customer.lastName,
        },
        deliverySlotId: additionalInfo.deliverySlot[2],
        discount,
        invoicingSettingsId: additionalInfo.invoicingSettingId,
        items: (finalProducts as Required<Product>[]).map(product => ({
          id: mode === "edit" ? product.orderItemId as string : undefined,
          productId: product.productId || product.id,
          discount: product.discount || 0,
          priceAfterTax: product.priceAfterTax,
          priceBeforeTax: product.priceBeforeTax,
          quantity: product.quantity,
          tax: product.tax,
          taxIdERP: product.taxIdERP,
        })),
        notes: invoiceNotes,
        incomingPurchaseOrder: incomingPurchaseOrder,
        paymentMethod: {
          id: additionalInfo.paymentMethodId,
          description: additionalInfo.paymentMethod.description,
          idERP: additionalInfo.paymentMethod.idERP,
          accountIdERP: additionalInfo.paymentMethod.accountIdERP,
          receiptPaymentMethodIdERP:
            additionalInfo.paymentMethod.receiptPaymentMethodIdERP,
        },
        promisedDeliveryDateTime: dayjs(deliveryDate)
          .set('hour', dayjs(deliverySlot[1]).hour())
          .set('minutes', dayjs(deliverySlot[1]).minute())
          .set('second', 0)
          .toISOString(),
        status: pick(
          statusList.find(status => status.role === 'START'),
          ['id', 'description', 'role', 'listingOrder'],
        ),
        totalAfterTax,
        totalBeforeTax,
        totalUnits,
      },
    }

    let promissed = null

    if (mode === 'edit') {
      setBodyUpdate({
        id: orderId,
        data: {
          ...order,
          deletedItems,
          data: {
            ...order.data,
            status: orderActive?.status,
            invoicingSettingsId: orderActive?.invoicingSettingsId,
          }
        }
      })
      promissed = triggerUpdateOrder({
        id: orderId,
        data: {
          ...order,
          deletedItems,
          data: {
            ...order.data,
            status: orderActive?.status,
            invoicingSettingsId: orderActive?.invoicingSettingsId,
          }
        }
      })
    } else {
      setBodyCreate(order)
      promissed = triggerCreateOrder(order)
    }

    promissed.finally(() => {
      if (hint === 'reset') {
        setOrderId(undefined);
        // setCustomer(undefined);
        setProducts([]);
        setInvoiceNotes('');
        setIncomingPurchaseOrder('');
        setComments('');
        setAdditionalInfo(({ deliveryDate, deliverySlot }) => ({
          ...InitialFormValues,
          deliveryDate,
          deliverySlot,
        }));
        setInitialProduct([]);
      } else {
        history.goBack();
      }
    });
  };

  const handleContactChange = value => {
    setCustomer(currentCustomer => {
      return { ...currentCustomer, ...value }
    })
  };

  return (
    <div className="flex flex-col h-screen">
      <PageHeader
        title={
          <>
            <FormattedMessage
              id={mode === 'create' ? 'create-order' : 'edit-order'}
            />
            {orderNumber && (
              <span className="mx-3 font-semibold">({orderNumber})</span>
            )}
          </>
        }
        right={
          <>
            <CreateOrderActions
              className="col-span-full text-right"
              onSave={handleCreateOrderSave}
              loading={mode === 'create' ? createOrderState.isLoading : updateOrderState.isLoading}
              onDiscard={() => history.goBack()}
              productError={productError}
            />
          </>
        }
      />
      <div
        className="relative overflow-auto flex-grow w-full mx-auto"
        style={{ maxWidth: 2048 }}
      >
        <div
          className="absolute top-0 left-0 right-0 
        grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-stretch justify-center items-stretch gap-x-2 gap-y-2 px-2"
        >
          <CustomerBrowser
            className="shadow-lg max-w-3xl"
            customer={customer}
            onCustomerChange={null}
            onContactChange={handleContactChange}
          />
          <AdditionalInfo
            className="shadow-lg max-w-3xl"
            customer={customer}
            onChange={setAdditionalInfo}
            value={additionalInfo}
            deliveryHoursAhead={deliveryHoursAhead}
            fulfillmentTypes={fulfillmentTypesList}
          />
          <PaymentSummary className="shadow-lg max-w-3xl" products={products} setSubtotal={setSubtotal} />
          <ProductBrowser
            className="shadow-lg md:col-span-2"
            selected={products}
            listPriceId={customer?.listPriceId}
            setProductIsShippingItem={setProductIsShippingItem}
            onSelectedChange={items => setProducts(items)}
            setListPriceProducts={setListPriceProducts}
          />

          <InvoiceNotes
            className="shadow-lg max-w-3xl"
            agentNotes={comments}
            invoiceNotes={invoiceNotes}
            incomingPurchaseOrder={incomingPurchaseOrder}
            onChange={(ev, name) => {
              if (name === 'invoiceNotes') {
                setInvoiceNotes(ev.currentTarget.value);
              } else if (name === 'incomingPurchaseOrder') {
                setIncomingPurchaseOrder(ev.currentTarget.value);
              } else {
                setComments(ev.currentTarget.value);
              }
            } 
            }
          />
          <CreateOrderActions
            className="col-span-full text-right py-4"
            onSave={handleCreateOrderSave}
            loading={mode === 'create' ? createOrderState.isLoading : updateOrderState.isLoading}
            onDiscard={() => history.goBack()}
            productError={productError}
          />
        </div>
      </div>
    </div>
  );
};

type CreateOrderActionsProps = {
  className?: string;
  onSave(hint: 'reset' | 'close'): void;
  onDiscard(): void;
  loading: boolean;
  productError: boolean
};

const CreateOrderActions: React.FC<CreateOrderActionsProps> = ({
  className,
  onSave,
  onDiscard,
  loading,
  productError,
}) => {
  const { isNarrow, isWide } = useViewport();
  return (
    <div className={className}>
      <ButtonToolbar className="flex-nowrap">
        <Button className="m-1" disabled={loading} onClick={onDiscard}>
          <FormattedMessage id="discard" />
        </Button>
        <ButtonGroup className="m-1">
          <Button
            disabled={loading || productError}
            appearance="primary"
            onClick={() => onSave('close')}
          >
            <FormattedMessage id="save" />
          </Button>
          {isNarrow && (
            <Whisper
              placement="autoVerticalEnd"
              trigger={['click']}
              speaker={({ onClose, left, top, className }, ref) => (
                <Popover
                  ref={ref}
                  className={className}
                  style={{ left, top }}
                  full
                >
                  <Dropdown.Menu
                    onSelect={eventKey => {
                      onSave(eventKey as 'reset');
                      onClose();
                    }}
                  >
                    <Dropdown.Item eventKey="reset">

                      Guardar y crear otra
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Popover>
              )}
            >
              <IconButton appearance="primary" icon={<ArrowDownIcon />} />
            </Whisper>
          )}
        </ButtonGroup>
        {isWide && (
          <Button
            disabled={loading || productError}
            appearance="primary"
            onClick={() => onSave('reset')}
          >
            Guardar y crear otra
          </Button>
        )}
      </ButtonToolbar>
    </div>
  );
};

type PaymentSummaryProps = {
  className?: string;
  products: Product[];
  setSubtotal;
};

function paymentSummaryValues(products: Product[]) {
  let subtotal = products.reduce(
    (result, { priceBeforeTax, quantity }) =>
      result + priceBeforeTax * quantity,
    0,
  );
  for (let i = 0; i < products?.length; i++) {
    if (products[i]?.isShippingItem) {
      subtotal = (subtotal - products[i]?.priceBeforeTax)
    }
  }

  const discount = products.reduce(
    (result, { discount, priceBeforeTax, quantity }) =>
      result + ((discount || 0) / 100) * priceBeforeTax * quantity,
    0,
  );

  const taxes = products.reduce(
    (result, { tax, priceBeforeTax, quantity, discount }) =>
      result +
      priceBeforeTax * (1 - (discount || 0) / 100) * quantity * (tax / 100),
    0,
  );

  const totalBeforeTax = subtotal - discount;

  const totalAfterTax = totalBeforeTax + taxes;

  const totalUnits = products
    .filter(item => item.unitsCount)
    .reduce((result, { quantity }) => result + quantity, 0);

  return {
    subtotal,
    discount,
    taxes,
    totalAfterTax,
    totalBeforeTax,
    totalUnits,
  };
}
function paymentSummaryValuesFee(products: Product[]) {
  let subtotal = products.reduce(
    (result, { priceBeforeTax, quantity }) =>
      result + priceBeforeTax * quantity,
    0,
  );

  const discount = products.reduce(
    (result, { discount, priceBeforeTax, quantity }) =>
      result + ((discount || 0) / 100) * priceBeforeTax * quantity,
    0,
  );

  const taxes = products.reduce(
    (result, { tax, priceBeforeTax, quantity, discount }) =>
      result +
      priceBeforeTax * (1 - (discount || 0) / 100) * quantity * (tax / 100),
    0,
  );

  const totalBeforeTax = subtotal - discount;

  const totalAfterTax = totalBeforeTax + taxes;

  const totalUnits = products
    .filter(item => item.unitsCount)
    .reduce((result, { quantity }) => result + quantity, 0);

  return {
    subtotal,
    discount,
    taxes,
    totalAfterTax,
    totalBeforeTax,
    totalUnits,
  };
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  className,
  products,
  setSubtotal,
}) => {
  const { discount, subtotal, taxes, totalAfterTax } = paymentSummaryValues(
    products,
  );
  setSubtotal(subtotal)

  return (
    <Panel
      className={className}
      header={
        <div>
          <span className="font-medium">Resumen</span>
        </div>
      }
    >
      <div className="grid grid-cols-2 gap-2">
        <span>Subtotal</span>
        <span>
          <FormattedCurrency value={subtotal} />
        </span>

        <span>Descuento</span>
        <span>
          <FormattedCurrency value={discount} />
        </span>

        <span>Impuesto</span>
        <span>
          <FormattedCurrency value={taxes} />
        </span>

        <span className="col-span-full border-t border-gray-200 -mx-8"></span>
        <span className="font-bold">Total</span>
        <span className="font-bold">
          <FormattedCurrency className="font-bold" value={totalAfterTax} />
        </span>
      </div>
    </Panel>
  );
};

const mapStateToProps = state => {
  return {
    fulfillmentTypesList: MasterDataSelector.getFulfillmentTypesList(state),
  };
};


export default connect(mapStateToProps, actions)(CreateOrder);
