import queryString from 'query-string'

import { createApi } from '@reduxjs/toolkit/query/react';
import { ordoxBaseQuery } from './baseQuery';
import { City, State } from './types'

export type FindOrderRequest = {
  statusId?: string[];
  limit?: number;
  offset?: number;
  orderBy?: string;
  toCreatedAt?: Date,
  fromCreatedAt?: Date,
  fromPromisedDeliveryDateTime?: Date,
  toPromisedDeliveryDateTime?: Date,
};

export type OrderResponse = {
  total: number;
  items: Order[];
};

export type CreateOrderRequest = {
  source: string;
  data: {
    actionOnShipment: string;
    channel: {
      id: string;
      description: string;
    },
    comments: string;
    customer: {
      id: string;
      fullName: string;
      idType: string;
      identification: string;
    };
    shippingAddress:{
      address: string;
      city: City;
      state: State;
      mobile?: string;
      primaryEmail?: string;
      name?: string;
      lastName?: string;
    }
    deliverySlotId: string;
    discount: number;
    invoicingSettingsId: string;
    items: {
      id: string;
      discount: number;
      priceAfterTax: number;
      priceBeforeTax: number;
      productId: string;
      quantity: number;
      tax: number;
      taxIdERP: string;
    }[];
    notes: string;
    paymentMethod: {
      id: string;
      description: string;
      idERP: string;
      accountIdERP: string;
      receiptPaymentMethodIdERP: string;
    }
    promisedDeliveryDateTime: string;
    status?: {
      id: string;
      description: string;
      listingOrder: number;
      role: string;
    };
    totalAfterTax: number;
    totalBeforeTax: number;
    totalUnits: number;
  };
};

type Status = {
  id: string;
  role: string;
  description: string;
  listingOrder: number;
}

type UpdateOrderRequest = {
  id: string;
  data: {
    source: string;
    deletedItems: string[];
    data: {
      actionOnShipment: string;
      channel: {
        id: string;
        description: string;
      },
      comments: string;
      customer: {
        id: string;
        fullName: string;
        idType: string;
        identification: string;
      };
      shippingAddress:{
        address: string;
        city: City;
        state: State;
        mobile?: string;
        primaryEmail?: string;
        name?: string;
        lastName?: string;
      }
      deliverySlotId: string;
      discount: number;
      invoicingSettingsId: string;
      items: {
        id: string;
        discount: number;
        priceAfterTax: number;
        priceBeforeTax: number;
        productId: string;
        quantity: number;
        tax: number;
        taxIdERP: string;
      }[];
      notes: string;
      paymentMethod: {
        id: string;
        description: string;
        idERP: string;
        accountIdERP: string;
        receiptPaymentMethodIdERP: string;
      }
      promisedDeliveryDateTime: string;
      status?: {
        id: string;
        description: string;
        listingOrder: number;
        role: string;
      };
      totalAfterTax: number;
      totalBeforeTax: number;
      totalUnits: number;
    };
  }
};

export type UpdateStatusOrdersResponse = {
  statusId: String;
  orders: [String];
};

export type Order = {
  id: string;
  customerId: string;
  totalBeforeTax: number;
  totalAfterTax: number;
  statusId: string;
  comments: string;
  notes: string;
  incomingPurchaseOrder: string;
  paymentMethodId: string;
  promisedDeliveryDateTime: string;
  totalUnits: number;
  deliverySlotId: string;
  channelId: string;
  invoicingSettingsId: string;
  locationId: string;
  companyId: string;
  orderNumber: number;
  createdAt: string;
  updatedAt: string;
  invoiceId: string;
  invoiceIdERP: string;
  statusColor: string;
  purchaseOrderId: string;
  purchaseOrderIdERP: string;
  orderNumberShopify: number;
  actionOnShipment: string;
  tags: [
    {
      text: string;
    },
  ];
  customer: {
    id: string;
    identification: string;
    actionOnShipment: string;
    address: string;
    companyId: string;
    createdAt: string;
    fullName: string;
    idERP: string;
    idShopify: string;
    idType: string;
    lastName: string;
    listPriceId: string;
    mobile: string;
    name: string;
    paymentForm: string;
    paymentMethodId: number;
    paymentTerms: number;
    phone: string;
    preferredChannelId: number;
    preferredInvoicingSettings: number;
    primaryEmail: string;
    source: string;
    taxRegime: string;
    updatedAt: string;
  };
  status: Status;
  paymentMethod: {
    id: string;
    description: string;
    idERP: string;
    createdAt: string;
    updateAt: string;
  };
  listPrice: {
    id: string;
    description: string;
    companyId: number;
  };
};

type UpdateStatusBulk = {
  status: Status;
  orders: string[]
}

export const orderApi = createApi({
  reducerPath: 'order-api',
  tagTypes: ['order'],
  baseQuery: ordoxBaseQuery(),
  endpoints: builder => ({
    find: builder.query<OrderResponse, FindOrderRequest>({
      providesTags: ['order'],
      query: (data = {}) => {
        const params = queryString.stringify(data, { skipNull: true, skipEmptyString: true })

        return {
          url: `orders/${params ? `?${params}` : ''}`,
          method: 'GET'
        }
      }
    }),
    createOrder: builder.mutation<Order, CreateOrderRequest>({
      invalidatesTags: ['order'],
      query: data => ({
        url: 'orders',
        method: 'POST',
        body: data,
      }),
    }),
    updateOrder: builder.mutation<Order, UpdateOrderRequest>({
      invalidatesTags: ['order'],
      query: ({ id, data }) => ({
        url: `/orders/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    updateStatusOrders: builder.mutation<Order[], UpdateStatusBulk>({
      invalidatesTags: ['order'],
      query: data => ({
        url: '/orders/status/_bulk',
        method: 'PATCH',
        body: data,
      }),
    }),
  }),
});

export const {
  useFindQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useUpdateStatusOrdersMutation,
} = orderApi;

export default orderApi;
