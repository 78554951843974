import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_BACKEND_URL } from '@utils/Constants';

export type Tax = {
  id: string;
  idERP: string;
  description: string;
  percentage: number;
  companyId: string;
};

type FindTaxesRequest = {
  companyId: string;
};
type FindTaxesResponse = {
  result: Tax[];
};

type Store = {
  ordox?: {
    auth: {
      token: string;
    };
  };
};

export const taxApi = createApi({
  reducerPath: 'tax-api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BACKEND_URL}tax`,
    prepareHeaders: (headers, { getState }) => {
      const { ordox }: Store = getState();
      const token = ordox.auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    findTaxes: builder.query<Tax[], FindTaxesRequest>({
      query: ({ companyId }) => ({
        url: `find`,
        method: 'POST',
        body: { companyId },
      }),
      transformResponse: (response: FindTaxesResponse) => response.result,
    }),
  }),
});

export const { useFindTaxesQuery } = taxApi;

export default taxApi;
