import { styled } from "goober";
import React, { createContext } from "react";
import { Drawer, DrawerProps, Modal } from "rsuite";
import { ModalProps } from "rsuite/esm/Modal/Modal";

const DrawerStyled = styled(Drawer)`
  .rs-drawer-sm {
    height: auto;
  }
  .rs-drawer-content {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
  }
  .rs-drawer-header {
    padding: 8px 4px;
    border-bottom: unset;
  }
  .rs-drawer-body {
    padding: 4px 0 0;
    height: auto;
    flex: 1 1 100%;
  }
  .rs-drawer-header-close {
    position: relative;
    inset: auto;
  }
  .rs-drawer-body {
    display: flex;
    flex-flow: column nowrap;
  }
`;

const ModalStyled = styled(Modal)`
  .rs-modal-content {
    padding: 8px 0 0;
    margin-bottom: 0;
    overflow: hidden;
  }
  .rs-modal-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: unset;
    margin: 4px 20px 12px;
  }
  .rs-modal-header-close {
    position: relative;
    inset: auto;
    margin-left: auto;
    order: 10;
  }
  .rs-modal-body {
    margin: unset;
    padding: unset;
  }
`;

type Placement = DrawerProps["placement"] | "floating";

type DrawerModalProps = Omit<DrawerProps, "placement"> & {
  placement: Placement;
  title?: React.ReactNode;
  header?: React.ReactNode;
  body?: React.ReactNode;
  actions?: React.ReactNode;
  closeButton?: boolean;
  DrawerProps?: DrawerProps;
  ModalProps?: ModalProps;
};

const context = createContext<Record<"placement", Placement>>({
  placement: "floating",
});

const DrawerModal: React.FCC<DrawerModalProps> = ({
  placement,
  header,
  title,
  body,
  actions,
  closeButton,
  DrawerProps,
  ModalProps,
  ...props
}) => {
  return (
    <context.Provider value={{ placement }}>
      {placement === "floating" ? (
        <ModalStyled {...props} {...ModalProps}>
          {(title || header) && (
            <Modal.Header closeButton={closeButton}>
              {title && <Modal.Title>{title}</Modal.Title>}
              {header}
            </Modal.Header>
          )}
          {body && <Modal.Body>{body}</Modal.Body>}
          {actions && <Modal.Footer>{actions}</Modal.Footer>}
        </ModalStyled>
      ) : (
        <DrawerStyled placement={placement} {...props} {...DrawerProps}>
          {(title || header) && (
            <Drawer.Header closeButton={closeButton}>
              {title && <Drawer.Title>{title}</Drawer.Title>}
              {header}
              {actions && <Drawer.Actions>{actions}</Drawer.Actions>}
            </Drawer.Header>
          )}
          {body && <Drawer.Body>{body}</Drawer.Body>}
        </DrawerStyled>
      )}
    </context.Provider>
  );
};

export default DrawerModal;
