import channelApi from './channelApi';
import customerApi from './customerApi';
import deliverySlotApi from './deliverySlot';
import inventoryApi from './InventoryApi';
import invoicingSettingApi from './invoicingSetting';
import orderApi from './orderApi';
import paymentMethodApi from './paymentMethod';
import productApi from './productApi';
import taxApi from './taxApi';

const apis = {
  reducers: {
    [orderApi.reducerPath]: orderApi.reducer,
    [inventoryApi.reducerPath]: inventoryApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [paymentMethodApi.reducerPath]: paymentMethodApi.reducer,
    [invoicingSettingApi.reducerPath]: invoicingSettingApi.reducer,
    [channelApi.reducerPath]: channelApi.reducer,
    [deliverySlotApi.reducerPath]: deliverySlotApi.reducer,
    [taxApi.reducerPath]: taxApi.reducer,
  },
  middlewares: [
    orderApi.middleware,
    inventoryApi.middleware,
    productApi.middleware,
    customerApi.middleware,
    paymentMethodApi.middleware,
    invoicingSettingApi.middleware,
    channelApi.middleware,
    deliverySlotApi.middleware,
    taxApi.middleware,
  ],
};

export default apis;
