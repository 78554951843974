import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_BACKEND_URL } from '@utils/Constants';

export type Invoice = {
  id: string;
  idERP: string;
  description: string;
  companyId: string;
  origin: string[];
  generateStamp: boolean;
};

type FindInvoicingSettingRequest = {
  companyId: string;
};
type FindInvoicingSettingResponse = {
  result: Invoice[];
};

type Store = {
  ordox?: {
    auth: {
      token: string;
    };
  };
};

export const invoicingSettingApi = createApi({
  reducerPath: 'invoicingSetting-api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BACKEND_URL}invoicingSetting`,
    prepareHeaders: (headers, { getState }) => {
      const { ordox }: Store = getState();
      const token = ordox.auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    findInvoicingSettings: builder.query<
      Invoice[],
      FindInvoicingSettingRequest
    >({
      query: ({ companyId }) => ({
        url: `find/`,
        method: 'POST',
        body: { companyId },
      }),
      transformResponse: (response: FindInvoicingSettingResponse) =>
        response.result,
    }),
  }),
});

export const { useFindInvoicingSettingsQuery } = invoicingSettingApi;

export default invoicingSettingApi;
