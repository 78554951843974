import Fuse from "fuse.js";
import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export type UseFuzzyPattern = string | Fuse.Expression;

export type UseFuzzySearchProps<T> = Fuse.IFuseOptions<T> & {
  data: T[];
  limit?: number;
};

export type UseFuzzySearchInstance<T> = {
  data: T[];
  pattern: UseFuzzyPattern;
  patternIsEmpty: boolean;
  search(pattern: UseFuzzyPattern): void;
  searchResult: Fuse.FuseResult<T>[];
  isSearching: boolean;
};

export type UseFuszySearchMatches = Fuse.FuseResultMatch;

export type RangeTuples = readonly Fuse.RangeTuple[];

export function getMatchIndices(
  key: string,
  matches?: readonly UseFuszySearchMatches[]
): RangeTuples {
  return matches?.find((match) => match.key === key)?.indices || [];
}

const useFuzzySearch = <T>({
  data,
  limit,
  ...options
}: UseFuzzySearchProps<T>): UseFuzzySearchInstance<T> => {
  const fuseRef = useRef<Fuse<T>>();

  useEffect(() => {
    fuseRef.current = new Fuse(data, {
      ...options,
      includeMatches: true,
      shouldSort: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, ...Object.values(options)]);

  const fuse = fuseRef.current;

  const [searchResult, setSearchResult] = useState<Fuse.FuseResult<T>[]>([]);
  const [pattern, setPattern] = useState<UseFuzzyPattern>("");
  const patternDeferred = useDeferredValue<UseFuzzyPattern>(pattern);
  const [isSearching, setIsSearching] = useState(false);

  const search = useCallback((pattern: UseFuzzyPattern) => {
    setIsSearching(true);
    setPattern(pattern);
  }, []);

  useEffect(() => {
    if (patternDeferred) {
      setSearchResult(fuse.search(patternDeferred, { limit }));
    } else {
      setSearchResult([]);
    }
    setIsSearching(false);
  }, [patternDeferred, fuse, limit]);

  const patternIsEmpty = useMemo(() => {
    if (typeof patternDeferred === "string")
      return patternDeferred.trim().length === 0;

    return Object.keys(patternDeferred).length > 0;
  }, [patternDeferred]);

  return {
    data,
    pattern,
    patternIsEmpty,
    search,
    searchResult,
    isSearching,
  };
};

export default useFuzzySearch;
