import types from '@actions/ActionTypes';
import dayjs from '@utils/dayjs';
import { produce } from 'immer';
import { initialState } from './initialState';

/**
 *
 * @param {any} action
 * @returns
 */
export default function setState(state = initialState, action) {
  return produce(state, state => {
    const newState = state;

    let itemDiscount = 0;

    switch (action.type) {
      case types.SET_ODER_ITEM:
        newState.newOrder.items = newState.newOrder.items.slice();
        let orderItemPropertiesUpdated = {};
        let itemPrice = 0;

        for (let i = action.payload.fromRow; i <= action.payload.toRow; i++) {
          if (action.payload.updated.item) {
            let itemUpdated = newState.items.filter(
              item => item.value === action.payload.updated.item,
            )[0];
            itemPrice = itemUpdated.price;

            if (newState.listPrice.length > 0) {
              const selectedProduct = newState.listPrice.filter(
                item => item.productId === itemUpdated.id,
              )[0];
              if (selectedProduct) itemPrice = selectedProduct.price;
              else
                itemPrice = newState.items.find(
                  item => item.id === itemUpdated.id,
                ).price;
            }

            const quantity = newState.newOrder.items[i].itemQuantity
              ? newState.newOrder.items[i].itemQuantity
              : 1;

            const newTotal = parseFloat(
              (
                quantity *
                itemPrice *
                (1 - itemDiscount / 100) *
                (1 + itemUpdated.tax / 100)
              ).toFixed(0),
            );

            itemDiscount = itemUpdated.discount ? itemUpdated.discount : 0;
            orderItemPropertiesUpdated = {
              id: itemUpdated.id,
              id: itemUpdated.id,
              item: itemUpdated.value,
              itemPrice: itemPrice,
              itemTotal: newTotal,
              itemQuantity: quantity,
              itemTax: itemUpdated.formattedTax,
              itemTaxNumeric: itemUpdated.tax,
              idERP: itemUpdated.idERP,
              unitsCount: itemUpdated.unitsCount,
              discount: itemDiscount,
              taxIdERP: itemUpdated.taxIdERP,
            };
          } else if (action.payload.updated.itemQuantity) {
            const newTotal = parseFloat(
              (
                newState.newOrder.items[i].itemPrice *
                action.payload.updated.itemQuantity *
                (1 - newState.newOrder.items[i].discount / 100) *
                (1 + newState.newOrder.items[i].itemTaxNumeric / 100)
              ).toFixed(2),
            );
            orderItemPropertiesUpdated = {
              itemQuantity: parseFloat(action.payload.updated.itemQuantity),
              itemTotal: newTotal,
            };
          } else if (action.payload.updated.itemPrice) {
            const updatedPrice = action.payload.updated.itemPrice;
            const newTotal = parseFloat(
              (
                updatedPrice *
                newState.newOrder.items[i].itemQuantity *
                (1 - newState.newOrder.items[i].discount / 100) *
                (1 + newState.newOrder.items[i].itemTaxNumeric / 100)
              ).toFixed(0),
            );
            orderItemPropertiesUpdated = {
              itemPrice: updatedPrice,
              itemTotal: newTotal,
            };
          } else if (action.payload.updated.discount) {
            const updatedDiscount = action.payload.updated.discount;
            const newTotal = parseFloat(
              (
                newState.newOrder.items[i].itemPrice *
                newState.newOrder.items[i].itemQuantity *
                (1 - updatedDiscount / 100) *
                (1 + newState.newOrder.items[i].itemTaxNumeric / 100)
              ).toFixed(0),
            );
            orderItemPropertiesUpdated = {
              discount: action.payload.updated.discount,
              itemTotal: newTotal,
            };
          } else if (action.payload.updated.itemTax) {
            const updatedTax = action.payload.updated.itemTax;

            //valid tax values
            if (
              updatedTax == 5 ||
              updatedTax == 0 ||
              updatedTax == 19 ||
              updatedTax == 8
            ) {
              const updatedFormattedTax = updatedTax + '%';

              const selectedTax = action.payload.taxes.find(
                tax => tax.percentage == updatedTax,
              );
              const newTotal = parseFloat(
                (
                  newState.newOrder.items[i].itemPrice *
                  newState.newOrder.items[i].itemQuantity *
                  (1 - newState.newOrder.items[i].discount / 100) *
                  (1 + updatedTax / 100)
                ).toFixed(0),
              );
              orderItemPropertiesUpdated = {
                itemTaxNumeric: parseInt(updatedTax),
                itemTax: updatedFormattedTax,
                taxIdERP: selectedTax.value,
                itemTotal: newTotal,
              };
            }
          }
          newState.newOrder.items[i] = {
            ...newState.newOrder.items[i],
            ...orderItemPropertiesUpdated,
          };
        }

        return;

      case types.SHOW_ORDER_TRACKING:
        if (action.payload.action == 'ORDER_TRACKING')
          newState.orderTrackingVisible = true;

        newState.orderTracking = action.payload.data;

        return newState;

      case types.SET_ORDER_TRACKING:
        newState.orderTracking = action.payload;
        return newState;

      case types.SHOW_EXPORTED_PDF:
        newState.showExportedPDF = action.payload;
        return newState;

      case types.SET_INVOICE_CONFIRM_ACTION_VISIBLE:
        newState.invoiceConfirmActionVisible = action.payload;
        return newState;

      case types.ACTIVATE_BUTTON:
        newState.buttonDisabled = action.payload;
        return newState;

      case types.HIDE_ORDER_TRACKING:
        const hideOrderTrackingAction = action.payload;
        hideOrderTrackingAction == 'orderTracking'
          ? (newState.orderTrackingVisible = false)
          : (newState.orderExportVisible = false);
        return newState;

      case types.SHOW_UPDATE_ORDER_STATUS:
        newState.updateOrderStatusVisible = true;
        newState.orderStatusWillUpdate = action.payload;
        return newState;

      case types.UPDATE_ORDER_STATUS_STATUS_CHANGE:
        newState.updateOrderStatusStatus = action.payload;
        return newState;

      case types.UPDATE_TRACKING_COMMENTS:
        newState.trackingComments = action.payload;
        return newState;

      case types.HIDE_UPDATE_ORDER_STATUS:
        newState.updateOrderStatusVisible = false;
        return newState;

      case types.SET_CONFIRM_ORDER_ACTION_VISIBLE:
        newState.confirmOrderActionVisible = action.payload;
        return newState;

      case types.SET_SLOT_STATUS:
        newState.slotStatus = action.payload;
        return newState;

      case types.UPDATE_STATE_CUSTOMERS:
        newState.customer = action.payload;
        return newState;

      case types.UPDATE_STATE_PRODUCTS:
        newState.items = action.payload;
        return newState;

      case types.UPDATE_STATE_DELIVERY_SLOTS:
        newState.fullDeliverySlots = action.payload;

        if (newState.fullDeliverySlots.length > 0) {
          newState.newOrder.deliverySlot.startDateTime =
            action.payload[0].startDateTime;
          newState.newOrder.deliverySlot.endDateTime =
            action.payload[0].endDateTime;
          newState.newOrder.deliverySlotId = action.payload[0].value;
        }

        return newState;

      case types.LOAD_SELECTED_DATE_DELIVERY_SLOTS:
        const selectedDate = action.payload;

        // if it's a date in the past, return no slots
        // TODO: reducer should not perform this logic
        const today = dayjs();
        if (dayjs(selectedDate, 'YYYY-MM-DD').diff(today, 'days') < 0) {
          newState.newOrder.DeliverySlot.value = undefined;
          newState.selectedDateDeliverySlots = undefined;
          return newState;
        }

        const selectedDateSlots = newState.fullDeliverySlots.filter(slot =>
          dayjs(slot.endDateTime, 'YYYY-MM-DD').isSame(
            dayjs(selectedDate, 'YYYY-MM-DD'),
          ),
        );
        newState.selectedDateDeliverySlots = selectedDateSlots;

        let newOrderSelectedDate = { ...newState.newOrder };
        newOrderSelectedDate.DeliverySlot.value = undefined;
        newState.newOrder = newOrderSelectedDate;
        return newState;

      case types.UPDATE_USER_DEVICE:
        newState.isMobile = action.payload;
        return newState;

      case types.UPDATE_STATE_PAYMENT_METHODS:
        newState.paymentMethods = action.payload;
        return newState;

      case types.UPDATE_STATE_ORDERS:
        const customers = newState.customer;
        action.payload.forEach(order => {
          const customer = customers.find(x => x.value === order.CustomerId);
          order.customer = customer
            ? customer
            : {
              value: null,
              label: '',
              phone: '',
              address: '',
              email: '',
            };
        });
        newState.orders.list = action.payload;
        return newState;

      case types.UPDATE_ORDERS_PAGING:
        let pagingOrders = {};

        if (!action.payload.history) {
          pagingOrders = { ...newState.orders.paging };
          pagingOrders[action.payload.name] = action.payload.value;
          newState.orders.paging = pagingOrders;
        } else {
          pagingOrders = { ...newState.ordersHistory.paging };
          pagingOrders[action.payload.name] = action.payload.value;
          newState.ordersHistory.paging = pagingOrders;
        }
        return newState;

      case types.UPDATE_ORDERS_CHECKED_KEYS:
        newState.orders.checkedKeys = action.payload;
        const exportDisabled =
          newState.orders.checkedKeys.length > 0 ? false : true;
        newState.orders.exportDisabled = exportDisabled;
        return newState;

      case types.UPDATE_ORDERS_FILTERS:
        newState.orders.filters = {
          ...newState.orders.filters,
          ...action.payload,
        };
        return newState;

      case types.UPDATE_ORDERS_HISTORY_FILTERS:
        let newOrdersHistoryFilters = action.payload;
        let ordersHistoryFilters = { ...newState.ordersHistory.filters };

        newOrdersHistoryFilters.forEach(filter => {
          Array.isArray(filter.value)
            ? (ordersHistoryFilters[filter.name] = filter.value)
            : (ordersHistoryFilters[filter.name] = filter.value);
        });

        newState.ordersHistory.filters = ordersHistoryFilters;
        return newState;

      case types.UPDATE_ORDERS_FIRST_LOAD:
        newState.orders.isFirstLoad = action.payload;
        return newState;

      case types.UPDATE_ORDERS_GRID_ROWS:
        // const newOrdersGridRows = [...action.payload.orders];
        const newOrdersGridRows = action.payload.orders;

        !action.payload.history
          ? (newState.orders.list = newOrdersGridRows)
          : (newState.ordersHistory.list = newOrdersGridRows);

        return newState;

      case types.UPDATE_STATE_STATUS:
        newState.status = action.payload;
        return newState;

      case types.UPDATE_LOADING:
        newState.loadingVisible = action.payload;
        return newState;

      case types.ADD_ROWS_GRID_ITEMS:
        const newRow = {
          id: null,
          item: '',
          itemPrice: '',
          itemDiscount: 0,
          itemTax: '',
          itemQuantity: '',
          itemTotal: '',
          delete: 'x',
        };
        let rows = [...newState.newOrder.items];
        rows.splice(rows.length, 0, newRow);
        newState.newOrder.items = rows;
        return newState;

      case types.DELETE_ROWS_GRID_ITEMS:
        const deletedRow = newState.newOrder.items[action.payload];
        newState.newOrder.deletedItems.push(deletedRow.id);
        let actualRows = [...newState.newOrder.items];
        actualRows.splice(action.payload, 1);
        newState.newOrder.items = actualRows;
        return newState;

      case types.CLEAR_ORDER_ITEMS:
        const clearedItems = [];
        newState.newOrder.items = clearedItems;
        return newState;

      case types.LOAD_EDIT_ORDER:
        // const actionOnShipment = ACTIONS_ONSHIPMENT.find(
        //   (x) => x.value === action.payload.actionOnShipment.value
        // );

        newState.customer = action.payload.customer;
        action.payload.currentStatusId = action.payload.statusId;
        action.payload.StatusId = action.payload.statusId;

        // build up all delivery slot object
        action.payload.deliverySlot.value = action.payload.deliverySlot.id;

        action.payload.Channel = {
          value: action.payload.channelId,
        };
        action.payload.InvoicingSettings = {
          value: action.payload.invoicingSettingsId,
        };
        newState.newOrder = action.payload;
        return newState;

      case types.COPY_ORDER:
        // const paymentMethodCopy = newState.paymentMethods.find(
        //   (x) => x.description === action.payload.PaymentMethod
        // );
        // const channelCopy = newState.masterData.channels.find(
        //   (x) => x.value === action.payload.ChannelId
        // );
        // const invoicingSettingCopy = newState.masterData.invoicingSettings.find(
        //   (x) => x.value === action.payload.InvoicingSettingsId
        // );
        // const invoiceOnShipmentCopy = ACTIONS_ONSHIPMENT.find(
        //   (x) => x.value === action.payload.InvoiceOnShipment
        // );

        // const customerMasterCopy = newState.customer.find(
        //   (x) => x.value === action.payload.customer.value
        // );
        // action.payload.customer.idERP = customerMasterCopy.idERP;

        // action.payload.items.forEach((item) => {
        //   const itemMaster = newState.items.find((x) => x.id === item.id);
        //   item.idERP = itemMaster.idERP;
        // });

        // // build up all delivery slot object
        // newState.selectedDateDeliverySlots = newState.fullDeliverySlots.filter(
        //   (slot) =>
        //     dayjs(slot.endDateTime, "YYYY-MM-DD").isSame(
        //       dayjs(action.payload.PromisedDeliveryDate, "YYYY-MM-DD"),
        //       "date"
        //     )
        // );

        // action.payload.Channel = {
        //   value: channelCopy.value,
        //   label: channelCopy.label,
        // };
        // action.payload.InvoicingSettings = {
        //   value: invoicingSettingCopy.value,
        //   label: invoicingSettingCopy.label,
        // };
        // action.payload.ActionOnShipment = invoiceOnShipmentCopy;

        newState.newOrder = action.payload;
        return newState;

      case types.UPDATE_SELECTED_CUSTOMER:
        let newOrder = { ...newState.newOrder };
        if (action.payload) {
          const pickedCustomer = newState.customer.find(
            item => item.value == action.payload.value,
          );
          newOrder.customer = pickedCustomer
            ? pickedCustomer
            : {
              value: undefined,
              label: '',
              phone: '',
              address: '',
              email: '',
              paymentMethodId: undefined,
            };
          newState.newOrder = newOrder;
        } else {
          newOrder.customer = {
            value: null,
            label: '',
            phone: '',
            address: '',
            email: '',
            paymentMethodId: undefined,
          };
          newState.newOrder = newOrder;
        }

        return newState;

      case types.UPDATE_SELECTED_PAYMENT_METHOD:
        newState.newOrder.paymentMethodId = action.payload
          ? action.payload.value
          : null;
        newState.newOrder.paymentMethod.description = action.payload
          ? action.payload.label
          : null;
        newState.newOrder.paymentMethod.idERP = action.payload
          ? action.payload.idERP
          : null;
        return newState;

      case types.UPDATE_SELECTED_DELIVERY_SLOT:
        newState.newOrder.deliverySlotId = action.payload.value;
        newState.newOrder.deliverySlot.value = action.payload.value;
        newState.newOrder.deliverySlot.startDateTime =
          action.payload.startDateTime;
        newState.newOrder.deliverySlot.endDateTime = action.payload.endDateTime;
        return newState;

      case types.UPDATE_SELECTED_PRICE_LIST:
        let newListPrice = { ...newState.listPrice };

        if (action.payload != null) newListPrice = action.payload.data;

        newState.listPrice = newListPrice;
        return newState;

      case types.CHANGE_ORDER_STATUS:
        let newOrderStatus = { ...newState.newOrder };
        newOrderStatus.StatusId = action.payload;
        newState.newOrder = newOrderStatus;
        return newState;

      case types.CHANGE_ORDER_COMMENTS:
        let newOrderComments = { ...newState.newOrder };
        newOrderComments.comments = action.payload;
        newState.newOrder = newOrderComments;
        return newState;

      case types.CHANGE_NOTES:
        newState.newOrder.notes = action.payload;
        return newState;

      case types.CHANGE_ORDER_PAYMENT_METHOD:
        newState.newOrder.paymentMethodId = action.payload.value;
        newState.newOrder.paymentMethod.description = action.payload.label;
        return newState;

      case types.UPDATE_SELECTED_CHANNEL:
        newState.newOrder.channelId = action.payload
          ? action.payload.value
          : null;
        // newState.newOrder.paymentMethod.description = action.payload.label;
        return newState;

      case types.UPDATE_SELECTED_INVOICING_SETTINGS:
        newState.newOrder.invoicingSettingsId = action.payload
          ? action.payload.value
          : null;
        return newState;

      case types.UPDATE_SELECTED_ACTION_ON_SHIPMENT:
        newState.newOrder.actionOnShipment = action.payload
          ? action.payload.value
          : null;
        return newState;

      case types.CHANGE_ORDER_PROMISED_DELIVERY_DATE:
        let newOrderPromisedDeliveryDate = { ...newState.newOrder };
        newOrderPromisedDeliveryDate.PromisedDeliveryDate = action.payload;
        newState.newOrder = newOrderPromisedDeliveryDate;
        return newState;

      case types.SET_SHOW_SPINNER:
        newState.showSpinner = action.payload;
        return newState;

      // security-related actions
      case types.UPDATE_USER_AUTHORIZATION:
        let newUserLogin = { ...newState.activeUser };
        newUserLogin.id = action.payload.id;
        newUserLogin.name = action.payload.name;
        newUserLogin.email = action.payload.email;
        newUserLogin.authorized = action.payload.authorized;
        newUserLogin.roleId = action.payload.roleId;
        newUserLogin.locationId = action.payload.locationId;
        newUserLogin.listDelimiter = action.payload.listDelimiter;
        newUserLogin.decimalSeparator = action.payload.decimalSeparator;
        newUserLogin.companyId = action.payload.companyId;
        newUserLogin.customer = action.payload.customer;
        newState.overallParams.companyId = action.payload.companyId;
        newState.overallParams.customerId = action.payload.customerId;
        newState.overallParams.customer = action.payload.customer;
        newState.activeUser = newUserLogin;
        newState.company.id = action.payload.companyId;
        newState.company.name = action.payload.companyName;
        newState.company.erp = action.payload.erp;
        newState.company.automaticDeliveryDateTimeSet = action.payload.automaticDeliveryDateTimeSet
        newState.company.choosePaymentMethod = action.payload.choosePaymentMethod
        newState.company.showDeliveryDate = action.payload.showDeliveryDate
        newState.company.deliveryHoursAhead = action.payload.deliveryHoursAhead
        return newState;

      case types.RESET_FULL_STATE:
        return { ...initialState };

      case types.LOG_OUT_USER:
        return initialState;

      case types.SET_ORDER_FILTERS:
        newState.ordersFilters = action.payload;
        return newState;

      case types.RESET_NEW_ORDER:
        let newOrderReset = { ...initialState.newOrder };
        newState.newOrder = newOrderReset;
        return newState;

      case types.LAST_UPDATED_ORDERS_GRID:
        newState.lastUpdateOrdersGrid = action.payload;
        return newState;

      case types.SET_DASHBOARD_DATA:
        const data = action.payload;
        newState.mainOpsDashboard.currentMonthOnTime =
          data.currentMonthOnTime.currentMonthOnTime;
        newState.mainOpsDashboard.currentMonthOnTimeOrders =
          data.currentMonthOnTime.currentMonthOnTimeOrders;
        newState.mainOpsDashboard.currentMonthOffTimeOrders =
          data.currentMonthOnTime.currentMonthOffTimeOrders;

        newState.mainOpsDashboard.totalUnits =
          data.currentMonthOnTime.totalUnits;
        newState.mainOpsDashboard.totalOrders =
          data.currentMonthOnTime.totalOrders;
        newState.mainOpsDashboard.totalAmount =
          data.currentMonthOnTime.totalAmount;

        newState.mainOpsDashboard.dailyOrdersSeries =
          data.currentMonthOnTime.dailyOrdersSeries;
        newState.mainOpsDashboard.dailyOrdersData =
          data.currentMonthOnTime.dailyOrdersData;
        newState.mainOpsDashboard.topCustomersData = data.customerData.map(
          value => value.totalUnits,
        );
        newState.mainOpsDashboard.topCustomersSeries = data.customerData.map(
          value => value.customerName,
        );
        return newState;

      case types.SET_LIVE_STATUS_DATA:
        const LiveStatusData = action.payload;
        newState.mainOpsDashboardLiveStatus.ordersSet =
          LiveStatusData.firstRow.ordersSet;
        newState.mainOpsDashboardLiveStatus.amountsSet =
          LiveStatusData.firstRow.amountsSet;
        newState.mainOpsDashboardLiveStatus.unitsSet =
          LiveStatusData.firstRow.unitsSet;
        return newState;

      case types.SET_DASHBOARD_CONTROLLERS:
        const controllers = action.payload;
        newState.mainOpsDashboardController.buttonGroupStatus =
          controllers.buttonGroupStatus;
        newState.mainOpsDashboardController.lastUpdate = controllers.lastUpdate;
        newState.mainOpsDashboardController.currentSelection =
          controllers.currentSelection;
        return newState;

      case types.UPDATE_TOPTEN_DIMENSION:
        if (newState.mainOpsDashboardController.topTenDimension) {
          newState.mainOpsDashboardController.topTenDimension = action.payload;
        } else {
          window.location.href = '/logout';
        }

        return newState;

      case types.SET_CUSTOMER_ID_TYPE:
        newState.customerIdType = action.payload;
        return newState;

      case types.SET_PRODUCTION_LIST_DATA:
        newState.productionList = action.payload;
        return newState;

      case types.SET_PRODUCTION_GRID_FILTERS:
        newState.productionListGridFilter = action.payload;
        return newState;

      case types.SET_PRODUCTION_LINE_VIEW:
        newState.productionListView = action.payload;
        return newState;

      case types.UPDATE_STATES:
        newState.masterData.statesList = action.payload;
        return newState;

      case types.UPDATE_SELECTED_STATE_CITIES:
        newState.masterData.selectedStateCities = action.payload;
        return newState;

      case types.SET_FOUND_STATES:
        newState.masterData.foundStates = action.payload;
        return newState;

      case types.SET_FOUND_CITIES:
        newState.masterData.foundCities = action.payload;
        return newState;

      case types.SET_CITIES_DISABLED:
        newState.masterData.citiesDisabled = action.payload;
        return newState;

      case types.UPDATE_CHANNELS:
        newState.masterData.channels = action.payload;
        return newState;

      case types.UPDATE_INVOICING_SETTINGS:
        newState.masterData.invoicingSettings = action.payload;
        return newState;

      case types.UPDATE_TAXES:
        newState.masterData.taxes = action.payload;
        return newState;

      case types.UPDATE_FULFILLMENT_TYPES:
        newState.fulfillmentTypes = action.payload;
        return newState;

      case types.UPDATE_STATE_TAGS:
        newState.masterData.tags.list = action.payload;
        return newState;

      case types.SET_UPDATE_TAGS_VISIBLE:
        newState.masterData.tags.visible = action.payload;
        return newState;

      case types.UPDATE_SELECTED_TAGS:
        if (action.payload.orderId)
          newState.masterData.tags.orderId = action.payload.orderId;
        newState.masterData.tags.selectedTags = action.payload.tags;
        return newState;

      case types.UPDATE_FULL_LIST_PRICE:
        newState.masterData.listPrice = action.payload;
        return newState;

      case types.UPDATE_FORM_ACTION:
        if (action.payload.form === 'ORDER') {
          newState.orderCaptureAction = action.payload.action;
        } else if (action.payload.form === 'CUSTOMER') {
          newState.customerCaptureAction = action.payload.action;
        }
        return newState;

      case types.UPDATE_EXPANDED_ROW_KEYS:
        newState.gridsHandling.expandedRowKeys = action.payload;
        return newState;

      case types.UPDATE_EDITED_CUSTOMER:
        newState.masterData.customers.editedCustomer = action.payload;
        return newState;

      case types.UPDATE_CUSTOMER_FILTERS:
        if (!action.payload) {
          newState.masterData.customers.filters = {
            fullName: null,
            identification: null,
            idERP: null,
            primaryEmail: null,
          };
          return newState;
        }

        const keyCustomers = Object.keys(action.payload)[0];
        Object.values(action.payload)[0]
          ? (newState.masterData.customers.filters = {
            ...newState.masterData.customers.filters,
            ...action.payload,
          })
          : delete newState.masterData.customers.filters[keyCustomers];
        return newState;

      case types.UPDATE_PRODUCT_FILTERS:
        if (!action.payload) {
          newState.masterData.products.filters = { name: '', idERP: '' };
          return newState;
        }

        const key = Object.keys(action.payload)[0];
        Object.values(action.payload)[0]
          ? (newState.masterData.products.filters = {
            ...newState.masterData.products.filters,
            ...action.payload,
          })
          : delete newState.masterData.products.filters[key];
        return newState;

      case types.UPDATE_GRID_CUSTOMERS:
        newState.masterData.customers.list = action.payload;
        return newState;

      case types.UPDATE_PRODUCTS_GRID:
        newState.masterData.products.list = action.payload;
        return newState;

      case types.SET_PENDING_INVOICING_ORDERS:
        newState.multiOrderInvoicing.selectedCustomerOrders = action.payload;
        return newState;

      case types.SET_SELECTED_CUSTOMER_MULTIORDERINVOICING:
        newState.multiOrderInvoicing.selectedCustomer = action.payload;
        return newState;

      case types.SET_MULTI_ORDER_INVOICING_CHECKED_KEYS:
        newState.multiOrderInvoicing.checkedKeys = action.payload;
        const invoiceDisabled =
          newState.multiOrderInvoicing.checkedKeys.length > 0 ? false : true;
        newState.multiOrderInvoicing.invoiceDisabled = invoiceDisabled;
        return newState;

      case types.SET_MULTI_ORDER_MODAL_VISIBLE:
        newState.multiOrderInvoicing.confirmVisible = action.payload;
        return newState;

      case types.SET_MULTI_ORDERS_INVOICE_NOTES:
        newState.multiOrderInvoicing.invoiceNotes = action.payload;
        return newState;

      case types.SET_ORDER_INVOICE_DATA:
        if (action.payload.id) {
          const invoicingData = action.payload;
          newState.invoicedOrderData.orderId = invoicingData.id;
          newState.invoicedOrderData.invoiceIdERP =
            invoicingData.invoiceData[0];
          newState.invoicedOrderData.invoiceId = invoicingData.invoiceData[1];
        } else {
          newState.invoicedOrderData = action.payload;
        }
        return newState;

      case types.SET_NEW_VERSION_AVAILABLE:
        newState.versionUpdates.newVersionAvailable = action.payload;
        return newState;

      case types.SET_WAITING_WORKER:
        newState.versionUpdates.waitingWorker = action.payload;
        return newState;

      case types.SET_FILE_UPLOADER_DATA:
        const context = action.payload.context;
        const fileUploaderData = action.payload.data;
        if (context === 'DELIVERY_SLOTS') {
          newState.deliverySlotsManagement.fileUploader[fileUploaderData.key] =
            fileUploaderData.value;
          return newState;
        } else {
          return newState;
        }

      case types.UPDATE_DELIVERY_SLOTS_PAGING:
        let pagingDeliverySlotsData = {
          ...newState.deliverySlotsManagement.paging,
        };
        pagingDeliverySlotsData[action.payload.name] = action.payload.value;
        newState.deliverySlotsManagement.paging = pagingDeliverySlotsData;
        return newState;

      case types.UPDATE_CUSTOMERS_PAGING:
        let pagingData = { ...newState.masterData.customers.paging };
        pagingData[action.payload.name] = action.payload.value;
        newState.masterData.customers.paging = pagingData;
        return newState;

      case types.UPDATE_PRODUCTS_PAGING:
        let pagingProducts = { ...newState.masterData.products.paging };
        pagingProducts[action.payload.name] = action.payload.value;
        newState.masterData.products.paging = pagingProducts;
        return newState;

      case types.UPDATE_PRODUCTS_FORM_VALUE:
        const productsFormValue = action.payload;
        newState.masterData.products.formValue.name = productsFormValue.name;
        newState.masterData.products.formValue.sku =
          productsFormValue.sku;
        newState.masterData.products.formValue.priceBeforeTax =
          productsFormValue.priceBeforeTax;
        newState.masterData.products.formValue.tax = productsFormValue.tax;
        newState.masterData.products.formValue.cost = productsFormValue.cost;
        newState.masterData.products.formValue.inventory =
          productsFormValue.inventory;
        newState.masterData.products.formValue.unit = productsFormValue.unit;
        return newState;

      case types.UPDATE_CUSTOMER_FORM_VALUES:
        const customerFormValues = action.payload;
        newState.masterData.customers.formValue = customerFormValues;
        return newState;

      case types.UPDATE_CUSTOMER_FORM_MODEL:
        newState.masterData.customers.formModel = action.payload;
        return newState;

      case types.UPDATE_PRODUCTS_FORM_ERROR:
        newState.masterData.products.formError = action.payload;
        return newState;

      case types.UPDATE_CUSTOMER_FORM_ERROR:
        newState.masterData.customers.formError = action.payload;
        return newState;

      case types.UPDATE_DELIVERY_SLOTS_GRID:
        newState.deliverySlotsManagement.gridRows = action.payload;
        return newState;

      case types.UPDATE_PROCESS_ENABLED:
        newState.deliverySlotsManagement.processEnabled = action.payload;
        return newState;

      case types.SET_EDITED_SLOT:
        newState.deliverySlotsManagement.editedSlot.ordersCapacity =
          action.payload.ordersCapacity;

        newState.deliverySlotsManagement.editedSlot.id = action.payload.id;
        newState.deliverySlotsManagement.editedSlot.companyId =
          action.payload.companyId;

        newState.deliverySlotsManagement.editedSlot.startDateTime = new Date(
          action.payload.startDateTime,
        );
        newState.deliverySlotsManagement.editedSlot.endDateTime = new Date(
          action.payload.endDateTime,
        );

        return newState;

      case types.SET_EDITED_SLOT_VISIBLE:
        newState.deliverySlotsManagement.slotModalVisible = action.payload;
        return newState;

      case types.UPDATE_STATE_ORDER_INVOICE_URL:
        newState.orders.orderInvoiceURL = action.payload;
        return newState;

      case types.UPDATE_STATE_PURCHASE_ORDER_URL:
        newState.orders.purchaseOrderURL = action.payload;
        return newState;

      case types.SHOW_SIGN_UP:
        newState.signUpVisible = action.payload;
        return newState;

      case types.SET_SIGNUP_FORM:
        newState.signupForm = action.payload;
        return newState;

      case types.UPDATE_CUSTOMER_EMAIL:
        newState.customerEmail = action.payload;
        return newState;

      case types.SET_TOKEN_DATA:
        newState.auth = action.payload;
        return newState;

      case types.CLEAR_TOKEN_DATA:
        newState.auth = action.payload;
        return newState;

      case types.OPEN_AUTH_MODAL:
        newState.authModal = action.payload;
        return newState;

      case types.CLOSE_AUTH_MODAL:
        newState.authModal = action.payload;
        return newState;

      default:
        return state;
    }
  });
}
