import dayjs from '@utils/dayjs';
import * as constants from '../utils/Constants';
import {
  formatCurrencySpanishCO,
  formatDateAndHourSpanishCO,
  formatPercentage,
  formatToDatabaseDate
} from './DataFormat.js';
import { cleanupString } from './Mappers/Tools';

export function mapOrderList(parentArray, tagsList) {
  if (!parentArray) {
    return [];
  }

  let childArray = [];
  const newArray = [...parentArray];

  childArray = newArray.map(childNode => {
    const container = {};
    container['key'] = childNode.id;
    container['orderId'] = childNode.orderId;
    container['createdAt'] = childNode.createdAt;
    container['customerId'] = childNode.customerId;
    container['customerName'] = childNode.customer.fullName;
    container['customerPhone'] = childNode.customer.mobile;
    container['customerAddress'] = childNode.customer.address;
    container['totalAfterTax'] = childNode.totalAfterTax;
    container['totalAfterTaxView'] = formatCurrencySpanishCO(
      childNode.totalAfterTax,
    );
    container['totalBeforeTax'] = childNode.totalBeforeTax;
    container['discount'] = childNode.discount;
    container['paymentMethod'] = childNode.paymentMethod.description;
    container['promisedDeliveryDateView'] = formatDateAndHourSpanishCO(
      childNode.promisedDeliveryDateTime,
    );
    container['promisedDeliveryDate'] = childNode.promisedDeliveryDateTime;
    container['status'] = childNode.status.description;
    container['statusId'] = childNode.statusId;
    container['comments'] = childNode.comments;
    container['notes'] = childNode.notes;
    container['rowId'] = childNode.id;
    container['paymentMethodId'] = childNode.paymentMethodId;
    container['paymentIdERP'] = childNode.paymentMethod.idERP;
    container['timeRemaining'] = getRemainingTime(
      childNode.promisedDeliveryDateTime,
      childNode.statusId,
      childNode.orderId,
    );
    container['invoiceId'] = childNode.invoiceId;
    container['invoiceIdERP'] = childNode.invoiceIdERP;
    container['purchaseOrderId'] = childNode.purchaseOrderId;
    container['purchaseOrderIdERP'] = childNode.purchaseOrderIdERP;
    container['deliverySlotId'] = childNode.deliverySlotId;
    container['totalUnits'] = childNode.totalUnits;
    container['actionOnShipment'] = childNode.actionOnShipment;
    container['invoicedAt'] = childNode.invoicedAt;
    container['channelId'] = childNode.channelId;
    container['invoicingSettingsId'] = childNode.invoicingSettingsId;
    container['locationId'] = childNode.locationId;
    container['listPrice'] = childNode.listPrice.description;
    container['tags'] = childNode.tags;
    container['actions'] = '';

    return container;
  });

  return childArray;
}

function getTagFullData(tags, tagsList) {
  if (tags) {
    const arrayTags = tags.split(',');
    const tagsFullData = arrayTags.map(tag => {
      return {
        value: tag,
        color: tagsList.find(item => item.value == tag).color,
      };
    });

    return tagsFullData;
  }
  return [];
}

function getRemainingTime(promiseDate, status, id) {
  if (status === constants.ENTREGADA || status === constants.CANCELADA) {
    return Number.POSITIVE_INFINITY;
  }

  const target = dayjs(promiseDate);
  const now = dayjs();
  const diff = target.diff(now, 'minutes');

  if (!diff) {
    console.log('wrong! null value');
  }
  return diff;
}

export function mapProductList(parentArray) {
  let childArray = [];
  childArray = parentArray.map(childNode => {
    const container = {};
    container['id'] = childNode.id;
    container['value'] = childNode.name;
    container['price'] = childNode.priceBeforeTax;
    container['tax'] = childNode.tax;
    container['formattedTax'] = formatPercentage(childNode.tax);
    container['idERP'] = childNode.idERP;
    container['unitsCount'] = childNode.unitsCount;
    container['title'] = childNode.name;
    container['sku'] = childNode.sku ? childNode.sku : '';
    container['formattedPrice'] = formatCurrencySpanishCO(
      childNode.priceBeforeTax,
    );
    container['taxIdERP'] = childNode.taxIdERP;
    return container;
  });

  return childArray;
}

export function mapStatesList(states) {
  let mappedStates = states.map(state => {
    const child = {};
    child['label'] = state.description;
    child['value'] = state.id;
    return child;
  });
  return mappedStates;
}

export function mapCitiesList(cities) {
  let mappedCities = cities.map(city => {
    const child = {};
    child['label'] = city.description;
    child['value'] = city.id;
    return child;
  });
  return mappedCities;
}

export const mapDeliverySlots = rawSlots => {
  let mappedSlots = [];
  mappedSlots = rawSlots.map(slot => {
    const slotItem = {};
    slotItem.value = slot.id;
    slotItem.id = slot.id;
    slotItem.companyId = slot.companyId;

    slotItem.formattedStartDateTime = formatDateAndHourSpanishCO(
      slot.startDateTime,
    );
    slotItem.startDateTime = formatToDatabaseDate(new Date(slot.startDateTime));
    slotItem.endDateTime = formatToDatabaseDate(new Date(slot.endDateTime));
    slotItem.formattedEndDateTime = formatDateAndHourSpanishCO(
      slot.endDateTime,
    );
    slotItem.label =
      dayjs(slotItem.startDateTime, 'YYYY-MM-DD HH:mm').format('h:mmA') +
      ' - ' +
      dayjs(slotItem.endDateTime, 'YYYY-MM-DD HH:mm').format('h:mmA');
    slotItem.ordersCapacity = slot.capacity;
    return slotItem;
  });

  return mappedSlots;
};

export function mapUser(user, email, name, authorized) {
  let mappedUser = {
    id: user.id,
    email: email,
    name: name,
    authorized: authorized,
    roleId: user.roleId,
    locationId: user.locationId,
    listDelimiter: user.listDelimiter,
    decimalSeparator: user.decimalSeparator,
    companyId: user.companyId,
    fullDataValidation: user.company.fullDataValidation,
    companyName: user.company.description,
    erp: user.company.erp,
    deliveryHoursAhead: user.company.deliveryHoursAhead,
    taxAccountable: user.company.taxAccountable,
    automaticDeliveryDateTimeSet : user.company.automaticDeliveryDateTimeSet,
    choosePaymentMethod: user.company.b2bPortalConfig.choosePaymentMethod,
    showDeliveryDate: user.company.b2bPortalConfig.showDeliveryDate,
    ecomSite: user.company.ecomSite,
    customerId: user.customerId,
    customer: user.customer,
  };
  return mappedUser;
}

export const mapSearchFilters = payload => {
  let fields, values;
  let searchFilter = {};

  let columns = Object.keys(payload);
  let filters = Object.values(payload);

  fields = cleanupString(columns.join(','));
  values = cleanupString(filters.join(','));

  searchFilter.columns = fields;
  searchFilter.filters = values;

  return searchFilter;
};
