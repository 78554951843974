import { createApi } from '@reduxjs/toolkit/query/react';
import { ordoxBaseQuery } from './baseQuery';

type State = {
  id: string;
  name: string;
  idSiigo: string;
};

type City = {
  id: string;
  name: string;
  idSiigo: string;
  stateId: string;
};

export type Customer = {
  id: string;
  identification: string;
  actionOnShipment: string;
  address: string;
  cityDescription: string;
  cityId: string;
  companyId: string;
  createdAt: string;
  fullName: string;
  idERP: string;
  idShopify: string;
  idType: string;
  lastName: string;
  listPriceId: string;
  mobile: string;
  name: string;
  paymentForm?: string;
  fulfillmentTypeId?: string;
  fulfillmentType: {
    id: string;
    description: string;
    idDelivery: string;
    deliveryType: string;
    companyId: string;
  }
  paymentMethodId: string;
  paymentMethod: {
    description: string;
    idERP: string;
  };
  paymentTerms: number;
  phone?: string;
  primaryEmail: string;
  source: string;
  stateDescription: string;
  stateId: string;
  taxRegime: string;
  updatedAt: string;
  fiscalResponsibility: string;
  channelId: string;
  invoicingSettingId: string;
  state: State;
  city: City;
  freeShippingFrom?: number;
  contacts?: Contact[];
};

export type Contact = {
  name?: string;
  lastName?: string;
  primaryEmail: string;
  mobile: string;
  city: City;
  state: State;
  address: string;
  stateId?: string;
  cityId?: string;
  defaultShippingAddress?: Boolean;
  color?: string;
};

type FindCustomerResponse = {
  items: Customer[];
  total: number;
};

type FindCustomerRequest = {
  companyId: string;
  fullName: string;
  limit?: number;
  offset?: number;
};

type UpdateCustomerRequest = {
  id: string;
  data: {
    source: string;
    listPriceId?: string;
  }
};


const customerApi = createApi({
    reducerPath: 'customer-api',
  tagTypes: ['customers'],
  baseQuery: ordoxBaseQuery(),
  endpoints: ({ query, mutation }) => ({
    findCustomer: query({
      providesTags: ['customers'],
      query: (filters: FindCustomerRequest) => ({
        url: '/customers',
        method: 'GET'
      }),
      transformResponse: (response: FindCustomerResponse) => response,
    }),
    updateCustomer: mutation<Customer, UpdateCustomerRequest>({
      invalidatesTags: ['customers'],
      query: ({ id, data }) => ({
        url: `/customers/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
  }),
});

export const { useFindCustomerQuery } = customerApi;

export default customerApi;
